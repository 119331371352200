.message-page-contain {
  display: flex;
}

.messages-page {
  overflow: hidden;
}

.messages-component {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messages-matches-wrapper {
  height: 84%;
  width: 100%;
  overflow-y: scroll;
}
