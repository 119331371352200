.modal-content {
  max-width: 500px;
  margin: auto;
}

.swipe-pop-modal-body-superlike {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(118, 225, 255, 1) 85%,
    rgba(50, 181, 255, 1) 100%
  );
}

.swipe-pop-modal-body-likes {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(130, 253, 237, 1) 85%,
    rgba(85, 234, 196, 1) 100%
  );
}

.swipe-pop-modal-body-boost {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(215, 163, 240, 1) 85%,
    rgba(154, 78, 217, 1) 100%
  );
}

.swipe-pop-modal-body-undo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 212, 59, 1) 85%,
    rgba(255, 173, 8, 1) 100%
  );
}

.swipe-pop-body-title {
  color: #444444;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  margin: 0;
}

.swipe-pop-body-premium-title-wrap {
  margin-bottom: 1.5rem;
}

.swipe-pop-body-premium-title {
  color: #fefefe;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  margin: 0;
}

.swipe-pop-premium-icon {
  color: #fefefe;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  margin-right: 0.5rem;
}

.swipe-pop-modal-body-image {
  height: 80px;
  width: 80px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.swipe-pop-modal-body-image-premium {
  height: 80px;
  width: 80px;
  object-fit: cover;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.swipe-pop-icon {
  height: 80px;
  width: 80px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  -webkit-box-shadow: 2px 2px 15px 2px #444444;
  box-shadow: 2px 2px 15px 2px #444444;
}

.swipe-pop-main-icon {
  font-size: 2.5rem;
  color: #ffad08;
}

.swipe-pop-main-icon-bolt {
  font-size: 2.5rem;
  color: #9a4ed9;
}

.swipe-pop-body-countdown-wrapper {
  color: #444444 !important;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 0.2rem;
}

.swipe-pop-body-subtitle {
  color: #444444;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 500;
}

.swipe-pop-features-card {
  width: 100%;
  border: 0 !important;
}

.swipe-pop-features-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  width: 100% !important;
  border: 0 !important;
}

.swipe-pop-feature-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
}

.swipe-pop-feature-text {
  text-align: center;
  margin-bottom: 0;
  margin-top: 5px;
  color: #444444;
  font-weight: 600;
  font-size: 0.8rem;
}

.swipe-pop-feature-icon-bolt {
  font-size: 2rem;
  color: #9a4ed9;
}

.swipe-pop-feature-icon-comment {
  font-size: 2rem;
  color: #5804d7;
}

.swipe-pop-feature-icon-eye {
  font-size: 2rem;
  color: #ffad08;
}

.swipe-pop-feature-icon-stars {
  font-size: 2rem;
  color: #32b5ff;
}

.swipe-pop-feature-icon-heart {
  font-size: 2rem;
  color: #55eac4;
}

.swipe-pop-footer {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  border: 0 !important;
  width: 100%;
}

.swipe-pop-premium-btn {
  width: 100%;
  background-color: #da0e2b !important;
  border-color: #da0e2b !important;
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-superlike-btn {
  width: 100%;
  border-color: rgb(82, 159, 247) !important;
  background: rgb(82, 159, 247);
  background: linear-gradient(
    263deg,
    rgba(82, 159, 247, 1) 0%,
    rgba(76, 149, 242, 1) 12%,
    rgba(46, 92, 213, 1) 100%
  );
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-boost-btn {
  width: 100%;
  border-color: rgb(194, 155, 253) !important;
  background: rgb(194, 155, 253);
  background: linear-gradient(
    90deg,
    rgba(194, 155, 253, 1) 0%,
    rgba(146, 75, 251, 1) 12%,
    rgba(88, 4, 215, 1) 100%
  );
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-boost-btn:hover {
  width: 100%;
  border-color: rgb(194, 155, 253) !important;
  background: rgb(194, 155, 253);
  background: linear-gradient(
    90deg,
    rgba(194, 155, 253, 1) 0%,
    rgba(146, 75, 251, 1) 12%,
    rgba(88, 4, 215, 1) 100%
  );
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-boost-btn:focus {
  width: 100%;
  border-color: rgb(194, 155, 253) !important;
  background: rgb(194, 155, 253);
  background: linear-gradient(
    90deg,
    rgba(194, 155, 253, 1) 0%,
    rgba(146, 75, 251, 1) 12%,
    rgba(88, 4, 215, 1) 100%
  );
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-boost-btn:active {
  width: 100%;
  border-color: rgb(194, 155, 253) !important;
  background: rgb(194, 155, 253) !important;
  background: linear-gradient(
    90deg,
    rgba(194, 155, 253, 1) 0%,
    rgba(146, 75, 251, 1) 12%,
    rgba(88, 4, 215, 1) 100%
  );
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-update-card-btn {
  width: 100%;
  border-color: #dc3545 !important;
  background-color: #dc3545 !important;
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-close-btn {
  color: #c2c5cb !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.modal-content {
  margin-top: 3rem;
}

.boost-active-countdown-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.boost-active-countdown-innerwrap {
  font-size: 2rem;
  font-weight: 800;
  color: #444444;
}

.boost-active-countdown-boost-icon-left {
  margin-right: 15px;
  color: #9a4ed9;
}

.boost-active-countdown-boost-icon-right {
  margin-left: 15px;
  color: #9a4ed9;
}

// CONFIRM PURCHASE

.confirm-purchase-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  border: 1px solid #888;
  border-radius: 15px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.confirm-purchase {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.confirm-purchase-wrap {
  padding: 0.5rem;
  display: flex;
  align-items: flex-end;
  border-bottom: 2px solid #888;
}

.confirm-purchase-title {
  margin: 0;
  text-align: center;
  font-size: 1rem;
  color: #888;
  margin-bottom: 8px;
}

.confirm-purchase-wrap-paymentinfo {
  padding-top: 1rem;
}

.confirm-purchase-payment-info {
  margin: 0;
  text-align: center;
  font-size: 1rem;
  color: #888;
}

.confirm-purchase-wrap-price {
  display: flex;
  align-items: flex-end;
  margin-top: 8px;
}

.confirm-purchase-number {
  margin: 0;
  text-align: center;
  margin-right: 5px;
}

.confirm-purchase-subtitle {
  margin: 0;
}

.confirm-purchase-price {
  margin: 0;
}

.confirm-purchase-icon {
  margin-left: 10px;
}

.confirm-purchase-new-card-text {
  margin: 0;
  font-size: 0.8rem;
  margin-top: 3px;
  color: #393c59;
  text-decoration: underline;
}
