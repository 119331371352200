.welcome-page-v2-component {
  height: 100%;
  width: 100%;
  background-color: #021e30;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

.welcome-page-v2-header {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.welcome-page-v2-content {
  width: 100%;
  max-width: 360px;
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  flex: 1;
}

.welcome-page-v2-logo {
  height: 93px;
  width: 164px;
  object-fit: contain;
}
