.profile-info-content {
  margin-top: 24px;
}

.profile-info-option {
  position: relative;
  display: flex;
  font-size: 16px;
  padding-bottom: 10px;
  height: 40px;
  margin-top: 12px;
  border-bottom: 1px solid #EEEEEE;
}

.profile-info-v2-icon {
  margin-top: 5px;
  color: #C44B4B;
}

.profile-info-v2-item-text {
  position: absolute;
  left: 25px;
}

.profile-info-v2-right-icon {
  margin-left: 5px;
  color: #B5B5B5;
}

.profile-info-non-item {
  color: #C44B4B;
}

.profile-info-v2-row-action {
  position: absolute;
  right: 15px;
}