.support-v2-header {
  width: 100%;
  background-image: url("../../assets/Images/background-pattern.png");
  background-size: cover;
  padding: 1rem;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.support-v2-title {
  text-align: center;
  font-size: 20px;
  color: #021e30;
  font-weight: 700;
  margin-bottom: 1rem;
}

.support-v2-subtitle {
  font-size: 16px;
  text-align: center;
  color: #021e30;
  margin: 0;
}

.mobile-support-container {
  width: 100%;
  padding: 2rem;
  padding-bottom: 5rem;
  height: 90%;
  overflow-y: scroll;
}

.mobile-support-header {
  border-bottom: 2px solid #da0e2b;
}

.mobile-support-title {
  text-align: center;
  font-size: 2rem;
}

.mobile-support-subtitle {
  text-align: center;
  font-style: italic;
}

// FAQ Options

.mobile-faq-container {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.mobile-faq-button {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #f9f9f9 !important;
  border-color: #090a20 !important;
  color: #090a20 !important;
}

.mobile-faq-tab-dropdown {
  padding: 1rem;
}

.mobile-faq-tab-dropdown-form {
  padding: 0rem;
}

.mobile-faq-tab-content-title {
  font-size: 1.25rem;
}

// Support Options

.mobile-support-options-container {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.mobile-support-options-button {
  width: 45% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #090a20 !important;
  border-color: #090a20 !important;
  padding: 1rem !important;
  font-size: 1.25rem !important;
  overflow: hidden !important;
}

.support-option-icon {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.faq-support-icon {
  margin-right: 0.5rem;
}

.support-options-email {
  font-size: 0.5rem;
}

.support-v2-contact-us-section {
  padding-top: 1rem;
  padding-bottom: 5rem;
}

.support-v2-contact-title {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #021e30;
}

.support-v2-contact-subtitle {
  text-align: center;
  font-size: 16px;
  color: #021e30;
  margin-bottom: 2rem;
}
