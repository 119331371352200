.matches-tabs-component {
  width: 100%;
  height: 92%;
}

.matches-tabs-wrapper {
  width: 100%;
  display: flex;
  height: 10%;
}

.matches-tab-btn-active-left {
  width: 50%;
  background-color: #fff !important;
  font-size: 1.5rem !important;
  color: #000 !important;
  font-weight: 700 !important;
  border-radius: 0 !important;
  border: 0 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.matches-tab-btn-active-right {
  width: 50%;
  background-color: #fff !important;
  font-size: 1.5rem !important;
  color: #000 !important;
  font-weight: 700 !important;
  border-radius: 0 !important;
  border: 0 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.matches-tab-btn-inactive-left {
  width: 50%;
  background-color: #fff !important;
  border-radius: 0 !important;
  font-size: 1.5rem !important;
  color: #b7bbc0 !important;
  font-weight: 700 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-bottom: 1px solid #b7bbc0 !important;
  border-right: 1px solid #b7bbc0 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.matches-tab-btn-inactive-right {
  width: 50%;
  background-color: #fff !important;
  font-size: 1.5rem !important;
  color: #b7bbc0 !important;
  font-weight: 700 !important;
  border-radius: 0 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #b7bbc0 !important;
  border-left: 1px solid #b7bbc0 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
