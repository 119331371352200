.matches-v2-component {
  width: 100%;
  height: 92%;
}

.matches-v2-tabs {
  width: 100%;
  display: flex;
  padding-top: 1rem;
  height: 8%;
}

.matches-v2-tab {
  width: 50%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: grey;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid grey;
}

.matches-v2-tab-active {
  width: 50%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  color: #C44B4B;
  border-bottom: 2px solid #C44B4B;
  padding-bottom: 0.5rem;
}

.matches-v2-content-likes {
  height: 84%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: space-between;
}

.matches-v2-content-matches {
  height: 84%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: space-between;
}
