.desktop-chat-component {
  width: 100%;
  background-color: #fff;
  height: 800px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// ! Desktop Chat Header

.desktop-chat-header {
  width: 100%;
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
}

.desktop-chat-header-link {
  text-decoration: none !important;
  width: 100% !important;
}

.desktop-chat-header-leftside {
  display: flex;
  cursor: pointer;
  width: 100%;
}

.desktop-chat-header-rightside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-chat-header-safety-icon {
  font-size: 1.5rem;
}

.desktop-chat-header-image-wrap {
}

.desktop-chat-header-image {
  height: 83px;
  width: 66px;
  object-fit: cover;
  border-radius: 5px;
}

.desktop-header-image-placeholder {
  height: 83px;
  width: 66px;
  background-color: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.desktop-chat-header-placeholder-icon {
  color: rgb(153, 153, 153);
  font-size: 2.5rem;
}

.desktop-chat-header-info-wrap {
  padding-left: 1rem;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.desktop-chat-header-username {
  margin: 0;
  margin-bottom: 1rem;
  color: #333;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.25rem;
}

.desktop-chat-header-age-location {
  margin: 0;
  color: #7e7e7e;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

// ! Desktop Chat Content / Messages Feed

.desktop-chat-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.desktop-message-feed {
  width: 100%;
  padding: 1rem;
  overflow-y: scroll;
  max-height: 550px;
}

// ! Desktop Chat Footer

.desktop-chat-footer {
  background-color: #eee;
  border-top: 1px solid #d9d9d9;
  padding: 2rem;
}

.desktop-chat-footer-form {
  display: flex;
}

.desktop-chat-footer-form-input {
  resize: none;
}

.desktop-chat-submit-button-wrapper {
}

.desktop-chat-submit-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  margin-left: 0.5rem;
  width: 150px !important;
}

.desktop-message-spinner {
  text-align: center;
}
