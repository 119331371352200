.main-image-container {
  height: 98%;
  width: 93%;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  max-width: 1140px;
  margin: auto;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.main-image-user-info-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 110px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(255, 255, 255, 0) 50%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.main-image-user-info-container-noactions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 2rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.main-image-info-innerwrap {
  padding-left: 1rem;
}

.main-image-info-top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-image-info-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-image-username {
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  margin: 0;
}

.main-image-age {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #fff;
  margin-left: 5px;
  margin-top: 1px;
}

.verified-main-image-icon {
  color: #007bff;
  font-size: 18px;
  --fa-primary-color: #fff;
  --fa-secondary-color: #007bff;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  margin-right: 5px;
  margin-top: 3px;
}

.main-image-info-middle {
  display: flex;
  align-items: center;
}

.main-image-online-icon {
  color: #20c997;
  font-size: 0.8rem;
  margin-right: 5px;
}

.main-image-online-text {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.main-image-user-info {
  color: #fff;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
}

.main-image-user-address {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  text-align: center;
  margin: 0;
}

//! Info Tabs

.card-top-info-container {
  position: absolute;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.card-top-info-section {
  width: 100%;
  max-width: 33%;
  height: 55px;
}

.card-tab-info {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0 !important;
}

.networth-tab {
  border: 3px solid rgba(205, 174, 109, 1);
}

.job-tab {
  border: 3px solid #95adb6;
}

.car-tab {
  border: 3px solid #7d869c;
}

.ethnicity-tab {
  border: 3px solid #7d869c;
}

.info-tab {
  border: 3px solid #e8e9eb;
}

.benefit-tab {
  border: 3px solid rgba(205, 174, 109, 1);
}

.tab-icon-wrap-networth {
  height: 100% !important;
  width: 35px;
  background-color: rgba(205, 174, 109, 1) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 70%;
}

.tab-icon-wrap-benefit {
  height: 100% !important;
  width: 35px;
  background-color: rgba(205, 174, 109, 1) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 70%;
}

.tab-icon-wrap-job {
  height: 100% !important;
  width: 35px;
  background-color: #95adb6 !important;
  opacity: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-icon-wrap-car {
  height: 100% !important;
  width: 35px;
  background-color: #7d869c !important;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 70%;
}

.tab-icon-wrap-ethnicity {
  height: 100% !important;
  width: 35px;
  background-color: #7d869c !important;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 70%;
}

.tab-icon-wrap-info {
  height: 100% !important;
  width: 35px;
  background-color: #e8e9eb !important;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 70%;
}

.tab-icon {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
}

.tab-text-wrap {
  padding-left: 0.5rem;
}

.card-tab-title {
  margin: 0;
  font-size: 0.6rem;
  text-transform: uppercase;
}

.card-tab-text {
  margin: 0;
  font-size: 0.7rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75px;
}

.card-tab-text-benefit {
  margin: 0;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.1;
  padding-right: 2px;
  margin-top: 2px;
}

.verified-tag {
  background-color: #007bff;
}
