.desktop-v2-photo-grid-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.desktop-v2-photo-grid-tab-container {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.desktop-v2-photo-grid-menu {
  position: absolute;
  right: 10px;
  top: 8px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  cursor: pointer;
  font-size: 16px;
}

.desktop-v2-photo-grid-tab {
  padding-top: 1rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 400;
  font-size: 14px;
  color: #021e30;
  width: 90px;
  text-align: center;
  cursor: pointer;
}

.desktop-v2-photo-grid-tab-active {
  padding: 1rem;
  padding-bottom: 0.75rem;
  font-weight: 700;
  font-size: 14px;
  color: #c44b4b;
  border-bottom: 1px solid #c44b4b;
  width: 90px;
  text-align: center;
  cursor: pointer;
}

.desktop-v2-photo-grid-content-container {
  display: flex;
  overflow-x: scroll;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.desktop-v2-photo-grid-user-photo-wrapper {
  width: 100px;
  height: 126px;
  border-radius: 3px;
  margin-right: 0.5rem;
  object-fit: cover;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.desktop-v2-photo-grid-user-photo-wrapper-request {
  width: 100px;
  height: 126px;
  border-radius: 3px;
  margin-right: 0.5rem;
  object-fit: cover;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  background-color: rgba(2, 30, 48, 1);
}

.desktop-profile-v2-photo-request-innerwrap {
  height: 100%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(238, 238, 238, 0.05);
}

.desktop-profile-v2-request-title {
  text-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}

.desktop-profile-v2-request-subtitle {
  text-align: center;
  margin: 0;
  font-size: 12px;
  margin-top: 0.5rem;
  color: #eeeeee;
}

.desktop-profile-v2-unlock-icon-wrapper-request {
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.desktop-v2-photo-grid-user-image {
  width: 100px;
  height: 126px;
  border-radius: 3px;
  margin-right: 0.5rem;
  object-fit: cover;
  cursor: pointer;
}

.desktop-v2-photo-grid-user-image-blur {
  width: 100px;
  height: 126px;
  border-radius: 3px;
  margin-right: 0.5rem;
  object-fit: cover;
  cursor: pointer;
  filter: blur(15px);
}

.desktop-v2-photo-grid-placeholder {
  width: 100px;
  height: 126px;
  border-radius: 3px;
  margin-right: 0.5rem;
  background-color: #f4f4f4;
}

.desktop-v2-photo-grid-no-photos-indicator {
  width: 100%;
  height: 126px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.desktop-v2-photo-grid-no-photos-title {
  text-align: center;
  font-size: 12px;
  color: #b5b5b5;
  margin: 0;
}

.desktop-v2-photo-grid-no-photos-innerwrap {
  width: 100%;
  margin: auto;
  border: 1px solid #eeeeee;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.desktop-v2-photo-grid-input {
  width: 100px;
  height: 126px;
  border-radius: 3px;
  margin-right: 0.5rem;
  object-fit: cover;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.desktop-v2-photo-grid-input-icon {
  height: 18px;
  width: 18px;
}

.desktop-v2-photo-grid-input-title {
  font-size: 16px;
  font-weight: 500;
  color: #021e30;
  text-align: center;
  max-width: 50px;
  margin: 0;
  margin-top: 10px;
}

.desktop-v2-photo-grid-loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgba(215, 215, 215, 0.5);
  z-index: 5;
  backdrop-filter: blur(10px);
}

.desktop-v2-photo-grid-loading-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-v2-photo-grid-loading-popup {
  z-index: 7;
  height: 40%;
  background-color: #fff;
  border-radius: 5px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.desktop-v2-photo-grid-loading-title {
  font-size: 22px;
  font-weight: 700;
  color: #021e30;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.desktop-v2-photo-grid-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #021e30;
  margin-top: 2rem;
}

.desktop-v2-photo-grid-request-container {
  width: 50%;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.desktop-v2-photo-grid-dropdown {
  height: 94px;
  width: 213px;
  background-color: #fff;
  position: absolute;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  z-index: 3;
  right: 15px;
  top: 35px;
  justify-content: space-between;
}

.desktop-v2-photo-grid-dropdown-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 47px;
  padding-right: 15px;
  padding-left: 15px;
  cursor: pointer;
}

.desktop-v2-photo-grid-dropdown-row-section {
  display: flex;
  align-items: center;
}

.request-row-icon {
  font-size: 16px;
  color: #021e30;
}

.request-row-icon-red {
  font-size: 16px;
  color: #c44b4b;
}
