.message-queue-card {
  display: flex !important;
  flex-direction: row !important;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border: 0 !important;
  border-bottom: 1px solid #f9f9f9 !important;
  width: 100%;
  max-width: 500px !important;
}
.message-queue-image {
  width: 65px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #eeeeee;
}

.message-queue-image-wrapper {
  position: relative;
  display: flex;
  width: 70px;
}

.message-queue-online-indicator {
  position: absolute;
  top: -9px;
  right: 0px;
  z-index: 2;
}

.message-queue-match-indicator {
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 2px;
  border-radius: 5px;
  height: 22px;
  width: 22px;
  background-color: #d95454;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 4px;
}

.message-queue-online-icon {
  --fa-primary-color: #47ac18;
  --fa-secondary-color: #eeeeee;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
}

.message-queue-unread-message-icon-wrapper {
  position: absolute;
  bottom: 22px;
  right: -8px;
}

.isread-icon {
  color: #c44b4b;
  font-size: 20px;
  --fa-secondary-color: white;
  --fa-secondary-opacity: 1;
}

.message-queue-info {
  padding-left: 0.75rem;
  width: 79%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.message-queue-indicator-wrapper {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.message-queue-compliment-indicator {
  padding-right: 0.5rem;
}

.message-queue-unread-indicator {
}

.message-queue-unread-icon {
  color: #c44b4b;
  font-size: 10px;
}

.message-queue-compliment-icon {
  height: 32px;
  width: 32px;
  object-fit: cover;
}

.message-queue-username {
  font-size: 14px;
  font-weight: 700;
  color: #021030;
  margin: 0;
  margin-bottom: 5px;
}

.message-queue-age {
  font-size: 12px;
  color: #021030;
  font-weight: 500;
}

.message-card-username {
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 5px;
}

.message-card-message {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 600;
  color: #021030;
  margin-top: 5px;
}

.message-queue-verified {
  color: #105bfb;
  margin-right: 7px;
  font-size: 12px;
}

.message-card-message-read {
  margin: 0;
  font-size: 13px;
  color: #818181;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}

.no-messages-wrapper {
  padding-right: 0.75rem;
  height: 100%;
}

.no-messages-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-messages-image {
  width: 40%;
}

.message-queue-loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-queue-component-loading {
  height: 100%;
  width: 100%;
  padding-top: 2rem;
}

.delete-message-button {
  width: 25%;
  height: 80%;
  position: absolute;
  top: 10px;
  right: 1px;
  text-align: center;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  font-size: 17px;
  font-weight: 500;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
