.desktop-v2-filters {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgba(215, 215, 215, 0.5);
  z-index: 5;
  backdrop-filter: blur(10px);
}

.desktop-v2-filters-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-v2-filters-overlay {
  height: 100%;
  width: 100%;
  z-index: 6;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.desktop-v2-filters-popup {
  z-index: 7;
  height: 70%;
  background-color: #fff;
  border-radius: 5px;
  width: 30%;
  display: flex;
  flex-direction: column;
}

.desktop-v2-filters-popup-icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.desktop-v2-filters-popup-title {
  font-size: 28px;
  color: #021e30;
  font-weight: 700;
}

.desktop-v2-filters-header {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  align-items: center;
}

.desktop-v2-filter-title-wrapper {
  display: flex;
  align-items: center;
}

.desktop-v2-filter-close-button {
  font-size: 24px;
  color: #021e30;
  cursor: pointer;
}

.desktop-v2-filters-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.5rem;
  padding-top: 0;
}

.desktop-v2-filters-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}

.desktop-v2-filters-row-title-container {
  display: flex;
  align-items: center;
}

.desktop-v2-filters-icon {
  color: #c44b4b;
  font-size: 16px;
  width: 20px;
  margin-right: 10px;
}

.desktop-v2-filters-row-title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.desktop-v2-filters-row-action-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.desktop-v2-filters-row-content-text {
  color: #021e30;
  font-size: 14px;
  text-align: right;
  padding-right: 1rem;
}

.desktop-v2-filters-row-action-button {
  color: #b5b5b5;
}

// Distance

.desktop-filter-slider-v2-form-wrapper {
  padding-top: 5rem;
}
