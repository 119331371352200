.sidebar-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  //   border-bottom: 1px solid #f9f9f9;
}

.sidebar-logo {
  width: 50%;
  object-fit: cover;
}

.sidebar-navigation {
}

.sidebar-owner-container {
  padding-top: 12rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
}

.icon-bot {
  color: #aeafb1;
  font-size: 1.5rem;
}

.icon-bot-focus {
  color: #2d8cd1;
  font-size: 1.5rem;
}

.icon-home {
  background-image: url(../../../assets/Images/adminSidebar/home.svg);
}

.icon-home-focus {
  background-image: url(../../../assets/Images/adminSidebar/home_active.svg);
}

.icon-flag {
  background-image: url(../../../assets/Images/adminSidebar/flag.svg);
}

.icon-flag-focus {
  background-image: url(../../../assets/Images/adminSidebar/flag_active.svg);
}

.icon-message {
  background-image: url(../../../assets/Images/adminSidebar/message.svg);
}

.icon-message-focus {
  background-image: url(../../../assets/Images/adminSidebar/message_active.svg);
}

.icon-users {
  background-image: url(../../../assets/Images/adminSidebar/users.svg);
}

.icon-users-focus {
  background-image: url(../../../assets/Images/adminSidebar/users_active.svg);
}

.icon-image {
  background-image: url(../../../assets/Images/adminSidebar/image.svg);
}

.icon-image-focus {
  background-image: url(../../../assets/Images/adminSidebar/image_active.svg);
}

.icon-settings {
  width: 18px;
  height: 18px;
  margin-top: 8px;
  background-image: url(../../../assets/Images/adminSidebar/settings.svg);
}

.icon-power {
  width: 14px !important;
  height: 14px !important;
  margin-right: 4px;
  margin-top: 3px;
  background-image: url(../../../assets/Images/adminSidebar/power.svg);
}

.sidebar-button {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  width: 100% !important;
  cursor: pointer;
}

.sidebar-button-active {
  background-color: #36393f !important;
  border: none;
  margin-left: 12px;
  width: 70% !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  cursor: pointer !important;
}

.sidebar-icon {
  color: #fff;
  font-size: 2rem;
}

.sidebar-icon-active {
  color: #090a20;
  font-size: 2.5rem;
}

#dropdown-basic::after {
  display: none;
}

#dropdown-basic {
  background: #202225;
  border: none;
}

.sidebar-admin-user {
  width: 40px;
  height: 40px;
  border-radius: 2px;
}

.sidebar-option {
  display: flex;
  color: white;
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  padding-top: 7px;
  padding-left: 14px;
  &:hover,
  &:focus {
    color: white;
    background: #4f545c;
  }
}

.sidebar-menu {
  width: 226px;
  top: 55px !important;
  left: -3px !important;
  background: #37393f;
}

.sidebar-option-logo {
  width: 18px;
  height: 18px;
  margin-top: 8px;
}

.sidebar-option-title {
  padding-left: 14px;
  padding-top: 6px;
}

.sidebar-logout-option-title {
  padding-left: 14px;
}

.sidebar-option-user {
  width: 40px;
  height: 40px;
  border-radius: 2px;
}

.sidebar-logout {
  display: flex;
  font-size: 12px;
}

.btn-logout {
  font-weight: 300;
}

.option-divider {
  border-color: #303239;
}
