.home-page-hero {
  height: 90%;
  width: 100%;
  background-color: #021e30;
  padding-top: 5rem;
}

.home-page-hero-innerwrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  margin: auto;
}

.home-page-hero-image {
  width: 100%;
  height: auto;
  max-width: 500px;
}

.home-page-hero-tagline-wrapper {
  padding-top: 2.5rem;
}

.home-page-hero-tagline {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  font-weight: 700;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  font-family: Modern Era, sans-serif;
  line-height: 1.2;
}

.home-page-footer {
  padding: 1.5rem;
}

.home-page-footer-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.home-page-footer-button-link {
  width: 60%;
}

.home-page-signup-button {
  width: 100% !important;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  color: #fff;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}

.home-page-login-button {
  width: 100% !important;
  background-color: transparent !important;
  border-color: transparent !important;
  color: #021e30;
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  text-decoration: underline;
}

@media (min-width: 767px) {
  .home-page-hero {
    padding-top: 8rem;
  }

  .home-page-hero-tagline {
    font-size: 2.5rem;
    margin-top: 1.5rem;
  }

  .home-page-login-signup-wrapper {
    display: none;
  }

  .home-page-footer-nav-wrapper {
    display: none;
  }

  .home-page-desktop-footer-nav-wrapper {
    display: flex;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 1.5rem;
  }

  .home-nav-desktop-footer-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1400px;
    margin: auto;
  }

  .home-nav-desktop-footer-link {
    font-size: 2.5rem;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    margin-bottom: 0.25rem;
  }

  .desktop-footer-spacer {
    margin-top: 2.5rem;
  }

  .home-page-sub-footer-wrapper {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding-bottom: 4rem;
  }

  .home-page-sub-footer-innerwrap {
    display: flex;
    align-items: center;
  }

  .home-page-desktop-sub-footer-social-wrapper {
    display: flex;
  }

  .home-page-sub-footer-links-wrapper {
    padding-left: 3rem;
  }

  .home-page-sub-footer-link {
    font-size: 1rem;
    font-weight: 400;
  }

  .home-page-sub-footer-trademark {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 3px;
  }

  .home-page-hero-image {
  }
}
