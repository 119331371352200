.likes-card-component {
  flex-basis: 49%;
  margin-bottom: 0.75rem;
}

.likes-card {
  width: 100%;
}

.likes-card-superlike {
  width: 100%;
  border: 4px solid #32b5ff !important;
}

.likes-card-note {
  width: 100%;
  border: 4px solid #f75590 !important;
}

.likes-card-body-nonpremium {
  padding: 0 !important;
  width: 100%;
  height: 250px;
  position: relative;
}

.likes-card-image-blur-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.likes-card-body-premium {
  padding: 0 !important;
  width: 100%;
  height: 250px;
  position: relative;
}

.likes-card-info {
  position: absolute;
  bottom: 45px;
  width: 100%;
  padding: 1rem;
}

.likes-card-info-non-premium {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 2;
  padding: 0.5rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.likes-card-info-innerwrap {
  display: flex;
  justify-content: space-between;
}

.likes-card-unlock-icon-wrapper {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.likes-card-unlock-title {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  color: #dbdbdb;
}

.likes-card-unlock-icon {
  width: 35%;
}

.likes-card-address-wrap {
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.likes-card-address {
  color: #fff;
  font-weight: 700;
  font-size: 1rem;
}

.likes-card-age {
  color: #fff;
  font-weight: 700;
  font-size: 1rem;
}

.likes-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.likes-card-image-blur {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px !important;
  filter: blur(10px) !important;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
}

.likes-card-username {
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 1rem;
}

.likes-card-action-wrapper {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.likes-card-btn {
  border-radius: 50% !important;
  background-color: #090a20 !important;
  border-color: #090a20 !important;
  z-index: 2;
  height: 45px;
  width: 45px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.likes-card-button {
  width: 50%;
  height: 50px;
  border: 1px #F4F4F4 solid;
  text-align: center;
  padding-top: 10px;
}

.likes-card-icon-like {
  color: #C44B4B;
  font-size: 28px;
}

.likes-card-icon-dislike {
  color: #B5B5B5;
  font-size: 28px;
}

.likes-card-footer-superlike {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #32b5ff !important;
  border: 2px solid #32b5ff !important;
  border-radius: 0 !important;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  font-size: 0.8rem;
}

.likes-card-footer-note {
  padding: 0 !important;
  padding-left: 0.2rem !important;
  padding-right: 0.2rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f75590 !important;
  border: 2px solid #f75590 !important;
  border-radius: 0 !important;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  font-size: 0.8rem;
}

.likes-card-superlike-icon-wrapper {
  position: absolute;
  top: -15px;
  left: 42%;
  z-index: 3;
}

.likes-card-icon-superlike {
  color: #32b5ff;
  font-size: 1.5rem;
}

.likes-card-icon-note {
  color: #f75590;
  font-size: 1.5rem;
  --fa-primary-color: #f75590;
  --fa-secondary-color: #dbdbdb;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
}

.likes-card-note-message {
  margin: 0 !important;
  font-size: 0.7rem;
  text-transform: none;
  font-weight: 500;
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.likes-card-online-indicator {
  display: flex;
  align-items: center;
}

.likes-card-online-icon {
  color: #20c997;
  font-size: 0.5rem;
  margin-right: 5px;
}

.likes-card-online-text {
  color: #f9f9f9;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.6rem;
}

@media (min-width: 992px) {
  .likes-card-component {
    flex-basis: 25%;
  }

  .likes-card-body-nonpremium {
    height: 450px;
  }

  .likes-card-body-premium {
    height: 450px;
  }
}
