.profile-v2-verifications-component {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.profile-v2-verifications-title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.profile-v2-verifications-content {
  display: flex;
  flex-direction: column;
}

.profile-v2-verifications-badge {
  width: 100%;
  padding: 1rem;
  background-color: #eeeeee;
  color: #b5b5b5;
  border-radius: 3px;
  text-align: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.profile-v2-verifications-badge-verified {
  width: 100%;
  padding: 1rem;
  background-color: #021e30;
  color: #fff;
  border-radius: 3px;
  position: relative;
  text-align: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.profile-v2-verifications-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.45rem;
}

.profile-v2-verification-icon {
  --fa-primary-color: #fff;
  --fa-secondary-color: #d95454;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
}

.profile-v2-verifications-content-title {
  font-size: 16px;
}

.profile-v2-verifications-content-subtitle {
  font-size: 12px;
  margin: 0;
}
