.welcome-v2-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1rem;
  padding-top: 1.5rem;
}

.welcome-v2-container-benefits {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
}

.welcome-v2-title {
  margin-bottom: 14px;
}

.welcome-v2-title-one {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 2px;
}

.welcome-v2-title-two {
  font-size: 28px;
  font-weight: 700;
  color: #c44b4b;
}

.welcome-v2-subtitle {
  font-size: 14px;
  margin: 0;
  margin-bottom: 1px;
  color: #021e30;
}

.welcome-v2-main-image {
  margin-top: 32px;
  margin-bottom: 24px;
  height: 204px;
  width: 100%;
}

.welcome-v2-description-badge {
  padding-top: 5px;
  color: #c44b4b;
  font-size: 34px;
}

.welcome-v2-description {
  display: flex;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.welcome-v2-description-title {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 0.25rem;
}

.welcome-step-icon {
  height: 28px;
  width: 28px;
  object-fit: cover;
}

.welcome-v2-description-text {
  font-size: 14px;
  color: #021e30;
  margin: 0;
}

.welcome-v2-steps {
  display: flex;
  width: 11%;
  margin: 12px auto;
  margin-bottom: 1rem;
}

.welcome-v2-indicator {
  margin-left: 2px;
  margin-right: 2px;
  width: 10px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 2px;
}

.welcome-v2-indicator-active {
  margin-left: 2px;
  margin-right: 2px;
  width: 10px;
  height: 4px;

  background: #021e30;
  border-radius: 2px;
}

.welcome-v2-description-body {
  margin-left: 15px;
}

.welcome-v2-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 1rem;
  left: 0;
  border-top: 1px solid #eeeeee;
  background-color: #fff;
}

.welcome-v2-button {
  cursor: pointer;
  border: none;
  width: 100%;
  height: 48px;
  background: #c44b4b;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  &:active,
  &:focus {
    background: #c44b4b;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-v2-button-skip {
  border: none;
  width: 100%;
  height: 48px;
  background: transparent;
  font-size: 16px;
  &:active,
  &:focus {
    background: transparent;
  }
  color: #021e30;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.welcome-v2-upload-button {
  border: none;
  width: 90%;
  height: 48px;
  margin-top: 20px;
  background: #c44b4b;
  font-size: 20px;
  font-weight: bold;
  &:active,
  &:focus {
    background: #c44b4b;
  }
}

.welcome-v2-photo-containter {
  width: 100%;
  height: 100%;
  padding-top: 65px;
  padding-left: 20px;
  padding-right: 20px;
}

.welcome-v2-photos-title-one {
  font-size: 20px;
  font-weight: bold;
}

.welcome-v2-photos-header {
  margin-bottom: 40px;
}

.welcome-v2-photos-title-two {
  font-size: 28px;
  font-weight: bold;
  color: #c44b4b;
}

.welcome-v2-photo-header-text {
  margin-top: 20px;
  font-weight: 500;
}

.welcome-v2-photos-tab-skip {
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  font-size: 16px;
  color: #021e30;
}

.welcome-v2-photos-footer {
  position: absolute;
  width: 90%;
  bottom: 25px;
  text-align: center;
}

.welcome-v2-photo-guideline {
  border-bottom: 1px solid;
  margin-right: 5px;
  cursor: pointer;
}

.welcome-v2-physical-value-input {
  width: 100%;
  height: 50px !important;
}

.welcome-v2-guideline {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 3;
}

.welcome-v2-guideline-description {
  background: #ffffff;
  width: 90%;
  height: 63%;
  margin-top: 20px;
  padding-top: 30px;
  padding-left: 15px;
}

.welcome-v2-guideline-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.welcome-v2-guideline-sub {
  position: relative;
  width: 95%;
  height: 60px;
}

.welcome-v2-guideline-caution {
  position: relative;
}

.welcome-v2-dot {
  position: absolute;
  font-size: 8px;
  color: #c44b4b;
  top: 10px;
}

.welcome-v2-guideline-sub-text {
  position: absolute;
  left: 12px;
  font-size: 18px;
}

.welcome-v2-guideline-caution-text {
  position: absolute;
  font-size: 18px;
  color: gray;
}

.welcome-v2-close-guideline {
  text-align: center;
  position: absolute;
  width: 86%;
  height: 40px;
  margin-top: 30px;
}

.welcome-v2-guideline-icon {
  color: #c44b4b;
}

.welcome-v2-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.welcome-v2-warning {
  width: 90%;
  height: 40%;
  background: #ffffff;
  margin-top: 90px;
  text-align: center;
  padding: 0 20px;
}

.welcome-v2-warning-mark {
  font-size: 55px;
  color: #c44b4b;
  margin-top: 50px;
}

.welcome-v2-warning-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}

.welcome-v2-warning-description {
  color: #021e30;
  font-size: 18px;
  font-weight: 500;
}

.welcome-v2-header-benefits {
  height: 20%;
}

.welcome-v2-footer-benefits {
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.welcome-v2-benefits-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 80%;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.welcome-v2-benefit-option {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  overflow: hidden;
  text-align: center;
  background-color: #eeeeee;
  border-radius: 3px;
  flex-basis: 49.5%;
  margin-bottom: 0.35rem;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.welcome-v2-benefit-option-active {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  overflow: hidden;
  text-align: center;
  background-color: #021e30;
  border-radius: 3px;
  flex-basis: 49.5%;
  margin-bottom: 0.35rem;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.looking-for-form {
  padding-top: 3rem;
}

.looking-for-text-input {
  resize: none;
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  font-size: 14px;
  color: #021e30;
  font-weight: 500;
  padding: 1rem;
  height: 105px !important;
}

.looking-for-label {
  text-align: right;
  position: absolute;
  right: 1rem;
  font-size: 12px;
  font-weight: 500;
  color: #b5b5b5;
}

.remaining-characters-alert {
  color: #c44b4b;
}

.welcome-v2-photo-body {
  overflow-y: scroll;
  padding-bottom: 100px;
}

.welcome-v2-benefits-body {
  overflow-y: scroll;
  padding-bottom: 100px;
}
