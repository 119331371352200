.desktop-profile-manage-subscription-status-text {
  color: #464646;
}

.desktop-profile-manage-subscription-premium-cta-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  font-weight: 500 !important;
}

.desktop-manage-subscription-go-premium-icon {
  margin-right: 8px;
}

.desktop-deactivate-account-cta-button {
  padding: 0 !important;
  color: #888 !important;
}

.desktop-profile-deactivate-account-section {
  display: flex;
  justify-content: space-between;
}

.desktop-profile-cancel-subscription-section {
  display: flex;
  justify-content: space-between;
}

.desktop-profile-deactivate-account-text {
  color: #464646;
}

.desktop-profile-cancel-subscription-text {
  color: #464646;
}

.desktop-deactivate-account-cancel-button {
  padding: 0 !important;
  color: #464646 !important;
}

.desktop-deactivate-account-confirm-button {
  background-color: #f9f9f9 !important;
  border-color: #464646 !important;
  color: #464646 !important;
}

.desktop-manage-subscription-premium-status-text-active {
  color: green;
  font-weight: 500;
}

.desktop-manage-subscription-premium-status-text-not-active {
  color: red;
  font-weight: 500;
}

.desktop-manage-subscription-item-text {
  color: #464646;
  text-transform: capitalize;
}

.desktop-manage-subscription-subscription-button-wrapper {
  width: 100%;
  display: flex;
}

.desktop-manage-subscription-resume-subscription-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
}
