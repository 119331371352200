.profile-v2-socials-component {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.profile-v2-socials-title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.profile-v2-socials-content {
  display: flex;
  justify-content: space-between;
}

.profile-v2-socials-badge {
  width: 24%;
  padding: 1rem;
  background-color: #eeeeee;
  color: #b5b5b5;
  border-radius: 3px;
  text-align: center;
  position: relative;
}

.profile-v2-socials-badge-verified {
  width: 24%;
  padding: 1rem;
  background-color: #eeeeee;
  border-radius: 3px;
  position: relative;
  text-align: center;
}

.facebook-verification-icon {
  font-size: 1.2rem;
  color: #b5b5b5;
}

.facebook-verification-icon-verified {
  font-size: 1.2rem;
  color: #3b5998;
}

.instagram-verification-icon {
  font-size: 1.2rem;
  color: #b5b5b5;
}

.instagram-verification-icon-verified {
  font-size: 1.2rem;
  color: #e1306c;
}

.twitter-verification-icon {
  font-size: 1.2rem;
  color: #b5b5b5;
}

.twitter-verification-icon-verified {
  font-size: 1.2rem;
  color: #1da1f2;
}

.linkedin-verification-icon {
  font-size: 1.2rem;
  color: #b5b5b5;
}

.linkedin-verification-icon-verified {
  font-size: 1.2rem;
  color: #0e76a8;
}
