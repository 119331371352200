.sd-cta {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-image: url("../../../assets/Images/sugar-daddy/sugar-daddy-cta-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.sd-cta-title {
  color: #f9f9f9;
  font-size: 32px;
  font-weight: 900;
  line-height: center;
  text-align: center;
  padding-bottom: 1rem;
}

.sd-cta-btn-wrap {
  display: flex;
  justify-content: center;
}

.sd-cta-link {
  width: 50%;
  max-width: 200px;
}

.sd-cta-btn {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  border-width: 2px !important;
  width: 100%;
}

.sd-cta-img-wrap {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.sd-cta-img {
  width: 100px;
  height: 15px;
}
