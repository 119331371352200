.profile-component {
  position: relative;
}

.swipe-profile-wrapper {
  height: 100%;
}

.swipe-component {
  width: 100%;
  height: 100%;
  background-color: #fff;
  // background-color: linear-gradient(180deg, #fff 0, #f5f7fa) !important;
  position: relative;
}

.swipe-component-x {
  width: 100%;
  height: 84%;
  overflow: hidden;
  position: relative;
}

.submitting-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fetching-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-cards-card {
  border: 0 !important;
}

.no-cards-container {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-cards-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-cards-card-footer {
  border: 0 !important;
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-cards-title {
  text-align: center;
  color: #424242;
  font-weight: 600;
  margin-bottom: 1rem;
}

.no-cards-subtitle {
  text-align: center;
  color: #999999;
  font-weight: 500;
}

.no-cards-icon {
  font-size: 3rem;
}

.like-stamp-image {
  pointer-events: none;
  position: fixed;
  z-index: 2;
  width: 30%;
  max-width: 250px;
}

.dislike-stamp-image {
  pointer-events: none;
  position: fixed;
  z-index: 2;
  width: 30%;
  max-width: 250px;
}

.superlike-stamp-image {
  position: absolute;
  z-index: 2;
  width: 50%;
  transform: rotate(-20deg);
  max-width: 250px;
}

.undo-stamp-image {
  position: absolute;
  z-index: 2;
  width: 50%;
  max-width: 250px;
  transform: rotate(20deg);
}

.fetching-wrapper {
  height: 100%;
  width: 100%;
  background-color: #c44b4b;
}

.swipe-nav-placeholder {
  height: 8%;
  width: 100%;
  background-color: grey;
}
