.landing-grid-component {
  width: 100%;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.landing-grid-header {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.landing-grid-title {
  text-align: center;
}

.landing-grid-subtitle {
  text-align: center;
}

.landing-grid-content {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
}

.landing-grid-user-card {
  flex-basis: 49% !important;
  width: 49%;
  margin-bottom: 0.25rem;
  position: relative;
  cursor: pointer;
}

.landing-grid-card-image {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.landing-grid-user-card-info {
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.landing-grid-user-card-age-username {
  color: #fff;
  font-size: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0;
}

.landing-grid-user-card-username {
  color: #fff;
  font-size: 0.65rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0;
  font-weight: 500;
}

.landing-grid-user-card-online-status {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.25rem;
}

.landing-card-online-icon {
  font-size: 0.65rem;
  color: #38ff14;
}

.landing-grid-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.landing-grid-footer-button {
  width: 50%;
  background-color: #fff;
  border-color: #c44b4b;
  color: #c44b4b !important;
  font-weight: 500 !important;
  max-width: 300px;
}

@media (min-width: 767px) {
  .landing-grid-user-card {
    flex-basis: 24% !important;
    width: 24%;
    margin-bottom: 0.25rem;
  }
}
