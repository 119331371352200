.spotlight-component {
  width: 100%;
  padding: 1rem;
  height: 150px;
}

.spotlight-component-content-wrapper {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  height: 100%;
}

.spotlight-component-cta-image {
  height: 100%;
  min-width: 100px !important;
  position: relative;
  border-radius: 2px;
  margin-left: 5px;
  border: 1px solid #eeeeee;
}

.spotlight-cta-user-image {
  height: 100%;
  min-width: 100px !important;
  object-fit: cover;
  border-radius: 3px !important;
  filter: blur(1px);
}

.spotlight-component-cta-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
}

.spotlight-component-cta-overlay-boosted {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
}

.spotlight-boosted-time {
  font-size: 10px;
  margin: 0;
  text-align: center;
  color: #fff;
  font-weight: 700;
  margin-top: 6px;
}

.spotlight-cta-icon {
  color: #fff;
  font-size: 1.5rem;
}

.boost-plus-icon {
  width: 25px;
  height: 25px;
}

.spotlight-results-container {
  display: flex;
  width: 100%;
}

.end-spacer {
  padding-right: 1rem;
}

.spotlight-card-wrapper {
  height: 100%;
}
