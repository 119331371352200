.btnGoogle { 
  display: inline-block !important;
  margin-top:15px;
  width: 100%;
  line-height: 2.4;
  border-radius: 0.3rem !important;
  background-color: #db3236 !important;
  font-size: 1.25rem !important;
  color:white !important;
  text-align: center;
}

.btnGoogle:hover {
  background: #db3236;
  opacity: 0.4;
}

.btnGoogle-signin { 
  display: inline-block !important;
  margin-top:15px;
  width: 100%;
  line-height: 2.4;
  border: 1px solid ghostwhite !important;
  border-radius: 0.3rem !important;
  background-color: white !important;
  font-size: 1.25rem !important;
  color: #333131 !important;
  text-align: center;
}

.google-logo {
  width: 35px;
  padding-bottom: 4px;
}