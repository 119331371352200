.hiwp-hero {
  width: 100%;
  padding-top: 125px;
  background-color: #090a20;
}

.how-it-works-page {
  width: 100%;
}

.hiwp-hero-title {
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  color: #fff;
}

.hiwp-hero-subtitle {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  color: #efefef;
}

.hiwp-hero-btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  width: 100%;
}

.hiwp-hero-link {
  width: 100% !important;
  max-width: 500px !important;
}

.hiwp-hero-btn {
  margin-bottom: 0.5rem;
  height: 50px !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  width: 100% !important;
  max-width: 500px !important;
}

.hiwp-learn-more-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 1rem;
}

.hiwp-learn-more {
  margin-bottom: 10px !important;
  color: #efefef;
}

.hiwp-learn-more-down-arrow {
  color: #efefef;
}

.hiwp-hero-btn {
  background-color: #393c59 !important;
  border-color: #393c59 !important;
}

.hiwp-card {
  padding: 0 !important;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  flex-basis: 100%;
}

.hiwp-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: auto;
  justify-content: space-between;
}

.hiwp-ul {
  padding-left: 1.3rem !important;
}

.hiwp-card-section-wrap {
  padding-top: 1.5rem;
  width: 100%;
}

@media (min-width: 767px) {
  .hiwp-card {
    flex-basis: 48%;
    padding: 0 !important;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
  }

  .hiwp-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .hiwp-card-section-wrap {
    padding-bottom: 2rem;
    padding-top: 4rem;
  }
}
