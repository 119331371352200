.chat-v2-component {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 3;
  background-color: #fff;
}

.chat-v2-header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 !important;
  height: 8% !important;
  position: relative;
  width: 100%;
}

.chat-v2-header-scroll {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 !important;
  height: 8% !important;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #eeeeee !important;
}

.chat-v2-footer {
  width: 100%;
  height: 8%;
}

.chat-v2-header-return-button {
  background-color: transparent;
  border: 0;
  color: #021e30;
  font-size: 18px !important;
}

.chat-v2-header-section-right {
  display: flex;
  position: relative;
}

.chat-v2-menu {
  position: absolute;
  bottom: -90px;
  left: -125px;
  width: 150px;

  background-color: #fff;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.chat-v2-menu-item {
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #021e30;
  font-size: 14px;
}

.chat-v2-header-section-left {
  display: flex;
}

.chat-v2-header-call-button {
  background-color: transparent;
  border: 0 !important;
  color: #d95454;
  font-size: 22px !important;
  margin-right: 5px;
}

.chat-v2-header-menu-button {
  background-color: transparent;
  border: 0 !important;
  color: #021e30;
  font-size: 22px !important;
}

.chat-v2-header-user-info {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
}

.chat-v2-header-user-info-image-wrapper {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.chat-v2-header-user-info-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat-v2-header-user-info-username-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}

.chat-v2-header-user-info-username-text {
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.chat-v2-content {
  height: 84%;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

// ! User Info Section

.chat-v2-user-info-section {
  width: 100%;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eeeeee;
}

.chat-v2-user-info-image-wrapper {
  width: 20%;
  border-radius: 3px;
}

.chat-v2-user-info-content-wrapper {
  width: 80%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-v2-user-info-image {
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
}

.chat-v2-user-info-content-top {
}

.chat-v2-user-info-content-bottom {
}

.chat-v2-user-info-bottom-text {
  margin: 0;
  font-size: 12px;
  color: #021e30;
}

.chat-v2-user-info-username-age-wrapper {
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.chat-v2-user-info-username {
  font-size: 14px;
  font-weight: 700;
}

.chat-v2-user-info-age {
  font-size: 12px;
  font-weight: 400;
}

.chat-v2-user-info-verification-icon {
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  --fa-primary-color: #fff;
  --fa-secondary-color: #105bfb;
  margin-right: 5px;
}

.chat-v2-photo-count-text {
  margin: 0;
  font-size: 14px;
  color: #021e30;
}

.chat-v2-form {
  height: 100%;
  width: 100%;
}

.chat-v2-inputgroup {
  height: 100%;
  border: 1px solid #f4f4f4;
  background-color: #fff;
}

.chat-v2-input {
  height: 90%;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: #fcfcfc;
  border: 1px solid #f4f4f4;
  border-radius: 3px !important;
  color: #b5b5b5;
}

.chat-v2-send-btn-disabled {
  background-color: #b5b5b5 !important;
  border-color: #b5b5b5 !important;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 22px !important;
  color: #fff;
}

.chat-v2-send-btn {
  background-color: #021e30 !important;
  border-color: #021e30 !important;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 22px !important;
  color: #fff;
}

.chat-v2-compliment-btn {
  background-color: #fff;
  border-color: #fff;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 22px;
  color: #c44b4b;
}

.chat-v2-compliment-btn-disabled {
  background-color: #fff;
  border-color: #fff;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 22px;
  color: #b5b5b5 !important;
}

.chat-v2-loading-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}
