.desktop-likes-tab {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.desktop-likes-content-container {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.desktop-likes-card {
  flex-basis: 24%;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
  border: 1px solid #888;
  border-radius: 5px;
}

.desktop-likes-card-body {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.desktop-likes-card-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.desktop-likes-card-image-blur {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px !important;
  filter: blur(10px) !important;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
}

.desktop-likes-card-image-placeholder {
  width: 100%;
  height: 350px;
  background-color: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.desktop-likes-card-image-placeholder-blur {
  width: 100%;
  height: 350px;
  background-color: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  filter: blur(10px) !important;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
}

.desktop-likes-card-placeholder-icon {
  color: rgb(153, 153, 153);
  font-size: 2.5rem;
}

.desktop-likes-tab-loading-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
}

// ! Desktop Likes Card User Info Section

.desktop-likes-card-user-info-wrapper {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 0.5508578431372548) 66%,
    rgba(255, 255, 255, 0) 100%
  );
}

.desktop-likes-card-info-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.desktop-likes-card-username-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desktop-likes-card-username {
  font-size: 1.1rem;
  margin: 0;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desktop-likes-card-verified-icon {
  color: #007bff;
  font-size: 1rem;
  --fa-primary-color: #fff;
  --fa-secondary-color: #007bff;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  margin-left: 5px;
}

.desktop-likes-card-age-location-wrap {
  max-width: 70%;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desktop-likes-card-age-location {
  color: #dbdbdb;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.desktop-likes-card-camera-icon {
  color: #dbdbdb;
  font-size: 0.9rem;
  margin-right: 5px;
}

.desktop-likes-card-image-count {
  color: #dbdbdb;
  font-size: 0.9rem;
}

// ! Desktop Likes Card Footer / Actions

.desktop-likes-card-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #dbdbdb;
}

.desktop-likes-card-actions-wrapper {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.desktop-likes-card-action-button {
  border-radius: 50% !important;
  background-color: rgba(0, 0, 0, 0.85) !important;
  border-color: rgba(0, 0, 0, 0.85) !important;
  z-index: 2;
  height: 55px;
  width: 55px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.desktop-likes-card-icon-like {
  color: #55eac4;
  font-size: 1.5rem;
}

.desktop-likes-card-icon-dislike {
  color: #fd277d;
  font-size: 1.5rem;
}

// ! Desktop Likes Match Toast

.desktop-likes-match-toast {
  position: fixed !important;
  bottom: 0;
  right: 0;
  width: 350px;
  height: 135px;
  z-index: 3;
}

.desktop-likes-match-toast-header {
  display: flex;
  justify-content: space-between;
}

.desktop-likes-match-toast-header-content {
  display: flex;
}

.desktop-likes-match-toast-header-title-wrapper {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.desktop-likes-match-toast-title {
  font-size: 1rem !important;
  color: #464646;
  font-weight: 600;
  margin: 0;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.desktop-likes-match-toast-image {
  height: 100%;
  width: 55px;
  object-fit: cover;
  border-radius: 5px;
}

.desktop-likes-match-toast-body {
  display: flex;
}

.desktop-likes-match-toast-body-content {
  padding-left: 1rem;
}

.desktop-likes-match-toast-subtitle {
  margin: 0;
  font-size: 0.875rem;
  color: #464646;
  font-weight: 400;
}

.desktop-likes-match-toast-button {
  margin-top: 5px;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
}

// ! No Likes Section

.desktop-no-likes-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
}

.desktop-no-likes-innerwrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-no-likes-icon {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.desktop-no-likes-subtitle {
}

.no-likes-search-icon {
  margin-right: 10px;
}

.desktop-no-likes-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
}

// ! Unlock Wrapper

.desktop-likes-card-unlock-wrapper {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-likes-card-unlock-innerwrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 50%;
  padding: 0.7rem;
  border-radius: 10px;
}

.desktop-likes-card-unlock-icon {
  width: 50%;
}

.desktop-likes-card-unlock-title {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  color: #dbdbdb;
}
