.desktop-search-component {
  width: 100%;
  min-height: 100%;
  background-color: rgb(241, 241, 241);
}

.desktop-search-container {
  max-width: 1300px;
  display: flex;
  width: 100%;
  margin: auto;
}

.desktop-search-container-loading-innerwrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
}
