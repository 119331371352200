.settings-v2-section {
  width: 100%;
  padding-bottom: 2.5rem;
}

.settings-v2-section-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0.75rem;
}

.settings-v2-section-content {
  width: 100%;
}

.settings-v2-section-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  display: flex;
  align-items: center;
}

.settings-v2-section-row-icon {
  color: #c44b4b;
  font-size: 16px;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-v2-section-row-title {
  color: #000;
  font-size: 16px;
  width: 40%;
  padding-left: 0.5rem;
}

.settings-v2-section-row-title-full-width {
  color: #000;
  font-size: 16px;
  width: 90%;
  padding-left: 0.5rem;
}

.settings-v2-section-row-content {
  color: #021e30;
  font-size: 14px;
  width: 50%;
  overflow-x: scroll;
  text-align: right;
  padding-right: 1rem;
}

.settings-v2-section-row-action {
  width: 5%;
  color: #021e30;
}

.settings-facebook-icon {
  color: #3b5998;
  margin-top: 2px;
}

.settings-google-icon {
  color: #c44b4b;
  margin-top: 2px;
}

.settings-twitter-icon {
  color: #1da1f2;
  margin-top: 2px;
}

.settings-instagram-icon {
  color: #e1306c;
  margin-top: 2px;
}

.settings-linkedin-icon {
  color: #1da1f2;
  margin-top: 2px;
}

.settings-v2-connect-button {
  background-color: transparent;
  border: 0;
  color: #c44b4b;
  font-size: 14px;
  padding: 0 !important;
}

.settings-v2-right-icon {
  color: #b5b5b5;
  font-size: 12px;
}

.settings-v2-footer-logo {
  max-width: 135px;
}

.settings-v2-section-footer {
  width: 100%;
  padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings-v2-footer-version-text {
  font-size: 14px;
  text-align: center;
  color: #b5b5b5;
  margin-top: 5px;
}

.settings-v2-footer-manage-subscription-button {
  width: 100%;
  background-color: #c44b4b;
  border-color: #c44b4b;
  font-size: 16px !important;
  font-weight: 700;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-top: 1rem;
}

.settings-v2-footer-delete-account-button {
  background-color: transparent;
  border: 0;
  color: #2c2b2b;
  font-size: 16px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-top: 1rem;
}

.settings-v2-footer-logout-button {
  background-color: transparent;
  border: 0;
  color: #c44b4b;
  font-size: 16px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.check-social-connection {
  margin: 0 !important;
  color: #007bff;
}

.settings-v2-verified-icon {
  color: #43b581;
}

.settings-v2-attention-icon {
  color: #c44b4b;
}
