.desktop-messages-v2 {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  flex: 1;
  overflow: hidden;
  display: flex;
}

.desktop-chat-v2 {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

// ! Desktop Inbox

.desktop-inbox-v2 {
  width: 40%;
  padding-left: 2rem;
  border-right: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}

.desktop-inbox-sort-container {
  width: 95%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.desktop-v2-message-queue {
  flex: 1;
  padding-bottom: 3rem;
  padding-right: 1rem;
}

.desktop-inbox-sort-container .dropdown .dropdown-toggle {
  width: 100%;
  text-align: left;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #f4f4f4 !important;
  color: #021e30 !important;
  font-size: 16px !important;
  display: flex;
  justify-content: space-between;
}

.desktop-dropdown-toggle-title {
  color: #021e30 !important;
  font-size: 16px !important;
}

.desktop-dropdown-menu-inbox {
  width: 100%;
}

.desktop-dropdown-menu-inbox .dropdown-item {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.desktop-icon-message-sort-all {
  margin-bottom: -4px;
  margin-right: 15px;
  background-image: url(../../../../assets/Icons/message-square.svg);
}

.desktop-icon-message-sort-matches {
  margin-bottom: -4px;
  margin-right: 15px;
  background-image: url(../../../../assets/Icons/heart.svg);
}

.desktop-icon-message-sort-online {
  margin-bottom: -4px;
  margin-right: 15px;
  background-image: url(../../../../assets/Icons/wifi.svg);
}

.desktop-icon-message-sort-nearest {
  margin-bottom: -4px;
  margin-right: 15px;
  background-image: url(../../../../assets/Icons/star.svg);
}

.desktop-icon-message-sort-compliments {
  margin-bottom: -4px;
  margin-right: 15px;
  background-image: url(../../../../assets/Icons/gift.png);
}

// ! Desktop Chat

.desktop-chat-v2-none {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.desktop-chat-select-convo-text {
  font-size: 20px;
  font-weight: 700;
  color: #021e30;
}

.desktop-chat-select-convo-subtitle {
  font-size: 16px;
  color: #b5b5b5;
}

.desktop-chat-v2-header {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  position: relative;
  border-bottom: 1px solid #f4f4f4;
}

.desktop-chat-v2-image-wrapper {
}

.desktop-chat-v2-image {
  height: 90px;
  width: 70px;
  object-fit: cover;
  border-radius: 3px;
  cursor: pointer;
}

.desktop-chat-v2-user-info-wrapper {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.desktop-chat-verified-icon {
  height: 16px;
  width: 16px;
  margin-bottom: 8px;
  margin-right: 5px;
}

.desktop-chat-v2-username {
  font-size: 14px;
  color: #000;
  font-weight: 700;
}

.desktop-chat-v2-actions-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1.5rem;
  padding-top: 1rem;
}

.desktop-chat-v2-photo-count {
  font-size: 14px;
  font-weight: 500;
  color: #021e30;
}

.desktop-chat-v2-match-text {
  font-size: 12px;
  font-weight: 500;
  color: #b5b5b5;
}

.desktop-chat-v2-menu-button {
  height: 43px;
  width: 43px;
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.desktop-chat-v2-convo-wrapper {
  flex: 1;
  padding-bottom: 65px;
  overflow-y: scroll;
  padding-right: 1rem;
  padding-top: 1rem;
}

.desktop-chat-v2-convo {
}

.desktop-chat-v2-no-messages-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.desktop-chat-bottom-spacer {
}

.desktop-chat-top-spacer {
}

.desktop-chat-v2-input {
  width: 100%;
  height: 50px;
  border-top: 1px solid #f4f4f4;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 2;
}

.desktop-chat-v2-inputgroup {
  display: flex;
  align-items: center;
  padding-left: 0.25rem;
}

.desktop-chat-v2-form-control {
  height: 43px;
  border: 1px solid #f4f4f4;
  margin-right: 0.25rem;
  background-color: #fcfcfc;
  color: #021e30;
  font-size: 14px;
  font-weight: 500;
}

// Desktop New Matches

.desktop-queue-match-container {
  max-width: 100%;
  display: flex;
  overflow-x: scroll;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.desktop-queue-image-wrapper {
  width: 100px;
  height: 133px;
  position: relative;
  margin-right: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}
.desktop-match-indicator-v2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.desktop-match-image {
  width: 100px;
  height: 133px;
  object-fit: cover;
  border-radius: 5px;
}

.desktop-match-indicator-v2 {
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: -3px;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  background-color: #d95454;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 4px;
}

.desktop-match-end-spacer {
  padding-right: 1rem;
}

.desktop-queue-match-title {
  font-size: 16px;
  font-weight: 700;
  color: #021e30;
  margin-top: 1rem;
  margin-bottom: 0;
}
