.desktop-v2-request-none-container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.desktop-v2-request-content-container {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
}

.desktop-v2-request-none-image {
  height: 110px;
  width: 110px;
  object-fit: cover;
  margin-bottom: 2rem;
}

.desktop-v2-request-none-title {
  font-size: 20px;
  font-weight: 700;
  color: #021e30;
  text-align: center;
  margin-bottom: 1rem;
}

.desktop-v2-request-none-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #021e30;
  text-align: center;
  width: 80%;
}
