.user-card-actions-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100% !important;
  width: 100% !important;
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.user-card-actions-card {
  width: 100% !important;
  height: 100% !important;
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.user-card-actions-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-card-actions-btn {
  background-color: rgba(1, 1, 1, 0.85);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 2px 6px 0px rgba(112, 125, 134, 0.65);
  height: 100%;
  width: 100%;
  transition: height 15ms, width 15ms;
}

.user-card-actions-btn-icon {
  font-size: 1.5rem;
  color: #fff;
}

.favorite-btn-icon {
  color: #55eac4;
}

.superlike-btn-icon {
  color: #32b5ff;
}

.superlike-btn-icon-disabled {
  color: rgba(28, 28, 28, 0.85);
}

.note-btn-icon-disabled {
  color: rgba(28, 28, 28, 0.85);
}

.note-btn-icon {
  color: #f75590;
}

.undo-btn-icon {
  color: #ffad08;
}

.message-btn-icon-disabled {
  color: rgba(28, 28, 28, 0.85);
}

.message-btn-icon {
  color: #dbdbdb;
}
