.home-nav {
  height: 10%;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.home-nav-innerwrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: auto;
}

.home-nav-open {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
}

.home-nav-icon-wrapper {
}

.home-nav-menu-wrapper {
}

.home-nav-icon {
  height: 35px;
  cursor: pointer;
}

.home-nav-menu-button {
  background-color: transparent !important;
  border-color: transparent !important;
}

.home-nav-menu-icon {
  color: #021e30;
  font-size: 1.9rem;
}

// !Home Nav Dropdown

.home-nav-dropdown {
  height: 90%;
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  overflow-y: scroll;
}

.home-nav-dropdown-nav {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-left: 1rem;
}

.home-nav-dropdown-link {
  color: #000;
  font-size: 2.5rem;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  line-height: 1;
  margin-bottom: 1.5rem;
  font-family: Modern Era, sans-serif;
}

.dropdown-spacer {
  margin-top: 2.5rem;
}

.home-nav-dropdown-social-wrapper {
  padding-top: 4rem;
}

.home-nav-dropdown-social-icon {
  margin-left: 1rem;
  margin-right: 1rem;
}

.home-nav-desktop-links {
  display: none;
}

@media (min-width: 767px) {
  .home-nav {
    background-color: #021e30;
  }

  .home-nav-menu-wrapper {
    display: none;
  }

  // .home-nav-icon {
  //   display: none;
  // }

  .home-nav-desktop-links {
    display: flex;
    padding-right: 5rem;
  }

  .home-nav-desktop-link {
    color: #fff;
    font-size: 1.25rem;
    text-decoration: none;
    font-weight: 500;
    margin-left: 3rem;
  }
}

// ! Below Styles Aren't Used

// .homenav-navbar {
//   background-color: #090a20;
//   position: fixed;
//   top: 0;
//   width: 100%;
//   z-index: 200;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }

// .homenav-logo {
//   height: 20px;
//   width: 136px;
// }

// .homenav-logo-wrap {
//   display: flex;
//   align-items: center;
// }

// .homenav-hamburger-icon {
//   font-size: 1.7rem;
//   color: #fafafa;
// }

// .homenav-close-icon {
//   font-size: 1.7rem;
//   color: #fafafa;
// }

// .homenav-navbar-innerwrap {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding-top: 1rem;
//   padding-bottom: 1rem;
//   padding-left: 1rem;
//   padding-right: 1rem;
//   max-width: 1140px;
//   width: 100%;
// }

// .homenav-dropdown {
//   background-color: #fafafa;
//   width: 100%;
// }

// .homenav-dropdown-nav {
//   height: 450px;
//   overflow-y: scroll;
// }

// .homenav-dropdown-ul {
//   list-style: none;
//   padding-left: 0;
// }

// .homenav-dropdown-li {
//   padding: 20px;
//   border-bottom: 1px solid #eee;
//   width: 100%;
// }

// .homenav-subdropdown-li {
//   padding: 15px;
//   padding-left: 50px;
//   border-bottom: 1px solid #eee;
//   width: 100%;
// }

// .home-dropdown-learn-icon {
//   color: #da0e2b;
// }

// .homenav-learn-link {
//   color: #da0e2b;
//   margin-right: 5px !important;
//   font-size: 1.2rem;
//   font-weight: 500;
// }

// .homenav-dropdown-link {
//   color: #da0e2b;
//   font-size: 1.2rem;
//   font-weight: 500;
// }

// .homenav-login-link {
//   display: none;
// }

// .homenav-signup-wrap {
//   display: flex;
// }

// .homenav-login-btn {
//   color: #fafafa !important;
// }

// .homenav-desktop-nav-wrap {
//   display: none;
// }

// .homenav-leftside-wrap {
//   display: flex;
// }

// .homenav-desktop-btn {
//   color: #dbdbdb !important;
//   font-weight: 600 !important;
// }

// .homenav-desktop-btn:hover {
//   color: #da0e2b !important;
//   font-weight: 600 !important;
//   text-decoration: none !important;
// }

// .homenav-desktop-btn button {
//   color: #dbdbdb !important;
//   font-weight: 600 !important;
// }

// .homenav-desktop-btn:hover button {
//   color: #da0e2b !important;
//   font-weight: 600 !important;
//   text-decoration: none !important;
// }

// .homenav-desktop-btn:active button {
//   color: #da0e2b !important;
//   font-weight: 600 !important;
//   text-decoration: none !important;
// }

// .homenav-desktop-btn:focus button {
//   color: #da0e2b !important;
//   font-weight: 600 !important;
//   text-decoration: none !important;
// }

// .homenav-join-free-btn {
//   background-color: #da0e2b !important;
//   border-color: #da0e2b !important;
//   color: #fafafa !important;
//   font-weight: 600 !important;
// }

// .homenav-dropdown-login-btn {
//   background-color: #090a20 !important;
//   border-color: #090a20 !important;
//   color: #fafafa !important;
//   font-weight: 600 !important;
//   font-size: 1.2rem !important;
//   width: 100px !important;
// }

// @media (min-width: 767px) {
//   .homenav-login-link {
//     display: block;
//   }

//   .homenav-hamburger {
//     display: none;
//   }

//   .homenav-dropdown {
//     display: none;
//   }

//   .homenav-desktop-nav-wrap {
//     display: flex;
//     padding-left: 2rem;
//   }
// }
