.desktop-matches-content {
  width: 75%;
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.desktop-matches-container {
  width: 100%;
  padding-top: 2rem;
  padding-left: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.desktop-matches-navigation {
  width: 100%;
  max-width: 300px;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.desktop-matches-nav-tab {
  width: 50%;
  text-align: center;
  font-size: 14px;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #eeeeee;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 500;
  color: grey;
  cursor: pointer;
}

.desktop-matches-nav-tab-active {
  width: 50%;
  text-align: center;
  font-size: 14px;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #021e30;
  font-weight: 700;
  color: #021e30;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}

.desktop-matches-results {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 2rem;
  height: 0;
  flex: 1;
}

.desktop-matches-results-innerwrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: scroll;
}

// Likes Card

.desktop-v2-likes-card {
  height: 248px;
  width: 164px;
  border-radius: 5px;
  border: 1px solid #f4f4f4;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
}

.desktop-v2-likes-card-image-wrapper {
  height: 200px;
  width: 100%;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.desktop-v2-likes-card-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.desktop-v2-likes-card-footer {
  width: 100%;
  height: 48px;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.desktop-v2-likes-card-action-button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.desktop-v2-likes-card-action-icon {
  width: 32px;
  height: 32px;
}

.desktop-v2-likes-card-user-info-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 1rem;
  padding-bottom: 1rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(255, 255, 255, 0) 50%
  );
}

.desktop-v2-likes-card-username {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.desktop-v2-likes-card-age {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}
