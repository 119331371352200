.photo-grid-component {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  padding-bottom: 1.5rem !important;
  max-width: 500px;
  margin: auto;
}

.photo-grid-header {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.photo-grid-title {
  color: #424242 !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.photo-grid-footer {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  padding-top: 1rem !important;
}

.photogrid-footer-text {
  text-align: center;
  font-size: 0.8rem;
  color: #7f7f7f;
}

// ! Photo Upload Grid Section

.photo-grid-body {
  width: 100%;
  display: flex;
  flex-wrap: wrap !important;
  padding: 0 !important;
  position: relative;
}

.photo-grid-label {
  flex-basis: 30% !important;
  height: 150px !important;
  margin-bottom: 0.7rem !important;
  border-radius: 10px !important;
  z-index: 100;
}

.photo-upload-card {
  flex-basis: 30% !important;
  height: 150px !important;
  background: #e4e5eb !important;
  border: 0 !important;
  position: relative;
  z-index: 100;
  margin: 0.25rem;
}

.photo-upload-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px !important;
  border: 0 !important;
}

.photo-upload-btn {
  border-radius: 50% !important;
  width: 35px !important;
  height: 35px !important;
  background-color: transparent;
  border: none !important;
  position: absolute;
  bottom: 55px;
  right: 35px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #021E30;
  outline: none !important;
  &:active, &:focus, &:hover, &:visited {
    background-color: transparent !important;
    color: #021E30;
    border: none !important;
    outline: none !important;
  }
}

.photo-upload-icon {
  font-size: 1.4rem;
  margin-left: 1px;
  margin-top: 1px;
}

.photo-grid-input {
  display: none;
}

.photo-grid-uploading-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 200;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-progress-card {
  height: 50%;
  width: 40%;
  background-color: transparent !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  border-radius: 15px !important;
}

.upload-progress-card-body {
  background-color: #fff !important;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  border-radius: 15px !important;
}

.upload-progress-bar {
  width: 100%;
}

.photo-view-btn {
  border-radius: 50% !important;
  width: 35px !important;
  height: 35px !important;
  background-color: transparent;
  border: none !important;
  position: absolute;
  bottom: -5px;
  right: -5px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
