.profile-actions-component {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-evenly;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
}

.profile-card-button {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background-color: rgba(1, 1, 1, 0.95);
  box-shadow: 0px 2px 6px 0px rgba(112, 125, 134, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #dbdbdb;
}

.profile-card-actions-btn-icon-message {
  color: #dbdbdb;
  font-size: 2rem;
}

.profile-card-actions-btn-icon-like {
  color: #55eac4;
  font-size: 2rem;
}

.profile-card-actions-btn-icon-undo {
  color: #ffad08;
  font-size: 2rem;
}

.profile-card-actions-btn-icon-superlike {
  color: #32b5ff;
  font-size: 2rem;
}

.profile-card-actions-btn-icon-superlike-disabled {
  color: rgba(1, 1, 1, 0.95);
  font-size: 2rem;
}
