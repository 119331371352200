.desktop-swipe-v2 {
  display: flex;
  width: 100%;
  height: 100%;
}

.desktop-swipe-v2-innerwrap {
  border: 1px solid #f4f4f4;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;
}

.desktop-swipe-v2-image-container {
  width: 50%;
  height: 100%;
  border-radius: 3px;
  position: relative;
}
.desktop-swipe-v2-image-container-placeholder {
  width: 50%;
  height: 100%;
  border-radius: 3px;
  position: relative;
  background-color: #f4f4f4;
}

.desktop-main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

.desktop-swipe-v2-profile-container {
  width: 50%;
  max-height: 100%;
  padding-left: 2rem;
  padding-right: 4rem;
  padding-top: 1.5rem;
  overflow-y: scroll;
}

.desktop-swipe-v2-profile-container-placeholder {
  width: 50%;
  max-height: 100%;
  padding-left: 2rem;
  padding-right: 4rem;
  padding-top: 1.5rem;
  background-color: #f4f4f4;
}

.desktop-swipe-v2-profile-info {
  border-bottom: 1px solid #f4f4f4;
}

.desktop-swipe-v2-profile-title {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  margin: 0;
}

.desktop-swipe-v2-profile-age {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin: 0;
}

.desktop-swipe-v2-profile-address {
  font-size: 14px;
  font-weight: 500;
  color: #b5b5b5;
  margin-top: 0.5rem;
}

.desktop-swipe-v2-profile-title-wrapper {
  display: flex;
  align-items: center;
}

.verification-icon-swipe-v2-desktop {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.desktop-swipe-v2-loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}
