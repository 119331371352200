.features-card {
  border-radius: 15px !important;
  max-width: 1140px;
  margin: auto;
  margin-top: 1rem;
}

.features-card-body {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 15px !important;
}

.features-card-title-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
}

.features-card-title-icon {
  margin-right: 0.5rem;
  color: #c44b4b;
  font-size: 1.5rem;
}

.features-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #444444;
}

.features-card-feature {
  border-radius: 15px !important;
  margin-top: 1rem !important;
  flex: 1 !important;
  flex-basis: 48% !important;
}

.features-card-feature-body {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
}

.feature-card-feature-title {
  text-align: center;
  color: #444444;
  font-size: 1.2rem;
  margin-bottom: 0;
  margin-top: 7px;
}

.feature-card-feature-icon-heart {
  font-size: 2rem;
  color: #55eac4;
}

.feature-card-feature-icon-stars {
  font-size: 2rem;
  color: #32b5ff;
}

.feature-card-feature-icon-bolt {
  font-size: 2rem;
  color: #9a4ed9;
}

.feature-card-feature-icon-message {
  font-size: 2rem;
  color: #5804d7;
}

.feature-card-feature-icon-filters {
  font-size: 2rem;
  color: #9d46e7;
}

.feature-card-feature-icon-undo {
  font-size: 2rem;
  color: #ffad08;
}

.feature-card-feature-icon-read {
  font-size: 2rem;
  color: #32b5ff;
}

.feature-card-feature-icon-eye {
  font-size: 2rem;
  color: #090a20;
}

.feature-card-feature-icon-note {
  font-size: 2rem;
  color: #f75590;
}

.feature-card-feature-description {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0;
  color: #444444;
}
