.welcome-modal-card {
}

.welcome-modal-card-body {
}

.welcome-modal-card-title {
  text-align: center;
  font-weight: 700 !important;
}

.welcome-modal-subtitle {
  text-align: center;
  font-size: 1.15rem;
  font-style: italic;
  font-weight: 500;
}

.welcome-modal-card-content-wrapper {
}

.welcome-modal-card-step {
  width: 100%;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.welcome-step-image-wrapper {
  width: 20%;
}

.welcome-step-image {
  width: 100%;
  object-fit: cover;
}

.welcome-step-content-wrapper {
  width: 80%;
  padding-left: 0.85rem;
}

.welcome-step-title {
  font-size: 1rem;
  text-decoration: underline;
}

.welcome-step-content {
  font-size: 0.9rem;
  margin: 0;
}

.welcome-modal-card-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.welcome-modal-card-button {
  width: 100%;
  background-color: #da0e2b !important;
  border-color: #da0e2b !important;
}

.welcome-modal-safety-content-container {
  padding-top: 0.75rem;
}

.welcome-modal-safety-title {
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.welcome-modal-safety-list {
  padding-bottom: 1rem;
}

.welcome-modal-safety-list-item {
  margin-bottom: 0.35rem;
  font-weight: 600;
}
