.swipe-profile-container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: #fff !important;
  position: absolute;
}

.swipe-like-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
  background-color: #c44b4b;
  border: 1px solid red;
}

.swipe-dislike-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #021e30;
  z-index: 5;
}

.swipe-profile-component-x {
}

.swipe-profile-container-innerwrap {
  width: 100%;
  background-color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
}

.swipe-profile-inner-innerwrap {
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-top: 0;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}

.swipe-profile-user-component {
  height: 90%;
  width: 100%;
  overflow-y: scroll;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fff !important;
}

.secondary-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 15px !important;
}

.secondary-image-wrapper {
  width: 95%;
  height: 100%;
  max-width: 1140px !important;
  margin: auto;
  margin-top: 1.5rem;
}

.swipeable-image {
  height: 100%;
}

.main-image-banner-liked {
  position: fixed;
  top: 10%;
  left: 39%;
  border: 3px solid #55eac4;
  padding: 0.5rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image-banner-text-liked {
  text-transform: uppercase;
  color: #55eac4;
  font-size: 0.7rem;
  font-weight: 800;
  text-align: center;
  line-height: 1.2;
}

.main-image-banner-superliked {
  position: fixed;
  top: 10%;
  left: 37%;
  border: 3px solid #32b5ff;
  padding: 0.5rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  text-align: center;
  line-height: 0.7;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image-banner-text-superliked {
  text-transform: uppercase;
  color: #32b5ff;
  font-size: 0.7rem;
  font-weight: 800;
  text-align: center;
  line-height: 1.2;
}

.main-image-banner-note {
  position: fixed;
  top: 10%;
  left: 36%;
  border: 3px solid #f75590;
  padding: 0.5rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image-banner-text-note {
  text-transform: uppercase;
  color: #f75590;
  font-size: 0.7rem;
  font-weight: 800;
  text-align: center;
  line-height: 1.2;
}

.profile-banner-icon-superlike {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #32b5ff;
}

.profile-banner-icon-like {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #55eac4;
}

.profile-banner-icon-note {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #f75590;
}
