.basic-info-content {
  padding: 1rem;
}

.basic-info-row {
  width: 100%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
}

.basic-info-title {
  font-size: 16px;
  font-weight: 500;
  color: #021e30;
  margin: 0;
}

.basic-info-action {
  display: flex;
  align-items: center;
}

.basic-info-content-text {
  font-size: 14px;
  font-weight: 500;
  color: #021e30;
  margin: 0;
  margin-right: 1rem;
  text-transform: capitalize;
}

.right-grey-chevron {
  font-size: 14px;
  color: #b5b5b5;
  margin-top: 4px;
}
