.desktop-search-filters-component {
  width: 100%;
}

// ! Search Sidebar Header

.desktop-search-header {
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid #dbdbdb;
}

.desktop-search-header-title {
  color: #464646;
  font-size: 2rem;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 1rem;
}

.desktop-search-header-button-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.desktop-search-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
}

.desktop-reset-filters-button {
  background-color: transparent !important;
  border: 0 !important;
  color: #464646 !important;
}

// ! Slider Styles

.desktop-search-section-form-group .MuiSlider-thumbColorPrimary {
  color: #c44b4b;
  width: 22px;
  height: 22px;
  margin-top: -8px;
}

.desktop-search-section-form-group .MuiSlider-thumb {
  transition: box-shadow 150ms cubic-bezier(0, 0, 0, 1) 0ms !important;
}

.desktop-search-section-form-group .MuiSlider-track {
  color: #c44b4b;
  height: 5px;
}

.desktop-search-section-form-group .MuiSlider-root {
  color: #c44b4b;
  height: 5px;
}

.desktop-search-section-form-group .MuiSlider-rail {
  color: #c7c7cb;
  height: 5px;
}

// ! Search Section Styles

.desktop-search-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #dbdbdb;
}

.desktop-search-section-no-border {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

// * Search Item Title / Header

.desktop-search-section-title-wrapper {
  display: flex;
  justify-content: space-between;
}

.desktop-search-section-title {
  text-transform: uppercase;
  font-size: 1rem;
  color: #464646;
  font-weight: 700;
}

.desktop-search-section-toggle-button {
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.desktop-search-section-toggle-icon {
  color: #464646;
}

.desktop-search-section-form-wrapper {
  padding-top: 0.5rem;
}

.desktop-search-section-form-group {
  margin: 0 !important;
}

.desktop-search-section-form-group-checkboxes {
  padding-left: 1rem;
}

.desktop-search-section-label {
  color: #464646;
}

.desktop-search-filters-checkbox {
  margin-bottom: 8px;
}

.desktop-search-filters-checkbox label {
  cursor: pointer;
}

.desktop-search-filters-checkbox input {
  cursor: pointer;
}
