.desktop-profile-summary-container {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  padding: 1.5rem;
  display: flex;
}

.desktop-profile-summary-image-wrapper {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

.desktop-profile-summary-main-image {
  object-fit: cover;
  width: 200px;
  height: 250px;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.desktop-profile-summary-no-images-placeholder {
  height: 250px;
  width: 200px;
  background-color: #dbdbdb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 0.5rem;
}

.desktop-profile-summary-no-images-placeholder-icon {
  color: rgb(153, 153, 153);
  font-size: 2.5rem;
}

.desktop-profile-summary-no-images-title {
  text-align: center;
  width: 75%;
  margin-top: 10px;
  color: #888;
}

.desktop-profile-summary-add-photos-button {
  width: 100% !important;
  border-radius: 5px !important;
  border-color: #888 !important;
  color: #888 !important;
  background-color: #f9f9f9 !important;
  font-weight: 500 !important;
}

.desktop-profile-summary-content-container {
  width: 100%;
  padding-left: 2.5rem;
  position: relative;
}

.desktop-profile-summary-user-info-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.desktop-profile-summary-username {
  font-weight: 700;
  color: #464646;
  margin: 0;
}

.desktop-profile-summary-add-photos-icon {
  margin-right: 10px;
}

.desktop-profile-summary-age-location {
  margin: 0;
  color: #464646;
}

.desktop-profile-summary-logout-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
}

.desktop-profile-summary-logout-button {
  background-color: transparent !important;
  border: 0 !important;
  color: #464646 !important;
  text-decoration: underline !important;
  font-weight: 500 !important;
}

.desktop-profile-summary-logout-icon {
  margin-right: 10px;
}

.desktop-profile-summary-body-wrapper {
  width: 100%;
  display: flex;
}

.desktop-profile-summary-progress-wrapper {
  width: 33%;
  padding: 1rem;
  height: 202px;
  max-height: 202px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-left: 3px;
  margin-right: 3px;
  overflow: hidden;
}

.desktop-profile-summary-inventory-wrapper {
  width: 33%;
  padding: 1rem;
  height: 202px;
  max-height: 202px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-left: 3px;
  margin-right: 3px;
  overflow: hidden;
  position: relative;
}

.desktop-profile-summary-quicklinks-wrapper {
  width: 33%;
  padding: 1rem;
  height: 202px;
  max-height: 202px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-left: 3px;
  margin-right: 3px;
  overflow: hidden;
  position: relative;
}

.desktop-profile-summary-section-title {
  text-align: center;
  color: #464646;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: underline;
}

.desktop-profile-summary-premium-cta-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  font-weight: 500 !important;
}

.desktop-profile-summary-premium-cta-icon {
  margin-right: 10px;
}

.desktop-profile-progress-wrapper {
  height: 100%;
  width: 100%;
  padding-top: 0.5rem;
}

.desktop-profile-progress-circle {
  height: 42%;
  color: #090a20 !important;
}

.CircularProgressbar-path {
  stroke: #090a20 !important;
}

.CircularProgressbar-text {
  fill: #090a20 !important;
}

.desktop-profile-progress-next-step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
}

.desktop-profile-progress-next-step-text {
  margin: 0;
  color: #888;
  text-align: center;
  margin-bottom: 2px;
}

.desktop-profile-progress-next-step-button {
  background-color: #6d6f8c !important;
  border-color: #6d6f8c !important;
}

.desktop-profile-summary-quicklinks-list {
  text-decoration: none;
}

.desktop-profile-summary-quicklinks-list-item {
  text-decoration: none;
  color: #464646;
}

.desktop-profile-summary-quicklinks-list-item:hover {
  text-decoration: underline;
  color: #c44b4b;
}

.desktop-profile-summary-quicklinks-link {
  text-decoration: none !important;
}

.desktop-profile-summary-inventory-innerwrap {
}

.desktop-profile-summary-inventory-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.desktop-profile-summary-inventory-superlike-icon {
  margin-right: 5px;
  color: #3578f6;
}

.desktop-profile-summary-inventory-boost-icon {
  margin-right: 5px;
  color: #9a4ed9;
}

.desktop-profile-summary-inventory-section-title {
  color: #464646;
}

.desktop-profile-summary-inventory-counter {
  color: #464646;
  font-weight: 500;
}

.desktop-profile-summary-inventory-cta-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
}

.desktop-profile-summary-inventory-cta-button {
  width: 50% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  background-color: #fff !important;
  border-color: #dbdbdb !important;
}

.desktop-profile-summary-inventory-superlike-icon-cta {
  margin-bottom: 5px;
  margin-top: 5px;
  color: #3578f6;
}

.desktop-profile-summary-inventory-boost-icon-cta {
  margin-bottom: 5px;
  margin-top: 5px;
  color: #9a4ed9;
}

.desktop-profile-summary-inventory-cta-button-text {
  color: #464646;
  font-size: 0.75rem;
}

.desktop-profile-form-group .MuiSlider-thumbColorPrimary {
  color: #c44b4b;
  width: 22px;
  height: 22px;
  margin-top: -8px;
}

.desktop-profile-form-group .MuiSlider-thumb {
  transition: box-shadow 150ms cubic-bezier(0, 0, 0, 1) 0ms !important;
}

.desktop-profile-form-group .MuiSlider-track {
  color: #c44b4b;
  height: 5px;
}

.desktop-profile-form-group .MuiSlider-root {
  color: #c44b4b;
  height: 5px;
}

.desktop-profile-form-group .MuiSlider-rail {
  color: #c7c7cb;
  height: 5px;
}
