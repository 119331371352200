.desktop-profile-tab-component {
  position: relative;
}

.desktop-profile-tab-header {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #aeaeae;
}

.desktop-profile-tab-header-title {
  margin: 0;
  font-size: 1.6rem;
  color: #464646;
}

.desktop-profile-tab-content-container {
  background-color: #fff;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.desktop-profile-tab-content-container-photos {
  background-color: #fff;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  position: relative;
}

.desktop-profile-tab-section {
  padding: 2rem;
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
  display: flex;
}

.desktop-profile-tab-section-title-wrap {
  width: 17%;
}

.desktop-profile-tab-section-title-wrap-verifications {
  width: 35%;
}

.desktop-profile-tab-section-title {
  margin: 0;
  color: #464646;
  font-weight: 700;
  font-size: 1rem;
  text-align: right;
}

.desktop-profile-tab-section-title-verifications {
  margin: 0;
  color: #464646;
  font-weight: 700;
  font-size: 1rem;
  text-align: left;
}

.desktop-profile-tab-section-form-wrapper {
  width: 35%;
  padding-left: 2rem;
  padding-right: 1rem;
}

.desktop-profile-tab-section-form-wrapper-verifications {
  width: 65%;
  padding-left: 2rem;
  padding-right: 1rem;
  max-width: 350px;
}

.desktop-profile-tab-section-form-wrapper-settings {
  width: 65%;
  padding-left: 3rem;
  padding-right: 1rem;
  max-width: 400px;
}

.desktop-profile-tab-section-form-wrapper-description {
  width: 65%;
  padding-left: 2rem;
  padding-right: 1rem;
}

.desktop-profile-tab-section-form-wrapper-benefits {
  width: 100%;
  padding-left: 2rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.desktop-profile-form-footer {
  padding-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.desktop-profile-form-save-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.desktop-profile-form-save-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
}

.desktop-profile-form-label {
  color: #464646 !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.2 !important;
  margin-bottom: 1rem;
}

.desktop-profile-tab-section-description-wrapper {
  width: 48%;
  padding-left: 2rem;
  padding-right: 1rem;
}

.desktop-profile-tab-section-description-text {
  color: #888;
  font-size: 0.875rem;
}

// ! Slider Styles

.desktop-profile-form-group .MuiSlider-thumbColorPrimary {
  color: #c44b4b;
  width: 22px;
  height: 22px;
  margin-top: -8px;
}

.desktop-profile-form-group .MuiSlider-thumb {
  transition: box-shadow 150ms cubic-bezier(0, 0, 0, 1) 0ms !important;
}

.desktop-profile-form-group .MuiSlider-track {
  color: #c44b4b;
  height: 5px;
}

.desktop-profile-form-group .MuiSlider-root {
  color: #c44b4b;
  height: 5px;
}

.desktop-profile-form-group .MuiSlider-rail {
  color: #c7c7cb;
  height: 5px;
}

// ! Benefits Section

.desktop-profile-tab-section-benefits {
  width: 65%;
}

.desktop-profile-benefits-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-right: 1rem;
  justify-content: space-between;
}

.desktop-profile-benefits-button {
  color: #090a20 !important;
  background-color: #fff !important;
  border-radius: 100px !important;
  font-size: 0.7rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
  border-color: #dbdbdb !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  flex-basis: 32% !important;
  max-width: 32% !important;
}

.desktop-profile-benefits-button-checked {
  color: #fff !important;
  background-color: #090a20 !important;
  border-radius: 100px !important;
  font-size: 0.7rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
  border-color: #090a20 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  flex-basis: 32% !important;
  max-width: 32% !important;
}

// ! Verifications Component

.desktop-verify-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
}

.desktop-verify-advance-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
}

// ! Photos Section

.desktop-profile-photos-cta {
  padding: 2rem;
  border: 1px dashed #aeaeae;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
}

.desktop-photos-upload-input-wrapper {
  padding-bottom: 1rem;
}

.desktop-photos-cta-subtitle-wrapper {
  max-width: 500px;
  text-align: center;
}

.desktop-photo-input {
  display: none;
}

.desktop-photos-cta-subtitle {
  margin: 0;
  color: #aeaeae;
  font-size: 0.9rem;
  font-weight: 500;
}

.desktop-photo-input-upload-button {
  background-color: #fff !important;
  border-color: #464646 !important;
  color: #464646 !important;
  font-weight: 500 !important;
}

.desktop-profile-photos-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}

.desktop-image-wrapper {
  width: 24%;
  position: relative;
  border-radius: 5px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.desktop-image {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.desktop-image-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.5rem;
}

.desktop-image-settings-button {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-color: #888 !important;
}

.desktop-image-settings-button::after {
  display: none !important;
}

.desktop-image-settings-icon {
  color: #f9f9f9;
  font-size: 1.2rem;
}

.desktop-profile-photos-uploading-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.desktop-profile-photos-uploading-innerwrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 2.5rem;
  border-radius: 15px;
}

.desktop-profile-photos-uploading-title {
  color: #f9f9f9;
  margin-bottom: 1.5rem;
}

.desktop-images-primary-badge {
  background-color: #c44b4b !important;
}

.desktop-profile-edit-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.desktop-profile-edit-button-wrapper-submit {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.desktop-profile-edit-submit-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
}

.desktop-profile-edit-email-button {
  color: #c44b4b !important;
  padding: 0 !important;
}

.desktop-profile-edit-cancel-button {
  padding: 0 !important;
  color: #464646;
}

.desktop-update-password-button {
  color: #c44b4b !important;
  padding: 0 !important;
}
