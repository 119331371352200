.boost-active-popup {
  text-align: center;
}

.boost-active-popup-header {
  position: relative;
  padding-top: 56px;
  padding-bottom: 60px;
  flex-direction: column;
  align-items: center;
  background-image: url('../../../../assets/Images/pattern.png');
  background-position: center;
  background-size: cover;
}

.boost-active-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.boost-active-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.boost-active-back-image {
  height: 63px;
  width: 63px;
  border-radius: 3px;
  border: 2px solid #c44b4b;
  filter: blur(2px);
}

.boost-active-front-image {
  height: 83px;
  width: 83px;
  border-radius: 3px;
  border: 2px solid #c44b4b;
  filter: blur(2px);
}

.boost-active-submit {
  margin-top: 24px;
  width: 335px;
  height: 48px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  background-color: #c44b4b;
  border-color: #c44b4b;
}

.boost-active-badge {
  position: absolute;
  height: 40px;
  width: 65px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 40px;
  text-align: center;
}
