.arrangement-page {
  width: 100%;
}

.arrangement-hero {
  width: 100%;
  background-color: #090a20;
  padding-top: 4rem;
}

.arrangement-hero-content-container {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin: auto;
}

.arrangement-hero-image-wrapper {
  width: 100%;
}

.arrangement-hero-image {
  width: 100%;
}

.arrangement-hero-image-desktop {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.arrangement-hero-content-wrapper {
  padding: 1.5rem;
}

.arrangement-hero-content {
  color: #f9f9f9;
  margin-top: 2rem;
}

.arrangement-hero-title {
  text-align: center;
  color: #fff;
  border-bottom: 2px solid #da0e2b;
  padding-bottom: 1rem;
}

.arrangement-content-container {
  padding: 1.5rem;
  width: 100%;
}

.arrangement-how-it-works {
  max-width: 1100px;
  margin: auto;
}

.arrangement-hiw-title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

@media (min-width: 992px) {
  .arrangement-hero-content-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .arrangement-hero-image-wrapper {
    width: 60%;
    display: flex;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 2rem;
  }

  .arrangement-hero-content-wrapper {
    width: 40%;
  }
}
