.inbox-component {
  width: 100%;
  max-width: 1140px !important;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0.75rem;
}

.inbox-title-wrap {
  display: flex;
  padding-bottom: 1rem;
}

.inbox-title {
  color: #c44b4b;
  font-weight: 600;
  margin: 0;
}

.inbox-count-number {
  color: #fff;
  background-color: #c44b4b;
  height: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px !important;
  margin-left: 7px;
  margin-top: 2px;
  border-radius: 5rem !important;
}

.inbox-sort-wrap {
  width: 95%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.inbox-sort-wrap .dropdown .dropdown-toggle {
  width: 100%;
  text-align: left;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #f4f4f4 !important;
  color: #021e30 !important;
  font-size: 16px !important;
  display: flex;
  justify-content: space-between;
}

.dropdown-toggle-title {
  color: #021e30 !important;
  font-size: 16px !important;
}

.dropdown-menu-inbox {
  width: 100%;
}

.dropdown-menu-inbox .dropdown-item {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.inbox-dropdown-item-icon-wrap {
}

.inbox-dropdown-item-title {
}

.icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: cover;
}

.icon-message-sort-all {
  margin-bottom: -4px;
  margin-right: 15px;
  background-image: url(../../../assets/Icons/message-square.svg);
}

.icon-message-sort-matches {
  margin-bottom: -4px;
  margin-right: 15px;
  background-image: url(../../../assets/Icons/heart.svg);
}

.icon-message-sort-online {
  margin-bottom: -4px;
  margin-right: 15px;
  background-image: url(../../../assets/Icons/wifi.svg);
}

.icon-message-sort-nearest {
  margin-bottom: -4px;
  margin-right: 15px;
  background-image: url(../../../assets/Icons/star.svg);
}

.icon-message-sort-compliments {
  margin-bottom: -4px;
  margin-right: 15px;
  background-image: url(../../../assets/Icons/gift.png);
}
