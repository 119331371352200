.wallet-history-component {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.wallet-sortby-wrapper {
  width: 100%;
  padding-bottom: 0.5rem;
}

.wallet-dropdown {
  width: 100%;
}

.wallet-dropdown-toggle {
  width: 100%;
  display: flex !important;
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 50px;
  align-items: center !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.wallet-toggle-title {
  color: #021e30;
  font-size: 16px;
}

.wallet-toggle-icon {
  color: #021e30;
}

.wallet-history-feed {
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.wallet-history-feed-loading {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compliment-history-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid #eeeeee;
}

.compliment-history-item-user-info {
  display: flex;
}

.compliment-history-item-user-info-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: hidden;
}

.compliment-user-username {
  font-size: 14px;
  color: #021e30;
  font-weight: 700;
}

.compliment-age {
  font-size: 12px;
  font-weight: 500;
  color: #021e30;
}

.compliment-user-info-bottom {
  color: #b5b5b5;
  font-size: 12px;
}

.compliment-history-user-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 3px;
}

.compliment-history-item-diamond-count {
  display: flex;
  align-items: center;
}

.diamond-count-icon {
  height: 16px;
  margin-right: 6px;
}

.diamond-count-text {
  font-size: 18px;
  font-weight: 700;
  color: #021e30;
}

// ! Dropdown Styles

.wallet-dropdown-wrap {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.wallet-dropdown-wrap .dropdown .dropdown-toggle {
  width: 100%;
  text-align: left;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #f4f4f4 !important;
  color: #021e30 !important;
  font-size: 16px !important;
  display: flex;
  justify-content: space-between;
}

.wallet-dropdown-toggle-title {
  color: #021e30 !important;
  font-size: 16px !important;
}

.wallet-dropdown-menu {
  width: 100%;
}

.wallet-dropdown-menu .dropdown-item {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.inbox-dropdown-item-icon-wrap {
}

.inbox-dropdown-item-title {
}

.icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: cover;
}

.icon-message-sort-all {
  margin-bottom: -5px;
  margin-right: 15px;
  background-image: url(../../assets/Icons/message-square.svg);
}

.icon-message-sort-matches {
  margin-bottom: -5px;
  margin-right: 15px;
  background-image: url(../../assets/Icons/heart.svg);
}

.icon-message-sort-online {
  margin-bottom: -5px;
  margin-right: 15px;
  background-image: url(../../assets/Icons/wifi.svg);
}

.icon-message-sort-nearest {
  margin-bottom: -5px;
  margin-right: 15px;
  background-image: url(../../assets/Icons/star.svg);
}

.wallet-scroll-loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  color: #b5b5b5;
}

.wallet-max-reached-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  color: #b5b5b5;
  text-align: center;
  font-size: 14px;
}

.no-diamond-history-container {
  width: 100%;
  padding: 1rem;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-diamond-history-image {
  margin-bottom: 1rem;
}

.no-diamond-history-title {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #021e30;
}

.no-diamond-history-subtitle {
  text-align: center;
  font-size: 12px;
  color: #021e30;
  margin: 0;
  width: 80%;
}
