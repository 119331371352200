.edit-profile-v2-component {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
}
.edit-profile-v2-header {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 !important;
  height: 8% !important;
  position: relative;
  width: 100%;
}

.edit-profile-v2-header-section-left {
  position: absolute;
  left: 0.5rem;
}

.edit-profile-v2-header-section-middle {
}

.edit-profile-v2-header-section-right {
  position: absolute;
  right: 0.5rem;
}

.edit-profile-v2-header-return-button {
  background-color: transparent !important;
  border: 0;
  color: #000;
  font-size: 16px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  &:disabled {
    color: #000;
  }
}

.edit-profile-v2-header-title {
  margin: 0;
  text-align: center;
  font-size: 18px;
}

.edit-profile-v2-header-done-button {
  background-color: transparent !important;
  border: 0;
  text-decoration: underline;
  font-size: 16px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #c44b4b;
  &:disabled {
    color: #000;
  }
}

.edit-profile-v2-content {
  height: 92%;
  width: 100%;
  padding: 1rem;
  overflow-y: scroll;
}

.edit-profile-v2-photo-button {
  height: 48px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  background-color: #c44b4b !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.edit-profile-v2-basic {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-profile-v2-basic-content {
}

.edit-profile-v2-photo-view {
  padding-bottom: 1rem;
}

.no-bottom-border {
  border-bottom: 0 !important;
}

.edit-profile-v2-title {
  font-weight: 700;
  font-size: 18px;
  color: #021e30;
  margin: 0 !important;
}

.edit-profile-v2-text {
  font-size: 14px;
  color: #b5b5b5;
  margin: 0;
  margin-top: 8px;
  font-weight: 500;
}

.edit-profile-v2-text-bold {
  font-size: 14px;
  color: #021e30;
  margin: 0;
  margin-top: 8px;
  font-weight: 500;
}

.capitalize {
  text-transform: capitalize;
}

.medium {
  font-weight: 500;
  font-size: 16px;
}

.edit-profile-v2-row-action {
  color: #b5b5b5;
}

.edit-profile-v2-info {
  padding-top: 1rem;
}

.edit-profile-v2-benefits {
  margin-top: 34px;
}

.edit-profile-v2-benefits-header {
  position: relative;
  display: flex;
}

.edit-profile-v2-benefits-header-action {
  position: absolute;
  margin-top: -7px;
  right: 15px;
}
