.premium-success-card {
  border-radius: 15px !important;
  background-color: #090a20 !important;
}

.premium-success-card-body {
  width: 100%;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

.premium-success-title {
  text-align: center;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
}

.premium-success-subtitle {
  margin-top: 1rem;
  margin-bottom: 0;
  color: #dbdbdb;
  text-align: center;
}

.premium-gif {
  margin: auto;
}

.premium-success-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  width: 85%;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  margin-top: 1.5rem;
}

.purchase-success-icon-superlike {
  font-size: 5rem;
  color: #32b5ff;
  margin-top: 25px;
  margin-bottom: 15px;
}

.purchase-success-icon-boost {
  font-size: 5rem;
  color: #9a4ed9;
  margin-top: 25px;
  margin-bottom: 15px;
}

.purchase-success-icon-note {
  font-size: 5rem;
  color: #f75590;
  margin-top: 25px;
  margin-bottom: 15px;
}

.purchase-success-button-superlike {
  border-color: rgb(82, 159, 247) !important;
  background: rgb(82, 159, 247);
  background: linear-gradient(
    263deg,
    rgba(82, 159, 247, 1) 0%,
    rgba(76, 149, 242, 1) 12%,
    rgba(46, 92, 213, 1) 100%
  );
  width: 85%;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  margin-top: 1.5rem;
}

.purchase-success-button-boost {
  border-color: rgb(194, 155, 253) !important;
  background: rgb(194, 155, 253);
  background: linear-gradient(
    90deg,
    rgba(194, 155, 253, 1) 0%,
    rgba(146, 75, 251, 1) 12%,
    rgba(88, 4, 215, 1) 100%
  );
  width: 85%;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  margin-top: 1.5rem;
}

.purchase-success-button-note {
  background: rgb(251, 177, 204);
  background: linear-gradient(
    90deg,
    rgba(251, 177, 204, 1) 0%,
    rgba(248, 119, 166, 1) 12%,
    rgba(247, 85, 144, 1) 100%
  );
  border-color: rgb(251, 177, 204) !important;
  width: 85%;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  margin-top: 1.5rem;
}
