.desktop-matches-tab {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.desktop-matches-content-container {
  width: 100%;
  height: 100%;
}

.desktop-matches-card {
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  border-top: 1px solid #dbdbdb;
  position: relative;
  cursor: pointer;
}

.desktop-matches-card-left-side {
  display: flex;
}

.desktop-matches-card-image {
  height: 144px;
  width: 114px;
  object-fit: cover;
  border-radius: 5px;
}

.desktop-matches-card-image-placeholder {
  height: 144px;
  width: 114px;
  background-color: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.desktop-matches-card-placeholder-icon {
  color: rgb(153, 153, 153);
  font-size: 2.5rem;
}

.desktop-matches-tab-loading-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
}

// ! Desktop Matches Card User Info

.desktop-matches-card-user-info {
  padding: 1rem;
  padding-left: 2rem;
  display: flex;
  z-index: 2 !important;
}

.desktop-matches-card-user-info-left {
  width: 250px;
  z-index: 2 !important;
}

.desktop-matches-card-user-info-right {
  width: 350px;
  padding-top: 2rem;
}

.desktop-matches-card-username {
  color: rgb(51, 51, 51);
  margin-bottom: 0.65rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.2rem;
  font-weight: 400;
}

.desktop-match-card-headline {
  max-width: 15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1rem;
  margin: 0;
  color: #464646;
}

.desktop-match-card-age-location {
  color: rgb(126, 126, 126);
  max-width: 15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1rem;
  margin: 0;
}

.desktop-match-card-view-conversation-button {
  margin-top: 0.65rem;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  width: 200px !important;
  z-index: 2 !important;
}

.desktop-match-card-send-message-button {
  margin-top: 0.65rem;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  width: 200px !important;
  z-index: 2 !important;
}

.desktop-match-card-user-info-list {
  list-style: none;
}

.desktop-match-card-user-info-list-item {
}

.desktop-match-card-user-info-title {
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: 1rem;
  color: #464646;
}

.desktop-match-card-user-info-content {
  font-size: 0.875rem;
  font-weight: 400;
  color: #464646;
}

.desktop-match-card-timestamp-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}

.desktop-match-card-timestamp {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  color: #464646;
}

.match-card-send-message-icon {
  margin-right: 7px;
}

.desktop-match-card-link {
  text-decoration: none !important;
}
