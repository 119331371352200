.match-v2-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: grey;
  z-index: 4;
}

.match-v2-innerwrap {
  width: 100%;
  height: 100%;
  position: relative;
}

.match-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}

.match-v2-close-wrapper {
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  padding: 1rem;
}

.match-v2-close-button {
  background-color: transparent !important;
  border: 0 !important;
  color: #fff;
  font-size: 2rem !important;
}

.match-v2-close-icon {
  color: #fff;
  font-size: 2rem !important;
}

.match-v2-image-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(2, 30, 48, 0.6);
  z-index: 5;
}

.match-v2-content-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 6;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
}

.match-v2-content-section {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.match-v2-content-innerwrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

// ! Images Section

.match-v2-match-images-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  width: 100%;
  padding-bottom: 1rem;
  margin-top: 10rem;
}

.match-v2-image-card-wrapper-left {
  border-radius: 5px;
  width: 35%;
  position: absolute;
  left: 20%;
  bottom: 175%;
  z-index: 3;
  transform: rotate(-25deg);
}

.match-v2-image-card-wrapper-right {
  border-radius: 5px;
  width: 35%;
  position: absolute;
  right: 20%;
  bottom: 175%;
  transform: rotate(25deg);
}

.match-v2-image-card {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.match-v2-logo-wrapper {
  height: 60px;
  width: 60px;
  background-color: #c44b4b;
  border-radius: 5px;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 4;
}

.match-v2-logo {
  width: 75%;

  object-fit: cover;
}

.match-v2-title-wrapper {
}

.match-v2-title {
  text-align: center;
  font-size: 3.5rem;
  font-weight: 800;
  color: #fff;
  margin-bottom: 1rem;
}

.match-v2-subtitle {
  font-size: 1.25rem;
  text-align: center;
  color: #fff;
  font-weight: 400;
}

.match-v2-content-footer {
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match-v2-send-message-button {
  width: 100%;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.match-v2-other-tips-button {
  background-color: transparent !important;
  border: 0 !important;
  text-decoration: underline;
  font-size: 1.25rem;
}
