.premium-card-body-monthly {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
}

.premium-plan-select-wrapper-monthly {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 1.5rem;
}

.premium-plan-card-monthly {
  width: 85%;
  border-color: #021e30 !important;
}

.premium-plan-card-body-monthly {
  padding: 1rem !important;
  padding-top: 1.5rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdf7f8 !important;
}

.premium-plan-card-monthly-price-monthly {
  color: #8e8b8c;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0;
}

.premium-plan-card-months-monthly {
  font-size: 2.3rem;
  color: #000;
  font-weight: 900;
  margin: 0;
}

.premium-plan-card-subtitle-monthly {
  color: #444444;
  font-weight: 500;
  margin-bottom: 0;
  font-style: italic;
}
