.loading-submit-wrapper {
  height: 250px;
  width: 250px;
  border-radius: 1rem;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-submit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-submit-textwrap {
  width: 90%;
  color: #fff;
  text-align: center;
  padding-top: 1rem;
}

.loading-submit-text {
  font-size: 1.2rem;
}

.loading-submit-innerwrap {
  width: 100%;
  height: 100%;
  position: relative;
}

.loading-submit-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 50;
}

.submit-blockreason-btn {
  background-color: #da0e2b;
  width: 100%;
  color: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.8rem;
}

.submit-blockreason-btn-disabled {
  cursor: not-allowed;
  background-color: #dbdbdb;
  width: 100%;
  color: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.8rem;
}
