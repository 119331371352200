.user-profile-v2-component {
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-profile-v2-component-boost {
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c44b4b;
}

.profile-component-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.verification-progress-icon {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.verification-progress-icon-verified {
  text-align: center;
  font-size: 22px;
  margin-bottom: 0.25rem;
  color: #007bff;
}

.user-profile-v2-icon-wrapper {
  height: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-profile-diamond-icon {
  height: 25px;
}

.user-profile-boost-icon {
  text-align: center;
  font-size: 22px;
  margin-bottom: 0.25rem;
  color: #fff;
}

.verification-progress-title {
  font-size: 12px;
  text-align: center;
  margin-bottom: 1rem;
  color: #828282;
  margin-top: 5px;
}

.verification-progress-title-boost {
  font-size: 12px;
  text-align: center;
  margin-bottom: 1rem;
  color: #fff;
  margin-top: 5px;
}

.verifications-progress-number {
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  margin: 0;
}

.verifications-progress-number-boost {
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  margin: 0;
  color: #fff !important;
}

.profile-component-action-btn {
  background-color: #021e30 !important;
  color: #fff !important;
  width: 100%;
  border: 1px solid #021e30 !important;
  font-size: 0.8rem;
}

.profile-component-action-btn-completed {
  background-color: transparent !important;
  border: 0 !important;
  text-decoration: underline !important;
  color: #021e30 !important;
  width: 100%;
  font-size: 0.8rem;
}

.profile-component-star-icon {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

// ! Inventory Counter Styles

.inventory-counter-title {
  text-align: center;
  font-size: 0.7rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.inventory-counter-number {
  text-align: center;
  font-size: 1rem;
  margin: 0;
}

.profile-component-section-inventory {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// ! Premium Status Styles

.user-profile-v2-premium-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user-profile-component-logo {
  width: 25%;
  margin: auto;
}
