.support-form {
  border: 1px solid #dbdbdb;
  padding: 1rem;
  overflow: hidden;
  position: relative;
}

.support-form-uploading-overlay {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.support-form-group {
}

.support-form-label {
  overflow: hidden;
}

.support-form-input {
  overflow: hidden;
}

.support-file-input-label {
  overflow: hidden;
}

.support-form-submit-button {
  width: 100%;
  background-color: #090a20 !important;
  border-color: #090a20 !important;
}

.support-form-success-wrapper {
}

.support-form-success-title {
  text-align: center;
}

.support-form-success-subtitle {
  text-align: center;
}

.support-form-success-icon-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.support-form-success-icon {
  color: #4bb543;
  font-size: 2rem;
}
