.desktop-v2-profile-user-header {
  width: 100%;
  padding: 1rem;
  background-color: #021e30;
  display: flex;
  justify-content: space-between;
}

.desktop-v2-profile-user-header-section {
  display: flex;
}

.desktop-v2-profile-user-image-wrapper {
  height: 127px;
  width: 100px;
  border-radius: 5px;
}

.desktop-v2-profile-user-image {
  height: 127px;
  width: 100px;
  border-radius: 5px;
  object-fit: cover;
}

.desktop-v2-profile-header-user-info {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.desktop-v2-profile-header-username {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  margin-right: 5px;
}

.desktop-v2-profile-header-age {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin: 0;
}

.desktop-v2-profile-user-info-username-age-wrap {
  display: flex;
  align-items: center;
}

.desktop-v2-profile-header-verified-icon {
  height: 16px;
  width: 16px;
  margin-left: 5px;
}

.desktop-v2-profile-user-address {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-top: 5px;
}

.desktop-v2-profile-user-header-button {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  margin-right: 1rem;
}

.desktop-v2-profile-user-header-compliment-button {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #c44b4b;
  cursor: pointer;
}

.desktop-v2-profile-user-header-superlike-button {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  color: #e08b27;
}

.desktop-v2-profile-user-header-superlike-button-active {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #e08b27;
  cursor: pointer;
  color: #fff;
}

.desktop-v2-profile-user-header-button-icon {
  height: 22px;
  width: 22px;
}

.desktop-v2-profile-header-actions-wrapper {
  display: flex;
}

.desktop-v2-profile-user-header-right-side {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.desktop-v2-profile-user-header-menu-button {
  color: #fff;
  font-size: 18px;
  padding-left: 0.35rem;
  cursor: pointer;
}

.desktop-v2-profile-user-header-message-button {
  height: 48px;
  width: 158px;
  border-radius: 5px;
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.desktop-v2-profile-send-message-text {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.desktop-v2-profile-send-message-icon {
  color: #c44b4b;
  height: 22px;
  width: 22px;
  margin-right: 8px;
  margin-top: 3px;
}

.desktop-v2-profile-user-content-container {
  flex: 1;
  overflow-y: scroll;
  height: 0;
}

.desktop-v2-profile-user-content-section {
  width: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.desktop-v2-profile-user-content-section-title {
  font-size: 18px;
  color: #021e30;
  font-weight: 700;
}

.desktop-v2-profile-user-location-map-wrapper {
  border-radius: 5px;
  width: 100%;
  height: 200px;
}

.desktop-v2-profile-user-location-map-placeholder {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  background-color: #f4f4f4;
}

.desktop-v2-profile-right-content {
  margin-top: 5rem;
  padding-left: 1rem;
}

.desktop-v2-profile-right-side-section {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.desktop-v2-profile-right-title {
  font-size: 16px;
  font-weight: 700;
  color: #021e30;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.desktop-v2-verification-item {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #eeeeee;
  font-weight: 400;
  color: #021e30;
  border-radius: 5px;
  max-width: 165px;
  font-size: 14px;
}

.desktop-v2-verification-item-active {
  width: 100%;
  max-width: 165px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #021e30;
  font-weight: 400;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
}

.desktop-v2-verification-icon {
  position: absolute;
  top: 7px;
  right: 7px;
  height: 16px;
  width: 16px;
}

.desktop-v2-profile-verifications-social-icon {
  width: 18px;
  height: 18px;
}

.desktop-v2-profile-social-item {
  width: 100%;
  height: 50px;
  max-width: 165px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: #eeeeee;
  font-weight: 400;
  color: #021e30;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
}

.desktop-v2-profile-social-item-active {
  width: 100%;
  max-width: 165px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: #eeeeee;
  font-weight: 400;
  color: #021e30;
  border-radius: 5px;
  padding-left: 1.5rem;
  font-size: 14px;
  margin-bottom: 0.75rem;
}

.desktop-v2-profile-verifications-social-icon-wrapper {
  width: 25px;
  padding-right: 2rem;
  margin-bottom: 3px;
}
