.desktop-spotlight-component {
  padding-right: 1rem;
}

.desktop-spotlight-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.desktop-spotlight-cta-image {
  width: 24%;
  height: 84px;
  flex-basis: 24%;
  position: relative;
  object-fit: cover;
  margin-bottom: 5px;
  cursor: pointer;
}

.spotlight-cta-user-image-desktop {
  width: 100%;
  height: 84px;
  object-fit: cover;
  margin-bottom: 5px;
}

.desktop-spotlight-cta-overlay-boosted {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
}

.desktop-spotlight-placeholder {
  width: 24%;
  height: 84px;
  border-radius: 5px;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  margin-bottom: 5px;
}
