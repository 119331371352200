.tab-indicator-card {
  border: 0 !important;
}

.tab-indicator-card-body {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.tab-indicator-icon {
  color: #f1f2f5;
  font-size: 4rem;
}

.tab-indicator-text {
  color: #5b5b5b;
  text-align: center;
  margin-top: 0.5rem;
  width: 70%;
}

.no-matches-indicator-rocket-gif {
  width: 35%;
}

.no-matches-title {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: #000;
}

.no-matches-boost-button {
  background-color: #2a2d43 !important;
  width: 95% !important;
  border-radius: 15px !important;
  text-transform: uppercase !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  border: 3px solid #9a4ed9 !important;
}

.no-matches-bolt-icon {
  margin-right: 0.5rem;
}
