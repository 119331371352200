.compliment-modal-body {
  padding-top: 1.75rem;
}

.compliment-modal-footer {
  border: 0;
}

.compliment-modal-body-title {
  font-size: 20px;
  text-align: left;
  color: #000;
  margin: 0;
  font-weight: 700;
}

.compliment-modal-send-button {
  width: 100%;
  background-color: #c44b4b;
  border-color: #c44b4b;
  font-size: 16px;
  font-weight: 700;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.75rem;
  border-radius: 5px;
}

.compliment-modal-send-button:disabled {
  width: 100%;
  background-color: #c44b4b;
  border-color: #c44b4b;
  font-size: 16px;
  font-weight: 700;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.75rem;
  border-radius: 5px;
}

.compliment-modal-text-area {
  resize: none;
  font-size: 16px;
  color: #b5b5b5;
  border: 1px solid #eeeeee;
  background-color: #fcfcfc;
}

.compliment-modal-form {
  padding-top: 1.75rem;
}

.compliment-modal-item-form-group {
  padding-bottom: 1rem;
}

.compliment-modal-item-form-group-innerwrap {
  width: 100%;
  display: flex;
  overflow-x: scroll;
}

.compliment-modal-item-wrapper {
  min-width: 50%;
  width: 50%;
  max-width: 50%;
  margin-right: 1rem;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  cursor: pointer;
}

.compliment-modal-item-wrapper-active {
  min-width: 50%;
  width: 50%;
  max-width: 50%;
  margin-right: 1rem;
  background-color: #ffffff;
  border: 1px solid #c44b4b;
  border-radius: 3px;
  cursor: pointer;
}

.compliment-modal-item-content {
  height: 165px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compliment-modal-item-footer {
  border-top: 1px solid #f4f4f4;
  width: 100%;
  padding: 1rem;
  position: relative;
}

.compliment-modal-item-footer-active {
  border-top: 1px solid #c44b4b;
  background-color: #c44b4b;
  color: #fff;
  position: relative;
  width: 100%;
  padding: 1rem;
}

.compliment-modal-checkbox-wrapper {
  position: absolute;
  left: 10%;
}

.compliment-modal-item-footer-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

.compliment-modal-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: blur(2px);
  position: absolute;
}

.compliment-modal-icon {
  width: 45%;
}

.compliment-diamond-icon {
  width: 18px;
  height: 16px;
  margin-right: 3px;
}

.compliment-refill-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.compliment-refill-title {
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.compliment-refill-subtitle {
  text-align: center;
  font-size: 14px;
  color: #021e30;
}

.compliment-modal-success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.compliment-modal-gift-icon {
  color: #c44b4b;
  font-size: 30px;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.compliment-modal-success-title {
  color: #000;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  margin-bottom: 1rem;
}

.compliment-modal-success-subtitle {
  font-size: 14px;
  color: #021e30;
  font-weight: 500;
  text-align: center;
}
