.home-page-v2-container {
  height: 100%;
  width: 100%;
  background-color: #021e30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-page-v2-logo {
  height: 135px;
  width: 236px;
}

.home-page-v2-logo-subtitle {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  color: #fff;
  margin-bottom: 2.5rem;
}

.home-page-v2-continue-button-white {
  width: 100%;
  max-width: 335px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #021e30;
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.home-page-v2-continue-button-red {
  width: 100%;
  max-width: 335px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  background-color: #c44b4b;
  border-radius: 5px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.home-page-v2-continue-with-secondary-container {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
}

.home-page-v2-continue-secondary-button {
  width: 76px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c3445;
  border-radius: 5px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.home-page-v2-social-icon {
  height: 18px;
  width: 18px;
}

.home-page-v2-continue-with-secondary-text {
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin: 0;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.home-page-v2-continue-with-secondary-subtitle-text {
  font-size: 12px;
  color: #b5b5b5;
  margin: 0;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.home-page-v2-disclaimer-text {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  width: 266px;
  margin-top: 1rem;
}

.home-page-v2-disclaimer-text-red {
  font-size: 12px;
  color: #c44b4b;
  font-weight: 500;
  text-decoration: underline;
  margin: 0;
}
