.notifications-v2-component {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.notifications-v2-header {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 !important;
  height: 8% !important;
  position: relative;
  width: 100%;
}

.notifications-v2-header-section-left {
  position: absolute;
  left: 0.5rem;
}
