.message-feed-component {
  max-width: 1140px !important;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  flex-grow: 1;
}

.no-messages-match-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fetching-spinner {
  text-align: center;
}
