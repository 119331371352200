.desktop-chat-card-component {
  padding-top: 1.2rem;
  width: 100%;
  display: flex;
}

.desktop-chat-card-image {
  height: 63px;
  width: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.desktop-chat-card-image-placeholder {
  height: 63px;
  width: 50px;
  border-radius: 5px;
  background-color: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.desktop-chat-card-placeholder-icon {
  color: rgb(153, 153, 153);
  font-size: 2.5rem;
}

.desktop-chat-card-content-wrap {
  padding-left: 1rem;
  width: 100%;
}

.desktop-chat-card-username-wrap {
  display: flex;
  align-items: flex-end;
}

.desktop-chat-card-username {
  margin: 0;
  font-size: 1rem;
  color: #000;
  font-weight: 700;
  line-height: 28px;
}

.desktop-chat-card-timestamp {
  margin: 0;
  margin-left: 10px;
  font-size: 1rem;
  font-weight: 400;
  color: #999;
  font-size: 0.8rem;
  line-height: 24px;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.desktop-chat-message-content-wrap {
  width: 100%;
}

.desktop-chat-message-content {
  margin: 0;
  color: #464646;
  width: 80%;
}
