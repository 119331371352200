.search-grid-component {
  padding: 0rem 0.5rem 0rem 0.5rem;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: scroll;
}

.search-grid-container-main {
  height: 80%;
}

.loading-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-scroll-loader-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}
