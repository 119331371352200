.likes-image-component-wrapper {
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.likes-image-innerwrap {
  position: relative;
}

.likes-image {
  object-fit: cover;
}

.likes-image-blur {
  object-fit: cover;
  filter: blur(2px) !important;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
}

.likes-image-placeholder {
  object-fit: cover;
  background-color: #dbdbdb;
  border-radius: 50%;
}

.likes-image-wrap {
  border: 5px solid #e9aa43;
  border-radius: 50%;
  padding: 3px;
  overflow: hidden;
}

.likes-image-icon-wrapper {
  position: absolute;
}

.match-queue-likes-icon {
  color: #e9aa43;
  font-size: 2rem;
  z-index: 2;
  text-align: center;
  --fa-secondary-color: white;
  --fa-secondary-opacity: 1;
}

.match-queue-likes-image {
  width: 25px;
}

.likes-image-subtitle {
  margin: 0;
  margin-top: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
