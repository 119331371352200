.tabs-component {
  height: 90%;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

.matches-tab-innerwrap {
  display: flex;
  flex-wrap: wrap;
}

.likes-component {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 90%;
  overflow-y: scroll;
}

.likes-component-innerwrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.no-likes-wrapper {
  width: 100%;
  height: 100%;
}

.see-likes-btn-wrap {
  position: fixed;
  bottom: 35px;
  left: 0;
  z-index: 5 !important;
  width: 100%;
  display: flex;
  justify-content: center;
}

.see-likes-cta-btn {
  width: 90% !important;
  border-radius: 15px !important;
  text-transform: uppercase !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  background-color: #2a2d43 !important;
  border: 3px solid #ffad08 !important;
  z-index: 3 !important;
}

.see-likes-btn-icon {
  margin-right: 0.5rem;
}

.end-indicator {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: auto;
  width: 60%;
  padding: 10px;
}

@media (min-width: 992px) {
  .matches-tab-innerwrap {
    max-width: 1300px;
  }

  .likes-component-innerwrap {
    max-width: 1300px;
    margin: auto;
  }
}
