.login-section-container {
  background-color: #fff;
  padding-bottom: 2rem;
}

.lp-title {
  text-align: center;
  font-weight: 700 !important;
  color: #090a20;
  font-size: 3rem !important;
  padding-top: 1.5rem;
}

.lg-login-link {
  border-bottom: 4px solid #c44b4b;
  padding-bottom: 0.5rem;
}

.login-reactivate-title {
  font-size: 30px;
  font-weight: 600;
}

.login-form {
  padding: 1rem;
  width: 100%;
  max-width: 400px;
}

.login-reactivate-body {
  text-align: center;
  border: 1px solid;
  width: 85%;
  padding: 15px 50px 15px 50px;
}

.login-reactivate-success-alert {
  width: 85%;
}

.lp-error-alert {
  font-size: 0.9rem;
}

.lp-forgot-password-link {
  text-decoration: underline;
  color: #721c24;
}

.login-form-btn {
  width: 100% !important;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  margin-top: 1rem;
}

.lp-reactivate-cancel {
  margin-top: 10px;
  background: white;
  color: #343a40;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.login-reactivate-description {
  font-size: 20px;
}

.login-form-label {
  font-weight: 400;
  color: #090a20;
}

.lp-forgot-password-wrapper {
  display: flex;
  justify-content: flex-end;
}

.lp-forgot-password-link {
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  color: #090a20 !important;
  padding: 0 !important;
}

.login-below-form-container {
  padding-top: 3rem;
  text-align: center;
}

.lp-signup-text {
  color: #090a20;
}

.lp-signup-link {
  color: #090a20;
  font-weight: 700;
  text-decoration: underline;
}

.login-form-input {
  border-color: #090a20 !important;
  color: #090a20 !important;
}

.login-form-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.login-form-card {
  max-width: 600px !important;
  padding-bottom: 2rem;
}

.login-form-container {
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.lp-disclaimer-text {
  font-size: 13px;
  max-width: 400px;
  margin-top: 2rem;
}

.divider {
  width: 100%;
  height: 35px;
  border-bottom: 1px solid black;
  text-align: center;
  padding: 15px;
}

.divider-title {
  font-size: 20px;
  font-weight: 500;
  background-color: white;
  padding: 0 15px;
}

.login-form-top-img {
  display: none;
}

@media (min-width: 767px) {
  .login-form-top-img {
    display: block;
  }
  .login-section-container {
    background-color: #021e30;
    padding-top: 2rem;
  }
}
