.desktop-search-results-component {
  width: 100%;
  background-color: #fff;
  margin-top: 1.5rem;
  border-radius: 5px;
}

.desktop-search-card {
  width: 100%;
  padding: 1.5rem;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
}

.desktop-search-card-image-wrap {
  width: 15%;
  position: relative;
}

.desktop-search-card-image {
  object-fit: cover;
  width: 100%;
  height: 180px;
  border-radius: 5px;
}

.desktop-search-card-image-placeholder {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbdbdb;
}

.desktop-search-card-placeholder-icon {
  color: rgb(153, 153, 153);
  font-size: 2.5rem;
}

.desktop-search-card-link {
  text-decoration: none !important;
}

// ! Desktop Search Card User Info Wrap

.desktop-search-card-user-info-wrap {
  width: 70%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.desktop-search-card-header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop-search-card-username-wrap {
  display: flex;
  align-items: center;
}

.desktop-search-card-username {
  color: #464646;
  font-size: 1.4rem;
  margin-bottom: 0.25rem;
}

.desktop-search-card-verified-icon {
  color: #007bff;
  font-size: 1rem;
  --fa-primary-color: #fff;
  --fa-secondary-color: #007bff;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  margin-left: 5px;
}

.desktop-search-card-online-indicator {
  display: flex;
  align-items: center;
}

.desktop-search-card-online-icon {
  font-size: 0.5rem;
  color: #20c997;
  margin-right: 5px;
  margin-top: 3px;
}

.desktop-search-card-online-text {
  color: #999;
  font-size: 0.875rem;
  font-weight: 500;
}

.desktop-search-card-headline-age-address-wrap {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.desktop-search-card-headline {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #464646;
  font-weight: 400;
  font-size: 0.875rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.desktop-search-card-age-location {
  color: #7e7e7e;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 0.875rem;
  margin: 0;
}

.desktop-search-card-user-details-wrap {
  padding-top: 1.25rem;
}

.desktop-search-card-user-detail-row {
  display: flex;
  width: 75%;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.desktop-search-card-user-detail-section {
  display: flex;
  align-items: flex-end;
}

.desktop-search-card-user-detail-title {
  margin: 0;
  color: #464646;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2;
}

.desktop-search-card-user-detail-text {
  margin: 0;
  color: #464646;
  font-weight: 400;
  font-size: 0.875rem;
  margin-left: 0.875rem;
  line-height: 1.2;
}

// ! Desktop Image Card Count

.desktop-search-card-image-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 20%,
    rgba(0, 0, 0, 0.3) 66%,
    rgba(255, 255, 255, 0) 100%
  );
  padding-right: 0.4rem;
  padding-bottom: 0.25rem;
}

.desktop-search-card-image-footer-count-indicator {
}

.desktop-search-user-card-camera-icon {
  color: #dbdbdb;
  font-size: 0.8rem;
  margin-right: 5px;
}

.desktop-search-user-card-image-count {
  color: #dbdbdb;
  font-size: 0.8rem;
}

// ! Desktop Scroll Loading

.desktop-search-results-indicator-container {
  padding: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

// ! Desktop Search Card Actions Wrap

.desktop-search-card-actions-wrap {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
}

.desktop-search-card-action-button {
  background-color: transparent !important;
  height: 60px !important;
  width: 60px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border: 1px solid #999 !important;
  z-index: 2;
}

.desktop-search-card-like-icon {
  color: #464646;
  font-size: 1.7rem;
  margin-top: 3px;
}

.desktop-search-card-like-icon-checked {
  color: #55eac4;
  font-size: 1.7rem;
  margin-top: 3px;
}

.desktop-search-card-superlike-icon {
  color: #464646;
  font-size: 1.7rem;
  margin-top: 3px;
}

.desktop-search-card-superlike-icon-checked {
  color: #3578f6;
  font-size: 1.7rem;
  margin-top: 3px;
}
