.desktop-nav-component {
  width: 100%;
  background-color: #021e30;
  padding: 1rem;
}

.desktop-nav-component-premium {
  width: 100%;
  background-color: #021e30;
  padding: 1rem;
  border: 5px solid #e9aa43;
}

.desktop-nav-innerwrap {
  max-width: 1300px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop-nav-logo-wrapper {
}

.desktop-nav-logo {
  width: 170px;
}

.desktop-nav-navigation-wrapper {
  display: flex;
  align-items: center;
}

.desktop-nav-button-wrap {
}

.desktop-nav-button {
  background-color: transparent !important;
  border: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 1.3rem !important;
}

.desktop-nav-icon {
  font-size: 1.7rem;
  position: relative;
  color: #dbdbdb;
  margin-right: 15px;
}
