.swipe-pop-modal-body-superlike {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(118, 225, 255, 1) 85%,
    rgba(50, 181, 255, 1) 100%
  );
}

.swipe-pop-modal-body-verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(197, 231, 252, 1) 85%,
    rgba(29, 161, 242, 1) 100%
  );
}

.swipe-pop-modal-body-likes {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(130, 253, 237, 1) 85%,
    rgba(85, 234, 196, 1) 100%
  );
}

.swipe-pop-modal-body-boost {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(215, 163, 240, 1) 85%,
    rgba(154, 78, 217, 1) 100%
  );
}

.swipe-pop-modal-body-undo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 212, 59, 1) 85%,
    rgba(255, 173, 8, 1) 100%
  );
}

.swipe-pop-modal-body-viewlikes {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(130, 132, 144, 1) 85%,
    rgba(9, 10, 32, 1) 100%
  );
}

.swipe-pop-modal-body-note {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(253, 216, 230, 1) 85%,
    rgba(247, 85, 144, 1) 100%
  );
}

.swipe-pop-modal-body-readreceipt {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(200, 225, 251, 1) 85%,
    rgba(53, 120, 246, 1) 100%
  );
}

.swipe-pop-modal-body-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(121, 35, 251, 1) 85%,
    rgba(88, 4, 215, 1) 100%
  );
}

.swipe-pop-body-title {
  color: #444444;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  margin: 0;
}

.swipe-pop-body-premium-title-wrap {
  margin-bottom: 1.5rem;
}

.swipe-pop-body-premium-title {
  color: #fefefe;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  margin: 0;
}

.swipe-pop-premium-icon {
  color: #fefefe;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  margin-right: 0.5rem;
}

.swipe-pop-modal-body-image {
  height: 80px;
  width: 80px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.swipe-pop-icon {
  height: 80px;
  width: 80px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  -webkit-box-shadow: 2px 2px 15px 2px #444444;
  box-shadow: 2px 2px 15px 2px #444444;
}

.swipe-pop-main-icon {
  font-size: 2.5rem;
  color: #ffad08;
}

.swipe-pop-main-icon-bolt {
  font-size: 2.5rem;
  color: #9a4ed9;
}
.swipe-pop-main-icon-eye {
  font-size: 2.5rem;
  color: #090a20;
}

.swipe-pop-main-icon-message {
  font-size: 2.5rem;
  color: #5804d7;
}

.swipe-pop-main-icon-verify {
  font-size: 2.5rem;
  color: #1da1f2;
}

.swipe-pop-main-icon-filters {
  font-size: 2.5rem;
  color: #ffad08;
}

.swipe-pop-main-icon-read {
  font-size: 2.5rem;
  color: #3578f6;
}

.swipe-pop-body-countdown-wrapper {
  color: #444444 !important;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 0.2rem;
}

.swipe-pop-body-subtitle {
  color: #444444;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 500;
}

.swipe-pop-features-card {
  width: 100%;
  border: 0 !important;
}

.swipe-pop-features-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  width: 100% !important;
  border: 0 !important;
}

.swipe-pop-feature-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
}

.swipe-pop-feature-text {
  text-align: center;
  margin-bottom: 0;
  margin-top: 5px;
  color: #444444;
  font-weight: 600;
  font-size: 0.8rem;
}

.swipe-pop-feature-icon-bolt {
  font-size: 2rem;
  color: #9a4ed9;
}

.swipe-pop-feature-icon-comment {
  font-size: 2rem;
  color: #5804d7;
}

.swipe-pop-feature-icon-message {
  font-size: 2rem;
  color: #5804d7;
}

.swipe-pop-feature-icon-eye {
  font-size: 2rem;
  color: #ffad08;
}

.swipe-pop-feature-icon-stars {
  font-size: 2rem;
  color: #32b5ff;
}

.swipe-pop-feature-icon-heart {
  font-size: 2rem;
  color: #55eac4;
}

.swipe-pop-feature-icon-note {
  font-size: 2rem;
  color: #f75590;
}

.swipe-pop-feature-icon-read {
  font-size: 2rem;
  color: #3578f6;
}

.swipe-pop-footer {
  display: flex;
  flex-direction: column;
  border: 0 !important;
}

.swipe-pop-premium-btn {
  width: 100%;
  background-color: #da0e2b !important;
  border-color: #da0e2b !important;
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-premium-btn-read-receipt {
  width: 100%;
  border-color: #3578f6 !important;
  background: rgb(53, 120, 246);
  background: linear-gradient(
    90deg,
    rgba(53, 120, 246, 1) 0%,
    rgba(70, 138, 237, 1) 62%,
    rgba(82, 159, 247, 1) 100%
  );
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-premium-btn-message {
  width: 100%;
  border-color: #e9aa43 !important;
  background: rgb(158, 95, 252);
  background: linear-gradient(
    90deg,
    rgba(158, 95, 252, 1) 0%,
    rgba(121, 35, 251, 1) 62%,
    rgba(88, 4, 215, 1) 100%
  );
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-verify-btn {
  width: 100%;
  background-color: #1da1f2 !important;
  border-color: #1da1f2 !important;
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-note-btn {
  width: 100%;
  background: rgb(251, 177, 204);
  background: linear-gradient(
    90deg,
    rgba(251, 177, 204, 1) 0%,
    rgba(248, 119, 166, 1) 12%,
    rgba(247, 85, 144, 1) 100%
  );
  border-color: rgb(251, 177, 204) !important;
  border-radius: 15px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.swipe-pop-close-btn {
  color: #c2c5cb !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.like-message-pop-form {
  width: 100%;
}

.like-message-pop-input {
  resize: none;
  color: #555555 !important;
  font-size: 1rem;
  border: 1px solid #555555 !important;
}

.like-message-form-group {
  padding: 0 !important;
  margin: 0 !important;
  padding: 1rem !important;
  border: 1px solid #c2c5cb;
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.like-message-like-type {
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
}

.like-type-wrapper-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border-left: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.like-type-wrapper-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border-right: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.like-type-text {
  margin: 0;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #55eac4;
  text-align: center;
}

.superlike-type-text {
  margin: 0;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #32b5ff;
  text-align: center;
}

.like-type-superlike-icon {
  font-size: 1.3rem;
  color: #32b5ff;
  margin-bottom: 0.5rem;
}
.like-type-like-icon {
  font-size: 1.3rem;
  color: #55eac4;
  margin-bottom: 0.5rem;
}

.like-message-form-label {
  color: #555555;
  font-weight: 600 !important;
}

.like-type-check-input {
  margin: 0 !important;
  padding: 0 !important;
}

.like-type-check-input input {
  margin: 0 !important;
  padding: 0 !important;
}

.verification-modal-form {
  width: 100%;
}

.verification-modal-button-wrap {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.verification-modal-form .react-tel-input input {
  width: 100% !important;
}

.swipe-pop-modal-archive-body-note {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #DA0E2B;
  border-radius: 5px 5px 0px 0px;
}

.archive-modal-body {
  padding: 0rem !important;
}

.swipe-archive-body-title {
  color: white;
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-top: 20px;
}

.swipe-archive-body-description {
  color: white;
  text-align: center;
  padding: 0px 10px;
  font-size: 20px;
}

.swipe-archive-note {
  color: white;
  font-size: 18px;
}

.swipe-pop-features-card-body-archive {
  justify-content: space-between;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  width: 100% !important;
  border: 0 !important;
}

.archive-select-option {
  display: flex;
  position: relative;
  height: 50px;
  border-bottom: 1px solid #f0f1f1;
  padding-left: 25px;
  color: #DA0E2B;
}

.archive-option-title {
  font-size: 18px;
  font-weight: 500;
  padding-top: 15px;
  color: #343a40;
}

.archive-select-last-option {
  position: relative;
  height: 50px;
  border-bottom: 1px solid #f0f1f1;
  text-align: center;
}

.archive-option-icon {
  padding-top: 21px;
  margin-right: 10px;
}

.fa-plus18 {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center;
  font-size: 80%;
  font-weight: bold;
}

.fa-plus18:before {
  content: "18+";
}

.archive-under-age {
  height: 18px;
  background: #DA0E2B;
  width: 18px;
  border-radius: 10px;
  margin-top: 18px;
  margin-right: 10px;
}

.archive-age {
  color: white;
  font-size: 8px;
  padding-top: 3px;
  padding-left: 1px;
}

.unmatch-success {
  text-align: center;
  font-size: 18px;
}

.spam-pop-modal-body-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 60%,
    rgb(247, 61, 61) 85%,
    rgb(223, 17, 2) 100%
  );
}