.counter-icon {
  color: #c44b4b;
  font-size: 17px;
}

.counter-component {
  position: absolute;
  bottom: 7px;
  left: 18px;
}
