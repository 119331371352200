.graph-container {
  padding: 1rem;
}

.graph-header {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  color: #fff;
}

.graph-title {
  font-size: 16px;
}

.graph-content-container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 2rem;
}

.graph-content-title {
  text-align: center;
  margin-bottom: 1rem;
  color: #fff;
  font-size: 14px;
}
