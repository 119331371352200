.desktop-nav-v2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop-nav-logo-v2 {
  height: 36px;
  object-fit: contain;
}

.desktop-nav-action-container-v2 {
  display: flex;
  align-items: center;
  height: 50px;
}

.desktop-small-nav {
  position: absolute;
  top: 0;
  right: 0;
}

.desktop-nav-button-v2 {
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.desktop-nav-button-v2-active {
  position: relative;
  width: 273px;
  height: 100%;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.65rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}

.desktop-nav-user-left-wrapper {
  display: flex;
  align-items: center;
  margin-right: 1.3rem;
  padding-left: 0.5rem;
}

.desktop-nav-user-image-wrapper-v2 {
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 5px;
}

.desktop-nav-user-image-v2 {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.desktop-notification-icon-v2 {
  font-size: 20px;
  color: #021e30;
}

.desktop-nav-v2-username {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin: 0;
  margin-left: 1rem;
  margin-right: 2rem;
}

.nav-v2-dropdown-menu {
  position: absolute;
  left: 0;
  bottom: -285px;
  width: 100%;
  background-color: #fff;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.1);
  z-index: 3;
  border: 1px solid #f9f9f9;
  border-radius: 5px;
}

.nav-v2-dropdown-item {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}

.nav-dropdown-noborder {
  border-bottom: 0 !important;
}

.nav-v2-dropdown-icon {
  color: #021e30;
  font-size: 16px;
}

.nav-v2-dropdown-icon-logout {
  color: #c44b4b;
  font-size: 16px;
}

.nav-v2-dropdown-icon-wrap {
  width: 30px;
}

.nav-v2-dropdown-item-text {
  color: #021e30;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.desktop-nav-v2-icon {
  height: 24px;
  width: 24px;
}

.desktop-nav-v2-title {
  font-size: 16px;
  font-weight: 500;
  color: #021e30;
}

.desktop-nav-v2-title {
  margin-left: 0.75rem;
}
