.desktop-message-card-component {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 0.25rem;
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  cursor: pointer;
  position: relative;
}

.desktop-message-card-innerwrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop-message-card-leftside {
  display: flex;
  height: 100%;
}

.desktop-message-card-rightside {
  z-index: 2;
  position: absolute;
  right: 20px;
  top: 40%;
}

.desktop-message-card-archive-button {
  z-index: 2 !important;
}

.archive-icon {
  font-size: 1.1rem;
  color: #909090;
}

.desktop-message-card-indicator-wrapper {
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.desktop-message-unread-indicator-icon {
  font-size: 1rem;
  color: #c44b4b;
}

.desktop-message-read-indicator-icon {
  font-size: 1rem;
  color: #909090;
}

.desktop-message-sent-indicator-icon {
  font-size: 1rem;
  color: #909090;
}

.desktop-message-card-image-wrapper {
  padding-right: 1rem;
  z-index: 2;
}

.desktop-message-card-image {
  height: 108px;
  width: 86px;
  object-fit: cover;
  border-radius: 5px;
}

.desktop-message-card-image-placeholder {
  height: 108px;
  width: 86px;
  background-color: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.desktop-message-card-placeholder-icon {
  color: rgb(153, 153, 153);
  font-size: 2.5rem;
}

.desktop-message-card-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.desktop-message-card-content-title-wrapper {
  display: flex;
  padding-bottom: 0.35rem;
  align-items: flex-end;
}

.desktop-message-card-content-title {
  color: #464646;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
}

.desktop-message-card-content-time {
  color: #999;
  margin: 0;
  font-weight: 400;
  font-size: 0.8rem;
  margin-left: 10px;
}

.desktop-message-card-location-wrapper {
  padding-bottom: 0.35rem;
}

.desktop-message-card-location {
  color: rgb(153, 153, 153);
  font-size: 0.95rem;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.desktop-message-card-message-wrapper {
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.desktop-message-card-message {
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #464646;
  font-size: 1rem;
  font-weight: 500;
}

.message-card-chat-link {
  text-decoration: none !important;
}
