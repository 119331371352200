.sugar-daddy-subtitle {
  text-align: center;
  text-transform: uppercase;
  color: #c696e6;
  font-size: 24px;
  font-weight: 800;
}

.sugar-baby-subtitle {
  text-align: center;
  text-transform: uppercase;
  color: #da0e2b;
  font-size: 24px;
  font-weight: 800;
}

.sugar-daddy-title {
  font-size: 50px;
  color: #efefef;
  font-weight: 600;
  text-align: center;
}

.hero-section {
  background-color: #efefef;
  background-image: url("../../assets/Images/sugar-daddy/sugar-daddy-hero-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 70px;
  padding-top: 4rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sugar-daddy-hero-description {
  text-align: center;
  color: #efefef;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

.sugar-daddy-maincontent {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #f9f9f9;
}

.sd-mc-cardtitle {
  color: #da0e2b;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
}

.sd-mc-card {
  margin-bottom: 2rem;
  flex-basis: 100%;
}

.sd-mc-cardwrap {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 767px;
}

.sd-belowgrid-content {
  background-color: #e9e9e9;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.sd-bg-title {
  text-align: center;
  color: #14181a;
  font-size: 1.8rem;
  font-weight: 300;
}

.sd-bg-subtitle {
  text-align: center;
  color: #da0e2b;
  font-size: 1.4rem;
  font-weight: 300;
  font-style: italic;
  padding-bottom: 2rem;
}

.sd-bg-li {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.sd-cta-two {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-image: url("../../assets/Images/sugar-daddy/sugar-daddy-cta-background-two.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sg-down-arrow-txt {
  text-align: center;
  color: #efefef;
}

.sd-down-icon {
  text-align: center;
  color: #efefef;
}

.sg-down-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;
}

.sg-down-arrow-txt {
  margin: 0 !important;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.sd-getting-started {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  background-color: #f9f9f9;
  border-bottom: 1px solid #dbdbdb !important;
}

.sd-gs-title {
  text-align: center;
  color: #14181a;
  font-size: 1.8rem;
  font-weight: 300;
}

.sd-mc-title {
  text-align: center;
  color: #14181a;
  font-size: 1.8rem;
  font-weight: 300;
}

.sd-mc-subtitle {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 200;
  font-style: italic;
  padding-bottom: 2rem;
}

.sd-gs-col-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
}

.sd-gs-col-content {
  font-size: 15px;
  color: #464646;
}

.sd-gs-col {
  padding-top: 1rem;
}

.sd-gs-container {
  display: flex;
  flex-direction: column;
}

.sd-gs-btn-wrap {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.sd-btn-wrap {
  display: flex;
  justify-content: center;
}

.sd-mc-btn {
  margin: auto;
  font-size: 1.2rem !important;
}

.sd-mc-btn-wrap {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.sd-gs-btn {
  margin: auto;
  font-size: 1.2rem !important;
  border-color: #da0e2b !important;
  color: #da0e2b !important;
}

.getting-started-icon {
  display: none;
}

.sd-mc-card-text-list-title {
  padding-top: 1rem;
}

.sd-mc-li {
  padding-bottom: 0.5rem;
}

.sd-bg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sd-bg-features-col {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}

.sd-bg-features-title {
  margin-bottom: 0;
  font-size: 1.2rem;
  color: #464646;
}

.sd-bg-features-icon {
  color: #da0e2b;
  font-size: 2rem;
  padding-right: 1rem;
}

.sd-bg-feature-description {
  display: none;
}

@media (min-width: 767px) {
  .sd-mc-card {
    flex-basis: 45%;
  }

  .sd-gs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 1rem;
  }

  .sd-gs-col {
    flex-basis: 30%;
  }

  .sd-gs-col-title {
    text-align: center;
  }
  .sd-gs-col-content {
    text-align: center;
  }

  .getting-started-icon {
    display: block;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #5804d7;
  }

  .hero-section {
    background-image: url("../../assets/Images/sugar-daddy/sugar-daddy-hero-background-desktop.jpg");
  }

  .sd-bg-features-col {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    align-items: center;
    padding-bottom: 4rem;
  }

  .sd-bg-features-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .sd-bg-features-icon {
    padding-bottom: 1rem;
    padding-right: 0;
  }

  .sd-bg-container {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
  }

  .sd-bg-feature-description {
    display: block;
    text-align: center;
    padding-top: 1rem;
    font-weight: 300;
  }
}
