.advanced-filters-v2-back-button {
  background-color: transparent;
  border: 0;
  color: #000;
  padding-left: 0;
}

.advanced-filters-v2-header-section {
  display: flex;
  align-items: center;
}

.advanced-filters-v2-header-reset-all-button {
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: #000;
}

.advanced-filters-v2-header-title {
  margin: 0;
  font-size: 18px;
}

.advanced-filters-back-icon {
  margin-right: 5px;
}

.advanced-filter-counter {
  font-size: 14px;
  color: #fff;
  background-color: #021e30;
  border-radius: 3px;
  padding: 0.35rem;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plus-settings-switch .Mui-checked {
  color: #eeeeee !important;
}
