.signup-section-container {
  background-color: #fff;
  padding-bottom: 2rem;
}

.sp-title {
  text-align: center;
  font-weight: 700 !important;
  color: #021e30;
  font-size: 3rem !important;
  padding-top: 1.5rem;
}

.sg-login-link {
  border-bottom: 4px solid #c44b4b;
  padding-bottom: 0.5rem;
}

.signup-form {
  padding: 1rem;
  width: 100%;
  max-width: 400px;
}

.sp-error-alert {
  font-size: 0.9rem;
}

.sp-forgot-password-link {
  text-decoration: underline;
  color: #721c24;
}

.signup-form-btn {
  width: 100% !important;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  margin-top: 1rem;
}

.signup-form-label {
  font-weight: 400;
  color: #021e30;
}

.sp-forgot-password-wrapper {
  display: flex;
  justify-content: flex-end;
}

.sp-forgot-password-link {
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  color: #021e30 !important;
  padding: 0 !important;
}

.signup-below-form-container {
  padding-top: 3rem;
  text-align: center;
}

.sp-login-text {
  color: #021e30;
}

.sp-login-link {
  color: #021e30;
  font-weight: 700;
  text-decoration: underline;
}

.signup-form-input {
  border-color: #021e30 !important;
  color: #021e30 !important;
}

.signup-form-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  padding: 0 !important;
}

.signup-form-card {
  max-width: 600px !important;
  border-radius: 0 !important;
  width: 100% !important;
}

.signup-form-container {
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.sf-gender-input-wrapper {
  display: flex;
}

.sf-gender-btn {
  margin-right: 10px;
}

.sf-gender-input {
  position: absolute;
  opacity: 0;
}

.sf-radioinputbtn {
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #090a20;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  color: #495057;
}

.sf-gender-input:checked ~ .sf-radioinputbtn {
  background-color: #090a20;
  color: #fff;
}

.sf-location-input-wrapper {
  position: relative;
}

.sf-dropdown-card {
  position: absolute;
  z-index: 5;
  font-size: 1rem;
  top: 50px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
}

.suggestion-item {
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #dbdbdb;
}

.suggestion-item--active {
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #dbdbdb;
}

.sf-signup-agreement-wrapper {
  padding-top: 2rem;
  width: 100%;
  margin: auto;
  max-width: 400px;
}

.sf-signup-agreement {
  font-size: 0.9rem;
  text-align: center;
}

.sg-subtitle {
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: #888;
  font-weight: 500;
  margin-bottom: 0;
}

.sg-subtitlea {
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: #000;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
}

.sg-subtitle-free {
  color: #c44b4b;
  font-weight: 600;
}

.sf-gender-invalid {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #c44b4b;
}

.signup-form-top-img {
  border-radius: 0 !important;
}

.landing-page-container-form {
  width: 100%;
  min-height: 100vh !important;
  background-color: #021e30;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.landing-page-header {
  width: 100%;
  background-color: #021e30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  position: relative;
}

.landing-page-progress-bar {
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 0 !important;
  height: 10px !important;
  background-color: #021e30 !important;
}

.landing-page-progress-bar .progress-bar {
  background-color: #c44b4b !important;
}

.landing-page-step-form {
  padding: 1.5rem;
  padding-top: 3rem;
  width: 100%;
  max-width: 600px;
}

.landing-page-step-form-title {
  text-align: center;
  color: #fff;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.landing-page-step-form-title-age {
  text-align: center;
  color: #fff;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.landing-form-age-input {
  width: 200px !important;
  height: 65px !important;
  margin: auto;
  text-align: center;
  font-size: 1.5rem !important;
}

.landing-form-location-input {
  height: 60px !important;
}

.landing-select {
  width: 100%;
  border: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  position: relative;
  max-width: 600px;
  margin: auto;
  margin-bottom: 1rem;
  cursor: pointer;
}
.landing-select-active {
  width: 100%;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #090a20;
  font-weight: 600;
  font-size: 1.5rem;
  position: relative;
  max-width: 600px;
  margin: auto;
  margin-bottom: 1rem;
  cursor: pointer;
}

.landing-page-back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.landing-page-back-wrapper {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 30rem;
  padding-bottom: 2rem;
}

.landing-page-back-text {
  color: #dfe3e8;
  font-size: 1.2rem;
}

.age-input-subtitle {
  text-align: center;
  color: #dfe3e8;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.divider {
  width: 100%;
  height: 35px;
  border-bottom: 1px solid black;
  text-align: center;
  padding: 15px;
}

.divider-title {
  font-size: 20px;
  font-weight: 500;
  background-color: white;
  padding: 0 15px;
}

.signup-form-top-img {
  display: none;
}

.landing-page-recaptcha {
  margin-top: 15px;
}

@media (min-width: 767px) {
  .signup-form-top-img {
    display: block;
  }
  .signup-section-container {
    background-color: #021e30;
    padding-top: 2rem;
  }
}
