.search-user-card {
  position: relative;
  padding: 0 !important;
  border-radius: 15px !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  border: 1px solid #2a2d43 !important;
}

.search-user-card-body {
  padding: 0 !important;
  position: relative;
  border-radius: 15px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.search-user-card-footer {
  padding: 0.5rem !important;
  background-color: #f1f1f1 !important;
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.search-user-card-image {
  border-radius: 15px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 250px !important;
  width: 100%;
  object-fit: cover;
}

.search-user-card-info {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 15px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.search-user-card-info-wrap {
  display: flex;
}

.search-user-card-info-innerwrap {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-user-card-info-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
}

.search-user-card-online-icon {
  font-size: 0.6rem;
  color: #00c684;
}

.liked-stamp-image {
  position: absolute;
  top: 15px;
  left: 5px;
  z-index: 2;
  width: 30%;
  max-width: 250px;
  transform: rotate(-20deg);
}

.undo-stamp-image {
  position: absolute;
  top: 15px;
  right: 5px;
  z-index: 2;
  width: 30%;
  max-width: 250px;
  transform: rotate(20deg);
}
