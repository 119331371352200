.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
}

.icon-risk-score-0 {
  background-image: url(../../../../assets/Images/admin/risk_00.svg);
}

.icon-risk-score-1 {
  background-image: url(../../../../assets/Images/admin/risk_01.svg);
}

.icon-risk-score-2 {
  background-image: url(../../../../assets/Images/admin/risk_02.svg);
}

.icon-risk-score-3 {
  background-image: url(../../../../assets/Images/admin/risk_03.svg);
}

.icon-risk-score-4 {
  background-image: url(../../../../assets/Images/admin/risk_04.svg);
}

.icon-risk-score-5 {
  background-image: url(../../../../assets/Images/admin/risk_05.svg);
}

.icon-risk-score-6 {
  background-image: url(../../../../assets/Images/admin/risk_06.svg);
}

.icon-risk-score-7 {
  background-image: url(../../../../assets/Images/admin/risk_07.svg);
}

.icon-risk-score-8 {
  background-image: url(../../../../assets/Images/admin/risk_08.svg);
}

.icon-risk-score-9 {
  background-image: url(../../../../assets/Images/admin/risk_09.svg);
}

.icon-risk-score-10 {
  background-image: url(../../../../assets/Images/admin/risk_10.svg);
}

.icon-risk-score-11 {
  background-image: url(../../../../assets/Images/admin/risk_11.svg);
}

.icon-risk-score-12 {
  background-image: url(../../../../assets/Images/admin/risk_12.svg);
}

.icon-risk-score-13 {
  background-image: url(../../../../assets/Images/admin/risk_13.svg);
}

.icon-risk-score-14 {
  background-image: url(../../../../assets/Images/admin/risk_14.svg);
}

.icon-risk-score-15 {
  background-image: url(../../../../assets/Images/admin/risk_15.svg);
}

.icon-risk-score-16 {
  background-image: url(../../../../assets/Images/admin/risk_16.svg);
}

.icon-risk-score-17 {
  background-image: url(../../../../assets/Images/admin/risk_17.svg);
}

.icon-risk-score-18 {
  background-image: url(../../../../assets/Images/admin/risk_18.svg);
}

.icon-risk-score-19 {
  background-image: url(../../../../assets/Images/admin/risk_19.svg);
}

.icon-risk-score-20 {
  background-image: url(../../../../assets/Images/admin/risk_20.svg);
}

.icon-risk-score-21 {
  background-image: url(../../../../assets/Images/admin/risk_21.svg);
}

.icon-risk-score-22 {
  background-image: url(../../../../assets/Images/admin/risk_22.svg);
}

.icon-risk-score-23 {
  background-image: url(../../../../assets/Images/admin/risk_23.svg);
}

.icon-risk-score-24 {
  background-image: url(../../../../assets/Images/admin/risk_24.svg);
}

.icon-risk-score-25 {
  background-image: url(../../../../assets/Images/admin/risk_25.svg);
}

.icon-risk-score-26 {
  background-image: url(../../../../assets/Images/admin/risk_26.svg);
}

.icon-risk-score-27 {
  background-image: url(../../../../assets/Images/admin/risk_27.svg);
}

.icon-risk-score-28 {
  background-image: url(../../../../assets/Images/admin/risk_28.svg);
}

.icon-risk-score-29 {
  background-image: url(../../../../assets/Images/admin/risk_29.svg);
}

.icon-risk-score-30 {
  background-image: url(../../../../assets/Images/admin/risk_30.svg);
}

.icon-risk-score-31 {
  background-image: url(../../../../assets/Images/admin/risk_31.svg);
}

.icon-risk-score-32 {
  background-image: url(../../../../assets/Images/admin/risk_32.svg);
}

.icon-risk-score-33 {
  background-image: url(../../../../assets/Images/admin/risk_33.svg);
}

.icon-risk-score-34 {
  background-image: url(../../../../assets/Images/admin/risk_34.svg);
}

.icon-risk-score-35 {
  background-image: url(../../../../assets/Images/admin/risk_35.svg);
}

.icon-risk-score-36 {
  background-image: url(../../../../assets/Images/admin/risk_36.svg);
}

.icon-risk-score-37 {
  background-image: url(../../../../assets/Images/admin/risk_37.svg);
}

.icon-risk-score-38 {
  background-image: url(../../../../assets/Images/admin/risk_38.svg);
}

.icon-risk-score-39 {
  background-image: url(../../../../assets/Images/admin/risk_39.svg);
}

.icon-risk-score-40 {
  background-image: url(../../../../assets/Images/admin/risk_40.svg);
}

.icon-risk-score-41 {
  background-image: url(../../../../assets/Images/admin/risk_41.svg);
}

.icon-risk-score-42 {
  background-image: url(../../../../assets/Images/admin/risk_42.svg);
}

.icon-risk-score-43 {
  background-image: url(../../../../assets/Images/admin/risk_43.svg);
}

.icon-risk-score-44 {
  background-image: url(../../../../assets/Images/admin/risk_44.svg);
}

.icon-risk-score-45 {
  background-image: url(../../../../assets/Images/admin/risk_45.svg);
}

.icon-risk-score-46 {
  background-image: url(../../../../assets/Images/admin/risk_46.svg);
}

.icon-risk-score-47 {
  background-image: url(../../../../assets/Images/admin/risk_47.svg);
}

.icon-risk-score-48 {
  background-image: url(../../../../assets/Images/admin/risk_48.svg);
}

.icon-risk-score-49 {
  background-image: url(../../../../assets/Images/admin/risk_49.svg);
}

.icon-risk-score-50 {
  background-image: url(../../../../assets/Images/admin/risk_50.svg);
}

.icon-risk-score-51 {
  background-image: url(../../../../assets/Images/admin/risk_51.svg);
}

.icon-risk-score-52 {
  background-image: url(../../../../assets/Images/admin/risk_52.svg);
}

.icon-risk-score-53 {
  background-image: url(../../../../assets/Images/admin/risk_53.svg);
}

.icon-risk-score-54 {
  background-image: url(../../../../assets/Images/admin/risk_54.svg);
}

.icon-risk-score-55 {
  background-image: url(../../../../assets/Images/admin/risk_55.svg);
}

.icon-risk-score-56 {
  background-image: url(../../../../assets/Images/admin/risk_56.svg);
}

.icon-risk-score-57 {
  background-image: url(../../../../assets/Images/admin/risk_57.svg);
}

.icon-risk-score-58 {
  background-image: url(../../../../assets/Images/admin/risk_58.svg);
}

.icon-risk-score-59 {
  background-image: url(../../../../assets/Images/admin/risk_59.svg);
}

.icon-risk-score-60 {
  background-image: url(../../../../assets/Images/admin/risk_60.svg);
}

.icon-risk-score-61 {
  background-image: url(../../../../assets/Images/admin/risk_61.svg);
}

.icon-risk-score-62 {
  background-image: url(../../../../assets/Images/admin/risk_62.svg);
}

.icon-risk-score-63 {
  background-image: url(../../../../assets/Images/admin/risk_63.svg);
}

.icon-risk-score-64 {
  background-image: url(../../../../assets/Images/admin/risk_64.svg);
}

.icon-risk-score-65 {
  background-image: url(../../../../assets/Images/admin/risk_65.svg);
}

.icon-risk-score-66 {
  background-image: url(../../../../assets/Images/admin/risk_66.svg);
}

.icon-risk-score-67 {
  background-image: url(../../../../assets/Images/admin/risk_67.svg);
}

.icon-risk-score-68 {
  background-image: url(../../../../assets/Images/admin/risk_68.svg);
}

.icon-risk-score-69 {
  background-image: url(../../../../assets/Images/admin/risk_69.svg);
}

.icon-risk-score-70 {
  background-image: url(../../../../assets/Images/admin/risk_70.svg);
}

.icon-risk-score-71 {
  background-image: url(../../../../assets/Images/admin/risk_71.svg);
}

.icon-risk-score-72 {
  background-image: url(../../../../assets/Images/admin/risk_72.svg);
}

.icon-risk-score-73 {
  background-image: url(../../../../assets/Images/admin/risk_73.svg);
}

.icon-risk-score-74 {
  background-image: url(../../../../assets/Images/admin/risk_74.svg);
}

.icon-risk-score-75 {
  background-image: url(../../../../assets/Images/admin/risk_75.svg);
}

.icon-risk-score-76 {
  background-image: url(../../../../assets/Images/admin/risk_76.svg);
}

.icon-risk-score-77 {
  background-image: url(../../../../assets/Images/admin/risk_77.svg);
}

.icon-risk-score-78 {
  background-image: url(../../../../assets/Images/admin/risk_78.svg);
}

.icon-risk-score-79 {
  background-image: url(../../../../assets/Images/admin/risk_79.svg);
}

.icon-risk-score-80 {
  background-image: url(../../../../assets/Images/admin/risk_80.svg);
}

.icon-risk-score-81 {
  background-image: url(../../../../assets/Images/admin/risk_81.svg);
}

.icon-risk-score-82 {
  background-image: url(../../../../assets/Images/admin/risk_82.svg);
}

.icon-risk-score-83 {
  background-image: url(../../../../assets/Images/admin/risk_83.svg);
}

.icon-risk-score-84 {
  background-image: url(../../../../assets/Images/admin/risk_84.svg);
}

.icon-risk-score-85 {
  background-image: url(../../../../assets/Images/admin/risk_85.svg);
}

.icon-risk-score-86 {
  background-image: url(../../../../assets/Images/admin/risk_86.svg);
}

.icon-risk-score-87 {
  background-image: url(../../../../assets/Images/admin/risk_87.svg);
}

.icon-risk-score-88 {
  background-image: url(../../../../assets/Images/admin/risk_88.svg);
}

.icon-risk-score-89 {
  background-image: url(../../../../assets/Images/admin/risk_89.svg);
}

.icon-risk-score-90 {
  background-image: url(../../../../assets/Images/admin/risk_90.svg);
}

.icon-risk-score-91 {
  background-image: url(../../../../assets/Images/admin/risk_91.svg);
}

.icon-risk-score-92 {
  background-image: url(../../../../assets/Images/admin/risk_92.svg);
}

.icon-risk-score-93 {
  background-image: url(../../../../assets/Images/admin/risk_93.svg);
}

.icon-risk-score-94 {
  background-image: url(../../../../assets/Images/admin/risk_94.svg);
}

.icon-risk-score-95 {
  background-image: url(../../../../assets/Images/admin/risk_95.svg);
}

.icon-risk-score-96 {
  background-image: url(../../../../assets/Images/admin/risk_96.svg);
}

.icon-risk-score-97 {
  background-image: url(../../../../assets/Images/admin/risk_97.svg);
}

.icon-risk-score-98 {
  background-image: url(../../../../assets/Images/admin/risk_98.svg);
}

.icon-risk-score-99 {
  background-image: url(../../../../assets/Images/admin/risk_99.svg);
}

.icon-risk-score-100 {
  background-image: url(../../../../assets/Images/admin/risk_100.svg);
}