.benefits-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #000;
}

.benefits-component {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.benefits-content {
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  max-height: 70px;
}
.benefits-content-expanded {
  display: flex;
  flex-wrap: wrap;
}

.benefits-item {
  display: flex;
  align-items: center;
  padding-left: 0.35rem;
  padding-right: 0.35rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  background-color: #eeeeee;
  border-radius: 3px;
  font-size: 14px;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  color: #021e30;
}

.benefits-item-active {
  display: flex;
  align-items: center;
  padding-left: 0.35rem;
  padding-right: 0.35rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  background-color: #021e30;
  border-radius: 3px;
  font-size: 14px;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  color: #fff;
}

.benefits-show-all-btn {
  background: transparent;
  border: 0 !important;
  color: #000 !important;
  text-decoration: underline !important;
  padding-left: 0 !important;
  margin-top: 0.25rem;
  font-size: 14px;
}

@media (min-width: 992px) {
  .benefits-title {
    font-size: 18px;
    font-weight: 700;
  }
}
