.requests-component {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  position: relative;
}

.requests-header {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 !important;
  height: 8% !important;
  position: relative;
  width: 100%;
}

.requests-header-left {
  position: absolute;
  left: 1rem;
}

.requests-header-title {
  margin: 0;
  text-align: center;
  font-size: 18px;
}

.requests-return-button {
  background-color: transparent;
  border: 0;
  color: #000;
  font-size: 16px;
}

.requests-content {
  height: 92% !important;
  overflow-y: scroll !important;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.edit-profile-v2-request-action {
  cursor: pointer;
}
