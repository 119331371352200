.user-profile-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
}

.user-profile-header-nav-wrap {
  width: 100%;
  max-width: 700px;
  margin-top: 1rem;
}

.user-profile-header-nav {
  width: 100%;
  box-shadow: 0 0.25rem 0 rgba(177, 187, 199, 0.2);
}

.user-profile-header-nav-list {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
}

.tabinactive {
  font-size: 0.9rem;
  cursor: pointer;
  color: #888;
  padding-bottom: 1rem;
}

.tabisactive {
  border-bottom: 0.125rem solid #5804d7;
  cursor: pointer;

  color: #000;
  font-weight: 500;
  font-size: 0.9rem;
  padding-bottom: 1rem;
}

.componentactive {
  display: block;
}

.componentinactive {
  display: none;
}
