.features-content {
  padding: 1rem;
  width: 100%;
}

.features-header {
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin: auto;
  margin-bottom: 3rem;
  border-bottom: 2px solid #da0e2b;

  max-width: 1100px;
}

.features-header-title {
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
}

.features-header-subtitle {
  text-align: center;
  font-size: 1.15rem;
  margin: auto;
  margin-top: 20px;
  font-weight: 600;
  max-width: 800px;
}

.features-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.features-card {
  width: 100%;
  border-radius: 5px;
  background-color: #090a20;
  position: relative;
  padding: 1rem;
  padding-top: 4rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.features-icon-wrapper {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 35%;
  top: -55px;
  background-color: #f9f9f9;
  border: 2px solid #090a20;
}

.features-icon-image {
  width: 55px;
  height: 55px;
  object-fit: cover;
}

.features-title {
  color: #fff;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.features-text {
  font-size: 1rem;
  color: #dbdbdb;
}

.features-card-cta {
  width: 100%;
  display: flex;
  justify-content: center;
}

.features-card-button {
  background-color: transparent !important;
  border-color: transparent !important;
}

.features-card-button-icon {
  margin-left: 10px;
  font-size: 0.75rem;
}

@media (min-width: 992px) {
  .features-content-container {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: auto;
  }

  .features-card {
    width: 100%;
    border-radius: 5px;
    background-color: #090a20;
    position: relative;
    padding: 1rem;
    padding-top: 4rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
    flex-basis: 30%;
  }
}
