.desktop-swipe-actions-v2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.desktop-swipe-v2-action-button {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.desktop-swipe-v2-action-button-compliment {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #c44b4b;
  cursor: pointer;
}

.desktop-swipe-v2-action-icon {
  height: 24px;
  width: 24px;
}
