.welcome-component-container {
  background-color: #021e30;
  width: 100%;
  height: 100%;
}

.welcome-component-container-nonstick {
  background-color: #021e30;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 5rem;
}

.welcome-progress-bar {
  border-radius: 0 !important;
  background-color: #393c59 !important;
  height: 2% !important;
}

.welcome-content-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  overflow-y: scroll;
}

.welcome-content-wrapper-nonstick {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 18%;
  border-top: 1px solid #dbdbdb;
}

.welcome-site-logo {
  width: 55%;
  max-width: 300px;
}

.welcome-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.welcome-title {
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  text-shadow: rgba(40, 42, 42, 0.2) 1px 1px 2px;
  text-align: center;
  margin-bottom: 20px;
}
.welcome-title-bigger {
  color: #fff;
  font-weight: 700;
  font-size: 2rem;
  text-shadow: rgba(40, 42, 42, 0.2) 1px 1px 2px;
  text-align: center;
  margin-bottom: 20px;
}

.welcome-title-wrap {
  border-bottom: 2px solid #dbdbdb;
}

.welcome-icon {
  text-align: center;
  color: #c44b4b;
  font-size: 2rem;
  margin-bottom: 15px;
  width: 100%;
}

.welcome-subtitle {
  font-size: 20px;
  color: #fff;
  text-shadow: rgba(40, 42, 42, 0.2) 1px 1px 2px;
  text-align: center;
}

.welcome-form {
  width: 100%;
  padding-top: 2rem;
}

.welcome-form-checkbox {
  width: 80%;
  padding-top: 2rem;
}

.welcome-form-checkbox-title {
  color: #fff;
  font-size: 1rem;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 2rem;
}

.welcome-checkbox-wrapper {
  padding-bottom: 1rem;
  position: relative;
}

.welcome-notifications-checkbox {
  transform: scale(1.8);
  color: #fff !important;
  margin-top: 12px !important;
}

.welcome-notifications-label {
  color: #fff;
  margin-left: 1rem;
  font-size: 1.1rem;
}

.welcome-phone-input-wrapper {
  padding-top: 2rem;
  height: 100%;
}

.welcome-loading-wrapper {
  padding-top: 3rem;
}

.welcome-photo-grid-wrapper {
  padding-top: 2rem;
  width: 100%;
  max-width: 600px;
}

.welcome-photos-guidelines-wrapper {
  padding-top: 2rem;
  width: 100%;
}

.welcome-photogrid-subtitle {
  color: #dbdbdb;
  display: flex;
  flex-direction: row-reverse;
  font-size: 0.7rem;
}

.photos-guideline-title {
  color: #fff;
  margin-bottom: 1rem;
}

.photos-guidelines-disclaimer {
  color: #dbdbdb;
  font-size: 0.7rem;
}

.photos-guidelines-list {
  color: #fff;
  padding-left: 1.5rem;
}

.photos-guidelines-list-item {
  margin-bottom: 0.5rem;
  color: #dbdbdb;
}

.welcome-select-btn-wrapper {
  width: 100%;
  padding-top: 2rem;
}

.benefits-select-btn-wrapper {
  width: 100%;
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.welcome-checkbox-btn {
  color: #fff !important;
  border-radius: 100px !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
  border-color: #dbdbdb !important;
  width: 100% !important;
  max-width: 500px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 60px !important;
}

.welcome-checkbox-btn-checked {
  color: #090a20 !important;
  background-color: #fff !important;
  border-radius: 100px !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
  border-color: #dbdbdb !important;
  width: 100% !important;
  max-width: 500px !important;
  height: 60px !important;
}

.benefits-checkbox-btn {
  color: #fff !important;
  border-color: #dbdbdb !important;
  border-radius: 100px !important;
  font-size: 0.7rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
  border-color: #dbdbdb !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  flex-basis: 48% !important;
  max-width: 48% !important;
}

.benefits-checkbox-btn-checked {
  color: #021e30 !important;
  background-color: #fff !important;
  border-radius: 100px !important;
  font-size: 0.7rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
  border-color: #dbdbdb !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  flex-basis: 48% !important;
  max-width: 48% !important;
}

.welcome-form-label {
  color: #fff;
}

.welcome-change-email-linkwrapper {
  display: flex;
  flex-direction: row-reverse;
}

.welcome-changeemail-link {
  color: #888 !important;
}

.welcome-btn-wrap-nonstick {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.welcome-link {
  width: 80%;
}

.welcome-btn-next {
  width: 80% !important;
  height: 50% !important;
  max-height: 70px !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  background-color: #fff !important;
  border-color: #fff !important;
  color: #090a20 !important;
  max-width: 500px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.welcome-btn-getstarted {
  width: 80% !important;
  height: 50% !important;
  max-height: 70px !important;
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  color: #fff !important;
  max-width: 500px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.welcome-btn-advance {
  width: 80% !important;
  max-height: 50px !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  color: #fff !important;
  border-color: #fff !important;
  max-width: 500px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.premium-btn-advance {
  width: 80% !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  color: #fff !important;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  max-width: 500px !important;
}

.welcome-skip-link {
  margin-top: 10px;
  color: #888;
}

.welcome-rathernot-link {
  margin-top: 15px;
  color: #888;
  font-size: 1.2rem;
  font-weight: 500;
}

.offer-header-wrapper {
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 3px solid #c44b4b;
}

.offer-header-wrapper-free {
  background-color: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.welcome-offer-icon {
  height: 50px !important;
  margin-left: 1rem;
}

.offer-title {
  margin-bottom: 0;
  font-weight: 700;
}

.offer-features-wrapper {
  width: 100%;
  padding-top: 2rem;
  padding-left: 1rem;
}

.welcome-offer-checkmark {
  color: #fff;
  margin-right: 1rem;
  font-size: 1.2rem;
}

.offer-feature {
  padding-bottom: 1rem;
}

.offer-feature-text {
  font-size: 1rem;
  color: #fff;
  font-weight: 500;
}

.welcome-checkmark-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.welcome-offer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.welcome-offer-wrapper {
  width: 100%;
}

// ! Welcome Action Card

.welcome-action-card {
  width: 100%;
  margin-top: 2rem;
}

.welcome-action-card-title {
  text-align: center;
  color: #090a20;
  font-weight: 700 !important;
  margin: 0 !important;
}

.welcome-action-card-subtitle {
  text-align: center;
  color: #090a20;
  font-weight: 500;
  line-height: 1.4;
  margin-top: 8px;
}

.welcome-action-card-body {
  height: 100%;
  overflow-y: scroll;
}

.welcome-action-item {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.welcome-action-icon-wrapper {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.5rem;
}

.welcome-action-text-wrapper {
  width: 75%;
}

.welcome-action-title {
  margin: 0;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 7px;
}

.welcome-action-subtitle {
  margin: 0;
  line-height: 1.2;
  font-size: 1rem;
}
.welcome-action-icon-heart {
  color: #55eac4;
  font-size: 2.2rem;
}

.welcome-action-icon-message {
  color: #2a2d43;
  font-size: 2.2rem;
}
.welcome-action-icon-match {
  color: #fd277d;
  font-size: 2.2rem;
}

@media (min-width: 767px) {
  .welcome-offer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .welcome-offer-wrapper {
    flex-basis: 48%;
  }

  .welcome-component-container {
    overflow-y: scroll;
  }
  .welcome-content-wrapper {
    max-width: 1000px;
    margin: auto;
    padding-top: 5rem;
    overflow-y: auto;
  }

  .welcome-content-wrapper-nonstick {
    max-width: 1000px;
    margin: auto;
  }

  .welcome-photos-guidelines-wrapper {
    max-width: 600px;
  }

  .welcome-form {
    max-width: 500px;
  }

  .welcome-form-checkbox {
    max-width: 500px;
  }

  .benefits-checkbox-btn {
    flex-basis: 19% !important;
    max-width: 19% !important;
  }

  .benefits-checkbox-btn-checked {
    flex-basis: 19% !important;
    max-width: 19% !important;
  }

  .welcome-notifications-checkbox {
    margin-top: 5px !important;
  }
}
