.sd-cta-content {
  text-align: center;
  color: #fff;
  max-width: 800px;
  font-weight: 500;
  margin: auto;
  margin-bottom: 3rem;
}

.join-now-cta-container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-image: url("../../../assets/Images/sugar-daddy/sugar-daddy-cta-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
