.maintenance-page {
  height: 100%;
  width: 100%;
}

.maintenance-nav {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #090a20;
}

.maintenance-nav-logo {
  height: 80%;
}

.maintenance-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
}

.maintenance-card {
  width: 100%;
  max-width: 500px;
}

.maintenance-title {
  text-align: center;
}

.maintenance-subtitle {
  text-align: center;
  max-width: 300px;
  margin: auto;
  font-size: 1.2rem;
}

.maintenance-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maintenance-btn-link {
  width: 50%;
  margin-top: 2rem;
}

.maintenance-btn {
  width: 80% !important;
  background-color: #090a20 !important;
  border-color: #090a20 !important;
  margin-top: 2rem !important;
}
