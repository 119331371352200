.chat-v2-no-messages-component {
  width: 100%;
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-v2-no-messages-compliment-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.chat-v2-gift-icon {
  height: 66px;
  width: 66px;
}

.chat-v2-compliment-subtitle {
  font-size: 16px;
  color: #021e30;
  margin-top: 1.5rem;
}

.chat-v2-compliment-button-wrapper {
}

.chat-v2-footer-button-superlike {
  width: 100%;
  max-width: 300px;
  background-color: #e08b27;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 50px;
  cursor: pointer;
}
