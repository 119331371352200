#bottab-body {
  display: flex;
  flex-wrap: wrap;
}

.admin-bot-message-card {
  width: 30%;
  margin-left: 1rem;
  margin-bottom: 1rem;
  background-color: #383b41 !important;
}

.admin-bot-message-card-header {
  background-color: #383b41 !important;
  display: flex;
}

.admin-bot-message-image-container {
  display: flex;
  margin-right: 1rem;
}

.admin-bot-message-image-divider {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-bot-message-arrow {
  font-size: 0.5rem;
  color: #acadb0;
  margin-bottom: 0.5rem;
}

.admin-bot-message-card-footer {
  background-color: #383b41 !important;
}

.admin-bot-message-card-body {
  background-color: #40444b !important;
  margin: 1rem !important;
}

#bot-tab-reply-form {
  width: 100% !important;
}

#bot-tab-reply-form-input {
  resize: none !important;
}

.admin-bot-username {
  color: #fff;
  font-weight: 500;
}

.admin-bot-message-usernames-line {
  color: #acadb0;
  margin: 0;
  margin-bottom: 3px;
}

.admin-bot-message-timestamp {
  color: #b0b1b3;
  font-size: 0.8rem;
}

.admin-bot-message-content-wrapper {
  height: 100%;
  overflow-y: scroll;
}

.admin-bot-view-convo-cta {
  display: flex;
  justify-content: center;
  padding-bottom: 1.5rem;
}

.admin-bot-view-convo-button {
  background-color: transparent !important;
  border-color: #dbdbdb !important;
}

.view-convo-icon {
  color: #dbdbdb;
}

// ! Bot Convo Modal

.bot-convo-modal-header {
  background-color: #383b41 !important;
}

.bot-convo-modal-body {
  background-color: #40444b !important;
  height: 400px !important;
  overflow-y: scroll;
}

.bot-convo-modal-footer {
  background-color: #383b41 !important;
}

.bot-modal-convo-form {
  width: 100%;
}

#bot-modal-reply-form-input {
  resize: none !important;
}

.bot-convo-message-wrapper-sent {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.bot-convo-message-wrapper-received {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bot-convo-message-from-bot {
  background-color: #59b9f9;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 20px 20px 3px 20px;
  max-width: 70% !important;
}

.bot-convo-message-to-bot {
  background-color: #e6e6e6;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 20px 20px 20px 3px;
  max-width: 70% !important;
}

.bot-convo-chat-text-received {
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
}

.bot-convo-chat-text-sent {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
}
