.premium-component {
  width: 100%;
  height: 100%;
}

.premium-page-content {
  height: 80%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  background-color: #fff;
  overflow-y: scroll;
}

.premium-page-header-v2 {
}

.premium-page-h1 {
  font-size: 18px;
  font-weight: 700;
  color: #021e30;
}

.premium-page-h2 {
  font-size: 28px;
  font-weight: 700;
  color: #c44b4b;
  margin-bottom: 1.25rem;
}

.premium-page-p {
  font-size: 14px;
  color: #021e30;
}

.premium-page-price-section {
  width: 100%;
  padding: 1rem;
  background-color: #021e30;
  display: flex;
}

.premium-section-image-wrapper {
  width: 20%;
  border: 2px solid #c44b4b;
  border-radius: 3px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sugarbae-plus-logo-premium {
  width: 70%;
  object-fit: cover;
}

.premium-page-user-image {
  width: 100%;
  height: inherit;
  object-fit: cover;
}

.premium-section-price-wrapper {
  width: 80%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.premium-section-title {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.premium-section-price {
  display: flex;
  align-items: center;
}

.premium-dollar-sign {
  color: #b5b5b5;
  font-size: 14px;
  vertical-align: top;
  margin-bottom: 15px;
  margin-right: 5px;
}

.premium-price-price {
  color: #d95454;
  font-size: 30px;
  font-weight: 700;
  margin-right: 5px;
}

.premium-price-per-month {
  font-size: 14px;
  color: #b5b5b5;
  margin-top: 15px;
}

.premium-page-cta {
  height: 20%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.premium-free-button {
  background-color: transparent;
  border: 0;
  font-size: 16px;
  color: #2c2b2b;
}

.premium-activate-button {
  width: 100%;
  background-color: #c44b4b;
  border-color: #c44b4b;
  height: 48%;
  font-size: 16px;
  font-weight: 700;
}

.premium-checkout-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

//  ! Premium Success

.premium-success-component {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/background-pattern.png");
  background-size: cover;
}

.premium-success-content {
  height: 85%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  background-color: transparent;
  overflow-y: scroll;
}

.premium-success-page-cta-v2 {
  height: 15%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.premium-success-button-v2 {
  width: 100%;
  background-color: #c44b4b;
  border-color: #c44b4b;
  height: 100%;
  font-size: 16px;
  font-weight: 700;
  max-height: 50px;
}

.premium-success-thank-you-section {
  width: 100%;
  background-color: #021e30;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.premium-success-thank-you-title {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 1rem;
}

.premium-success-thank-you-subtitle {
  font-size: 16px;
  color: #fff;
  margin: 0;
}

.premium-success-receipt-section {
  width: 100%;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.premium-success-receipt-title {
  font-size: 18px;
  font-weight: 700;
  color: #021e30;
}

.premium-success-order-details-wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.premium-success-detail {
  font-size: 14px;
  color: #021e30;
}

.premium-success-message-section {
}

.premium-success-message-text {
  font-size: 12px;
  color: #b5b5b5;
}
