.wallet-component {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  position: relative;
}

.wallet-header {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 !important;
  height: 8% !important;
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.wallet-header-left {
  position: absolute;
  left: 0.5rem;
}

.wallet-header-title {
  margin: 0;
  text-align: center;
  font-size: 18px;
}

.wallet-return-button {
  background-color: transparent;
  border: 0;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.wallet-content {
  height: 82% !important;
  overflow-y: scroll !important;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 600px;
  margin: auto;
}

.wallet-content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

// ! Wallet Balance

.wallet-balance-container {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
}

.wallet-balance-info {
  display: flex;
  flex-direction: column;
}

.wallet-balance-title {
  color: #c44b4b;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.wallet-balance-wrapper {
  display: flex;
  align-items: center;
}

.wallet-balance-icon {
  width: 24px;
  margin-right: 10px;
}

.wallet-balance-text {
  font-weight: 700;
  font-size: 24px;
  color: #000;
}

.wallet-balance-cta {
}

.wallet-balance-cta-button {
  height: 50px;
  width: 50px;
  background-color: #c44b4b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 3px;
  font-size: 20px;
  cursor: pointer;
}

// !--

.wallet-content-header-image {
  width: 35%;
}

.wallet-content-header-subtitle {
  text-align: center;
  font-size: 14px;
  color: #021030;
  margin-top: 1rem;
  max-width: 85%;
}

.wallet-content-packages-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.wallet-package {
  width: 49%;
  flex-basis: 49%;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  margin-bottom: 0.5rem;
  height: 200px;
}

.wallet-package-active {
  width: 49%;
  flex-basis: 49%;
  border: 1px solid #c44b4b;
  border-radius: 3px;
  margin-bottom: 0.5rem;
  height: 200px;
}

.wallet-package-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-image: url('../../assets/Images/background-pattern.png');
  background-size: fill;
  height: 70%;
}

.wallet-package-icon {
  width: 42px;
  height: 42px;
}

.wallet-package-icon-pack {
  width: 72px;
  height: 48px;
}

.wallet-package-subtitle {
  font-size: 14px;
  color: #000;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.wallet-package-footer {
  background-color: #fff;
  border-top: 1px solid #f4f4f4;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30%;
}

.wallet-package-footer-active {
  background-color: #c44b4b;
  border-top: 1px solid #c44b4b;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30%;
}

.wallet-footer-discount-price {
  text-align: center;
  font-size: 12px;
  text-decoration: line-through;
  color: #b5b5b5;
  margin: 0;
  margin-bottom: 3px;
}
.wallet-footer-discount-price-active {
  text-align: center;
  font-size: 12px;
  text-decoration: line-through;
  color: #021030;
  margin: 0;
  margin-bottom: 3px;
}

.wallet-footer-price {
  font-size: 12px;
  text-align: center;
  color: #021030;
  font-weight: 700;
  margin: 0;
}
.wallet-footer-price-active {
  font-size: 12px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  margin: 0;
}

.wallet-footer {
  height: 10%;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 500px;
  margin: auto;
  padding-bottom: 15px;
}

.wallet-footer-button {
  width: 100%;
  background-color: #c44b4b;
  height: 100%;
  font-size: 16px;
  font-weight: 700;
  max-height: 48px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.wallet-purchase-loading-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
}

.wallet-purchase-loading-title {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #b5b5b5;
  margin-top: 1rem;
}

//  !! Redeem Dymonds

.wallet-exchange {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.wallet-exchange-center {
  position: absolute;
  width: 100%;
  background-color: transparent;
  padding: 2rem;
  top: 36%;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-redeem-equals {
  height: 32px;
  width: 32px;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  color: #b5b5b5;
}

.wallet-redeem-conversion {
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  height: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
}

.wallet-redeem-converion-section {
  border: 1px solid red;
}

.exchange-grey-diamond-icon {
  width: 14px;
  margin-right: 4px;
}

.wallet-redeem-number {
  font-size: 14px;
  color: #000;
}

.exchange-grey-dollar-icon {
  width: 14px;
  margin-right: 8px;
}

.redeem-equals-icon {
  color: #b5b5b5;
  font-size: 14px;
  font-weight: 800;
  margin-left: 1rem;
  margin-right: 1rem;
}

.wallet-exchange-container {
  width: 100%;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.wallet-exchange-container-bottom {
  width: 100%;
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
}

.walleter-exchange-info {
}

.wallet-exchange-wrapper {
  display: flex;
  flex-direction: column;
}

.wallet-exchange-text {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  margin-top: 1rem;
}
