.carousel {
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 500px;
  margin: auto;
}

.carousel-title {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.carousel-slider {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.premium-features-cta-button {
  width: 100%;
  background-color: #c44b4b;
  border-color: #c44b4b;
  font-size: 16px;
  font-weight: 700;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.carousel-slider-indicator {
  padding-top: 0.25rem;
  padding-bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slider-innerwrap {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.carousel-indicator {
  width: 13px;
  height: 5px;
  background-color: #c4c4c4;
  border-radius: 25px;
}

.carousel-indicator-active {
  width: 13px;
  height: 5px;
  border-radius: 25px;

  background-color: #021e30;
}
