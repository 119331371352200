.profile-v2-photo-grid {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.profile-v2-photo-grid-header {
  width: 100%;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.profile-v2-photo-grid-header-tab {
  width: 50%;
  text-align: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #b5b5b5;
  font-size: 14px;
  color: #b5b5b5;
  cursor: pointer;
}

.profile-v2-photo-grid-header-tab-active {
  width: 50%;
  text-align: center;
  padding-bottom: 0.5rem;
  color: #c44b4b;
  border-bottom: 2px solid #c44b4b;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.profile-v2-photo-grid-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 0.75rem;
}

.profile-v2-photo-grid-content-ownprofile {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 0.75rem;
}

.profile-v2-photo-wrapper {
  width: 48%;
  margin-bottom: 0.35rem;
  margin-right: 0.35rem;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.profile-v2-photo {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 3px;
  cursor: pointer;
}

.profile-v2-photo-wrapper-ownprofile {
  width: 31%;
  margin-bottom: 2%;
  margin-right: 2%;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.profile-v2-photo-ownprofile {
  width: 100%;
  height: 145px;
  object-fit: cover;
  border-radius: 3px;
}

.photo-grid-v2-input-placeholder {
  width: 31%;
  margin-bottom: 2%;
  margin-right: 2%;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #021030;
  height: 145px;
  font-size: 20px;
  background-color: #fcfcfc;
  cursor: pointer;
}

.profile-v2-photo-blur {
  width: 100%;
  border-radius: 3px;
  height: 200px;
  object-fit: cover;
  filter: blur(15px);
}

.profile-v2-photo-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
}

.profile-v2-photo-unlock {
  position: absolute;
  z-index: 2;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
}

.profile-v2-unlock-icon-wrapper {
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
}

.profile-v2-photo-request-private-button {
  width: 100%;
  background-color: #c44b4b !important;
  border: none !important;
}

.profile-v2-photo-wrapper-request {
  width: 48%;
  height: 200px;
  margin-bottom: 0.35rem;
  margin-right: 0.35rem;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  background-color: rgba(2, 30, 48, 1);
  cursor: pointer;
}

.profile-v2-request-title {
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}

.profile-v2-request-subtitle {
  text-align: center;
  margin: 0;
  font-size: 14px;
  margin-top: 0.5rem;
  color: #eeeeee;
}

.profile-v2-photo-request-innerwrap {
  height: 100%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(238, 238, 238, 0.05);
}

.profile-v2-unlock-icon-wrapper-request {
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.profile-v2-no-photos-innerwrap {
  border: 1px solid #eeeeee;
  width: 100%;
  height: 100px;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-v2-no-photos-text {
  text-align: center;
  font-size: 12px;
  color: #b5b5b5;
  margin: 0;
}

//  ! Requests Section

.photo-grid-requests-container {
  width: 100%;
}

.photo-grid-requests-row {
  width: 100%;
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.photo-grid-request-row-section {
  display: flex;
  align-items: center;
}

.request-row-title {
  font-size: 16px;
  color: #021030;
  margin-left: 12px;
}

.request-row-icon {
  font-size: 18px;
  color: #021030;
}

.request-row-icon-red {
  font-size: 18px;
  color: #c44b4b;
}

.request-row-number {
  font-size: 14px;
  color: #021030;
  margin-right: 20px;
}

.request-right-icon {
  color: #b5b5b5;
  font-size: 14px;
  margin-top: 3px;
}

.photo-grid-own-profile {
}

.photo-uploading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 3;
}
