// ! Card Header Styles

.card-header-wrap {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
}

.card-footer-wrap {
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
}

// ! User Table Styles

#row-image-wrapper {
  height: 150px;
  width: 150px;
  max-width: 150px;
  max-height: 150px;
  border: 1px solid grey;
  overflow: hidden;
}

#card-header-table {
  display: flex;
  justify-content: space-evenly !important;
}

#usertable-container {
  height: 72vh !important;
  padding: 1rem;
  overflow-y: scroll;
}

.modal-footer-innerwrap {
  display: flex !important;
  justify-content: space-between !important;
}

.user-info-tablelist {
  padding-left: 2rem;
}

.noliststyle {
  list-style: none;
  padding-left: 0 !important;
}

.img-table {
  margin-bottom: 0.4rem;
  cursor: pointer;
  object-fit: cover;
  border-radius: 5px;
  padding-top: 1px;
  width: 32px;
  height: 32px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1px;
}

.image-card {
  position: relative;
  padding: 3px;
  width: 176px;
  height: 275px;
  background: white;
  margin: 7px 19px;
}

.image-card-badge {
  position: absolute;
  right: 0;
}

.table-images-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1050px;
}

.ut-img-approvedbadge {
  font-size: 0.5rem !important;
  margin-bottom: 14px;
}

.ut-img-action-btn {
  font-size: 0.5rem !important;
  width: 90%;
  font-weight: 800 !important;
}

.images-card-body-ut {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem !important;
}

.images-card-ut {
  width: 100%;
}

.table-inner-container {
  max-width: 350px;
}

.action-buttons-container {
  display: flex;
  flex-direction: column;
}

.action-btn {
  margin-bottom: 0.5rem;
}

.action-btn-link:hover {
  text-decoration: none;
}

.user-status-table-wrapper {
  display: flex;
  flex-direction: column;
}

.bio-formtext {
  font-size: 0.8rem !important;
}

.revoke-premium-btn {
  margin-top: 1rem;
}

.bio-title {
  padding-left: 0.5rem;
}

.bio-action-approvalbtn {
  font-size: 0.7rem !important;
}

.bio-actions-ut-btn {
  width: 100%;
  font-size: 0.7rem !important;
}

//! User Table Filter Component Styles

.filter-form {
  margin-bottom: 0 !important;
}

.filter-status-wrapper {
  width: 100%;
  display: flex;
  padding-bottom: 1rem;
}

.filter-status-text {
  margin-bottom: 0 !important;
  font-size: 0.8rem;
  color: #888;
}

.filter-status-element {
  padding-right: 1.5rem;
}

.filter-title {
  font-weight: 500;
  color: rgb(179, 178, 178);
}

// ! Chat Feed

.chatfeed-chatcard-bodywrap {
  display: flex;
  justify-content: space-between;
}

.chatfeed-chatcard {
  display: flex;
  flex-basis: 17%;
  flex-wrap: wrap;
}

.chat-feed-header {
  display: flex;
  justify-content: space-between;
}

.chatfeed-chatcard-header {
  display: flex;
  justify-content: space-between;
}

.chatfeed-chatcard-body {
  display: flex;
  justify-content: space-between;
}

.chatfeed-chatcard-message {
  margin-bottom: 0;
  text-align: center;
  font-style: italic;
}

.chatfeed-chatcard-userinfo {
  margin-bottom: 0;
  font-size: 0.5rem;
}

.chatfeed-userimage {
  width: 50px !important;
  height: 50px !important;
}

.chatfeed-chatcard-usernametitle {
  font-size: 0.6rem;
}

.sendmsg-chatfeed-btn {
  width: 100%;
}

.send-msg-form-chatfeed {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: cover;
}

.icon-moderate-users {
  background-image: url(../../assets/Images/adminSidebar/users_active.svg);
}

.icon-moderate-reports {
  background-image: url(../../assets/Images/admin/report_flag.svg);
}

.icon-gender-male {
  width: 14px !important;
  height: 14px !important;
  margin-top: 7px;
  margin-left: 5px;
  background-image: url(../../assets/Images/admin/gender_male.svg);
}

.icon-gender-female {
  width: 11px !important;
  height: 14px !important;
  margin-top: 7px;
  margin-left: 5px;
  background-image: url(../../assets/Images/admin/gender_female.svg);
}

.icon-phone {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/phone.svg);
}

.icon-phone-active {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/phone_active.svg);
}

.icon-email {
  width: 14px !important;
  height: 14px !important;
  margin-left: 15px;
  background-image: url(../../assets/Images/admin/email.svg);
}

.icon-email-active {
  width: 14px !important;
  height: 14px !important;
  margin-left: 15px;
  background-image: url(../../assets/Images/admin/email_active.svg);
}

.icon-premium {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/premium.svg);
}

.icon-premium-active {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/premium_active.svg);
}

.icon-more-option {
  width: 18px !important;
  height: 18px !important;
  margin-top: 7px;
  background-image: url(../../assets/Images/admin/more_option.svg);
}

.icon-search {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/search.svg);
}

.icon-filter {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/filter.svg);
}

.icon-prev {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/prev.svg);
}

.icon-next {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/next.svg);
}

.icon-close {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/x.svg);
}

.icon-no-result {
  width: 135px !important;
  height: 26px !important;
  background-image: url(../../assets/Images/admin/no_result.svg);
}

.icon-no-result-word {
  width: 24px !important;
  height: 24px !important;
  background-image: url(../../assets/Images/admin/words.svg);
}

.icon-no-result-search {
  width: 24px !important;
  height: 24px !important;
  background-image: url(../../assets/Images/admin/search.svg);
}

.subscription-active {
  font-size: 12px;
  color: #43b581;
}

.subscription-none-active {
  font-size: 12px;
  color: #f8f9fa;
}

.icon-cancelled {
  width: 53px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/Cancelled.svg);
}

.icon-user-type {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/x-octagon.svg);
}

.icon-has-images {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/image_icon.svg);
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(93deg) brightness(150%)
    contrast(140%);
}

.icon-genders {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/genders.svg);
}

.icon-verification {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/yes.svg);
}

.icon-premium-filter {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/premium.svg);
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(93deg) brightness(150%)
    contrast(140%);
}

.icon-user-banned {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/user-minus.svg);
}

.icon-trash {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/trash-2.svg);
}

.icon-apply-filter {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/user-check.svg);
}

.icon-view-profile {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/user.svg);
}

.icon-cancel-premium {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/cancel_premium.svg);
}

.icon-make-premium {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/premium.svg);
}

.icon-chat-history {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/message_check.svg);
}

.icon-ban-user {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/user_remove.svg);
}

.icon-unban-user {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/unban.svg);
}

.icon-deactivate-user {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/user-x.svg);
}

.icon-reactivate-user {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/reactivate.svg);
}

.icon-arrow-right {
  width: 12px !important;
  height: 12px !important;
  background-image: url(../../assets/Images/admin/arrow_right.svg);
}

.icon-arrow-down {
  width: 12px !important;
  height: 12px !important;
  background-image: url(../../assets/Images/admin/arrow_down.svg);
}

.icon-account-username {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/user-gray.svg);
}

.icon-account-email {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/mail.svg);
}

.icon-account-phone {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/smartphone.svg);
}

.icon-account-phone-status {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/phone-outgoing.svg);
}

.icon-account-email-status {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/mail_status.svg);
}

.icon-account-signup {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/calendar_signup.svg);
}

.icon-account-bot {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/bot.svg);
}

.icon-account-last {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/calendar_last.svg);
}

.icon-account-deactivated {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/alert.svg);
}

.icon-account-ip {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/terminal.svg);
}

.icon-user-home {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/adminSidebar/home.svg);
}

.icon-user-ethnicity {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/account_users.svg);
}

.icon-user-gender {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/account_genders.svg);
}

.icon-user-age {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/age.svg);
}

.icon-user-bodytype {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/bodytype.svg);
}

.icon-user-height {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/height.svg);
}

.icon-subscription-purchase-date {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/calendar.svg);
}

.icon-subscription-purchase-time {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/clock.svg);
}

.icon-subscription-cycle {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/refresh.svg);
}

.icon-subscription-credit {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/credit.svg);
}

.icon-pending {
  width: 6px !important;
  height: 6px !important;
  margin-top: 7px;
  background-image: url(../../assets/Images/admin/pending.svg);
}

.icon-approved {
  width: 6px !important;
  height: 6px !important;
  margin-top: 7px;
  filter: invert(54%) sepia(48%) saturate(479%) hue-rotate(100deg)
    brightness(103%) contrast(89%);
  background-image: url(../../assets/Images/admin/pending.svg);
}

.icon-rejected {
  width: 6px !important;
  height: 6px !important;
  margin-top: 7px;
  filter: invert(23%) sepia(83%) saturate(2735%) hue-rotate(349deg)
    brightness(89%) contrast(82%);
  background-image: url(../../assets/Images/admin/pending.svg);
}

.icon-edit {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/edit.svg);
}

.icon-image-refresh {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/image_refresh.svg);
}

.icon-report-check {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/Vector.svg);
}

.icon-moderate-images {
  background-image: url(../../assets/Images/admin/image.svg);
}

.icon-moderate-chats {
  background-image: url(../../assets/Images/adminSidebar/message_active.svg);
}

.icon-large-close {
  width: 24px !important;
  height: 24px !important;
  background-image: url(../../assets/Images/admin/x.svg);
}

.icon-coversation {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/conversation.svg);
}

.icon-settings {
  width: 14px !important;
  height: 14px !important;
  background-image: url(../../assets/Images/admin/white_settings.svg);
}

.icon-info {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/info.svg);
}

.icon-refresh {
  width: 18px !important;
  height: 18px !important;
  background-image: url(../../assets/Images/admin/refresh-cw.svg);
}

.refresh-image {
  position: fixed;
  top: 20px;
  right: 440px;
  width: 45px;
  height: 45px;
  padding: 14px;
  background: #212529;
  cursor: pointer;
}

.card-header-title {
  font-size: 22px;
  padding-left: 17px;
}

.none-user-image {
  width: 18px;
  height: 18px;
  margin-left: 7px;
  margin-top: 5px;
}

.none-image-container {
  width: 32px;
  height: 32px;
  background: #2c2e34;
  border-radius: 2px;
}

.table-user-info {
  padding-top: 3px;
}

.usertable-card {
  width: 100%;
  height: 100%;
  border-radius: 0 !important;
}

.user-info-row {
  cursor: pointer;
}

.user-info-row:hover {
  border-left: 4px solid #e08b27;
}

.input-icons {
  position: fixed;
  right: 290px;
}

.inner-icon {
  padding-left: 33px;
  padding-top: 17px;
  font-weight: 400;
}

.input-field {
  width: 246px;
  height: 45px;
  padding-left: 50px;
  font-size: 14px;
  background: #202225;
  border: none;
  background-image: url(../../assets/Images/admin/search.svg);
  background-repeat: no-repeat;
  background-position: 10px;
  color: white;
  &:focus {
    border: 1px solid #6b6b6b;
    outline: none;
  }
}

.input-icons i {
  position: absolute;
}

.usertable-sortby {
  width: 154px !important;
  height: 45px !important;
  border: none !important;
  background: #202225;
  font-size: 14px !important;
  background-image: url(../../assets/Images/admin/sortby.svg);
  background-repeat: no-repeat;
  background-position: 9px 9px;
  background-size: 32px 12px;
  &:hover {
    outline: none !important;
    border: none !important;
  }
  &:focus {
    outline: none !important;
    border: 1px solid #6b6b6b;
  }
}

.usertable-pagination-current-page {
  width: 49px !important;
  height: 24px !important;
  border: none !important;
  font-size: 12px !important;
  background: #202225;
  &:hover {
    background: #121212;
    outline: none !important;
    border: none !important;
  }
  &:focus {
    background: #121212;
    outline: none !important;
    border: 1px solid #6b6b6b;
  }
}

.usertable-sortby .react-dropdown-select-content {
  padding-top: 15px;
  padding-left: 4px;
}

.usertable-pagination-current-page .react-dropdown-select-content {
  padding-left: 4px;
}

.react-dropdown-select-input {
  display: none;
}

.usertable-sortby .css-1yc4zyy-DropdownHandleComponent {
  margin-top: 11px !important;
}

.usertable-sortby .css-1aarvou-DropdownHandleComponent {
  margin-bottom: 1px;
}

.usertable-pagination-current-page .css-1yc4zyy-DropdownHandleComponent {
  margin-top: 11px !important;
}

.usertable-pagination-current-page .css-1aarvou-DropdownHandleComponent {
  margin-bottom: 1px;
}

.react-dropdown-select-dropdown {
  background: #202225 !important;
  border: none !important;
}

.react-dropdown-select-item-selected {
  background: #4f545c !important;
}

.react-dropdown-select-item {
  border: none !important;
  &:hover {
    background: #4f545c !important;
  }
}

.usertable-filter-container {
  position: fixed;
  right: 20px;
  width: 45px;
  height: 45px;
  background: #202225;
  padding: 15px;
  cursor: pointer;
}

.usertable-close-filter-container {
  position: fixed;
  right: 20px;
  width: 45px;
  height: 45px;
  background: #37393f;
  padding: 15px;
  cursor: pointer;
}

.usertable-all-amounts,
.usertable-pagination {
  display: flex;
  padding-top: 3px;
  margin: 0 10px;
}

.arrow-panel {
  width: 36px;
  height: 36px;
  background: #202225;
  padding: 9px;
  margin: 0 10px;
  &:focus,
  &:hover {
    background: #121212;
  }
}

.pages-title {
  padding-top: 5px;
  margin: 0 30px;
}

.usertable-filter-panel {
  right: 20px;
  position: fixed;
  width: 890px;
  height: 372px;
  background: #37393f;
  top: 65px;
  z-index: 1;
}

.select-sortby {
  position: fixed;
  right: 100px;
}

.filter-panel-location {
  background: #37393f;
  margin-left: 5%;
  width: 90%;
  top: 24px;
}

.filter-panel-location .form-control {
  background: #202225;
  border: none;
  border-radius: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
}

.filter-panel-location .sf-dropdown-card {
  width: 90%;
  margin-left: 5%;
}

.location-form-label {
  font-size: 16px;
  margin-left: 40px;
  margin-top: 24px;
}

.filter-panel-divider {
  margin: 24px 85px;
  width: 81%;
}

.filter-panel-options {
  margin-left: 85px;
  display: flex;
}

.filter input[type='checkbox'] {
  position: relative;
  cursor: pointer;
}

.filter input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: #202225;
}

.filter input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.filter-checkbox-label {
  padding-left: 8px;
}

.filter-checkbox-label-disabled {
  padding-left: 8px;
  opacity: 0.6;
}

.filter-checkbox {
  margin-top: 15px;
}

.filter-one-option {
  margin-right: 25px;
}

.filter-panel-footer {
  display: flex;
  margin-top: 30px;
  margin-left: 440px;
}

.footer-option-title {
  margin-left: 14px;
}

.filter-option {
  margin-right: 65px;
  width: 154px;
  height: 40px;
  padding-top: 8px;
  padding-left: 12px;
  &:focus,
  &:hover {
    background: #4f545c;
  }
}

.userinfo-drawer {
  width: 288px;
  height: 100%;
  position: absolute;
  background: #37393f;
  top: 0;
  right: 0px;
  z-index: 2;
}

.userinfo-drawer-header {
  width: 100%;
  height: 68px;
  color: white;
  background: #4f545c;
  padding: 14px;
}

.img-userinfo {
  width: 40px;
  height: 40px;
  border-radius: 2px;
}

.userinfo-drawer-header {
  display: flex;
}

.userinfo-top {
  margin-left: 14px;
}

.userinfo-username {
  font-weight: bold;
  font-size: 10px;
  line-height: 15px;
  color: #f8f9fa;
  margin-bottom: 2px;
}

.userinfo-riskscore {
  display: flex;
}

.userinfo-riskscore-title {
  margin-right: 10px;
}

.userinfo-drawer-footer {
  width: 100%;
  height: 216px;
  background: #4f545c;
  bottom: 0;
  position: absolute;
  padding-top: 11px;
  padding-left: 24px;
}

.profile-more-option {
  margin-left: 14px;
  cursor: pointer;
}

.userinfo-footer-option {
  //margin-bottom: 10px;
  font-size: 14px;
}

.userinfo-drawer-body {
  overflow: auto;
  height: 70%;
}

.userinfo-drawer-body-option {
  position: relative;
  width: 100%;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  padding: 14px;
}

.userinfo-drawer-body-option-active {
  position: relative;
  width: 100%;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  padding: 14px;
  background: #202225;
}

.userinfo-body {
  margin: 0;
}

.userinfo-arrow {
  position: absolute;
  right: 10px;
}

.accountinfo-detail {
  background: #202225;
}

.accountinfo-detail-option {
  display: flex;
  padding-left: 14px;
  height: 46px;
  padding-top: 7px;
}

.detail-option-container {
  width: 32px;
  height: 32px;
  background: #31343d;
  padding-top: 5px;
  padding-left: 9px;
}

.account-detail-subtitle {
  font-size: 12px;
  margin-bottom: 0;
}

.account-detail-description {
  margin-left: 8px;
}

.account-detail-content {
  font-size: 10px;
}

::-webkit-scrollbar {
  display: none;
}

.userinfo-table {
  width: 120px;
  height: 140px;
  margin-right: 15px;
  margin-left: 1px;
  margin-bottom: 10px;
}

.image-panel {
  display: flex;
  flex-flow: row wrap;
  padding-top: 20px;
  padding-left: 14px;
}

.image-delete-option {
  cursor: pointer;
  color: #963737;
  font-weight: 700;
}

.bio-option-header {
  display: flex;
}

.pending-box {
  display: flex;
  margin-left: 145px;
  width: 62px;
  height: 20px;
  background: #37393f;
  border-radius: 2px;
  padding-left: 4px;
}

.no-pending-box {
  display: flex;
  margin-left: 134px;
  width: 72px;
  height: 20px;
  background: #37393f;
  border-radius: 2px;
  padding-left: 4px;
}

.pending-box-bio {
  display: flex;
  margin-left: 175px;
  width: 62px;
  height: 20px;
  background: #37393f;
  border-radius: 2px;
  padding-left: 4px;
}

.no-pending-box-bio {
  display: flex;
  margin-left: 165px;
  width: 72px;
  height: 20px;
  background: #37393f;
  border-radius: 2px;
  padding-left: 4px;
}

.bio-option {
  padding-left: 14px;
  padding-top: 20px;
}

.bio-option-title {
  font-size: 12px;
  font-weight: 500;
}

.pending-style {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 500;
}

.bio-option-content {
  position: relative;
  background: #4f545c;
  width: 259px;
  height: 100px;
  font-size: 12px;
  padding: 8px;
  overflow: auto;
}

.bio-option-footer {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.bio-option-button {
  text-align: center;
  width: 92px;
  height: 24px;
  background: #36393f;
  margin-bottom: 10px;
  font-size: 12px;
  padding-top: 3px;
  color: #d1372d;
  cursor: pointer;
}

.bio-option-button-approve {
  text-align: center;
  width: 92px;
  height: 24px;
  background: #36393f;
  margin-bottom: 10px;
  margin-left: 74px;
  font-size: 12px;
  padding-top: 3px;
  color: #43b581;
  cursor: pointer;
}

.bio-option-edit {
  width: 18px;
  height: 18px;
  right: 18px;
  top: 80px;
  padding-left: 3px;
  background: #393d45;
  position: absolute;
}

.bio-wrapper {
  position: relative;
}

.bio-content {
  width: 259px;
  height: 100px;
  background: #4f545c;
  padding: 5px;
  color: white;
}

.userinfo-body-option-title {
  cursor: pointer;
}

.profile-drawer-close {
  position: fixed;
  right: 12px;
  cursor: pointer;
}

.search-option-dropdown {
  width: 246px;
  position: fixed;
  right: 290px;
  top: 67px;
  background: #202225;
  cursor: pointer;
}

.search-option {
  padding: 5px 10px;
  &:hover {
    background: #4f545c;
  }
}

.loading-spinner {
  position: fixed;
  margin-left: 240px;
}

.usertable-no-result {
  position: fixed;
  top: 40%;
  left: 45%;
}

.no-result-description {
  margin-top: 40px;
}

.no-result-option {
  position: relative;
  margin-bottom: 20px;
}

.no-result-option-sub-description {
  position: fixed;
  margin-left: 15px;
}

.image-search-option-dropdown {
  width: 246px;
  position: fixed;
  right: 290px;
  top: 67px;
  background: #202225;
  z-index: 3;
  cursor: pointer;
}

.image-filters-panel {
  right: 70px;
  position: fixed;
  width: 386px;
  height: 305px;
  background: #37393f;
  top: 65px;
  cursor: pointer;
  z-index: 3;
}

.img-card {
  width: 170px;
  height: 215px;
  cursor: pointer;
}

.image-card-footer {
  background: #212529;
  display: flex;
  padding: 12px;
}

.image-card-decline {
  font-size: 20px;
  width: 31px;
  height: 30px;
  margin-left: 7px;
  color: #963737;
  background: white;
  padding-left: 8px;
  border-radius: 26px;
  cursor: pointer;
}

.image-card-approve {
  font-size: 20px;
  width: 31px;
  height: 30px;
  margin-left: 68px;
  color: #2d8cd1;
  background: white;
  padding-left: 5px;
  border-radius: 26px;
  cursor: pointer;
}

.image-body-panel {
  display: flex;
  flex-flow: row wrap;
  overflow: auto;
  height: 100%;
}

.image-panel-body {
  padding-left: 60px;
}

.personal-image-panel {
  width: 1208px;
  height: 690px;
  position: absolute;
  top: 13%;
  left: 10%;
  background: #37393f;
  z-index: 2;
}

.personal-header {
  position: relative;
  display: flex;
  height: 68px;
  width: 100%;
  padding: 14px;
  border-bottom: 1px solid #303239;
}

.personal-image-header {
  height: 40px;
  width: 40px;
}

.personal-image-username {
  margin: 5px 14px;
}

.personal-images-close {
  display: flex;
  position: absolute;
  right: 30px;
  top: 25px;
  cursor: pointer;
}

.personal-close {
  margin-top: -4px;
  margin-right: 10px;
  cursor: pointer;
}

.personal-panel-body {
  display: flex;
}

.personal-panel-image-show {
  height: 622px;
  width: 585px;
  background: #2e3036;
}

.personal-image-shower {
  width: 338px;
  height: 505px;
  margin-left: 124px;
  margin-top: 22px;
}

.personal-image-action {
  display: flex;
  padding-left: 124px;
  padding-top: 30px;
}

.personal-image-action-option {
  display: flex;
  margin-right: 40px;
  cursor: pointer;
}

.personal-image-action-title {
  margin-left: 20px;
}

.personal-image-list {
  width: 623px;
  padding-left: 138px;
  padding-top: 22px;
  overflow: auto;
  height: 605px;
}

.cardtable-current-page {
  margin-right: 80px;
}

.chat-filter-panel-divider {
  width: 88%;
  margin-top: 10px;
}

.chat-filter-panel-options {
  margin-left: 24px;
  display: flex;
}

.chat-filter-panel-footer {
  display: flex;
  margin-top: 10px;
  margin-left: 14px;
}

.chat-filter-option {
  width: 154px;
  height: 40px;
  padding-top: 8px;
  padding-left: 12px;
  &:focus,
  &:hover {
    background: #4f545c;
  }
}

.chat-history-header {
  position: relative;
  display: flex;
  width: 100%;
  height: 68px;
  color: white;
  background: #4f545c;
  padding: 14px;
  border-bottom: 1px solid #303239;
}

.chat-search-option-dropdown {
  width: 246px;
  position: fixed;
  right: 290px;
  top: 67px;
  background: #202225;
  z-index: 3;
  cursor: pointer;
}

.conversation-loading-spinner {
  position: fixed;
  margin-left: 200px;
  margin-top: 25px;
}

.chat-card-footer-admin {
  position: fixed;
  bottom: 0px;
}

.chat-card-admin {
  width: 540px;
  height: 229px;
  margin: 15px 20px;
  background: #42464d;
}

.chat-panel {
  display: flex;
  flex-flow: row wrap;
  padding-left: 20px;
}

.chat-card-sender-image {
  position: absolute;
  left: 14px;
  top: 13px;
  width: 32px;
  height: 32px;
  z-index: 1;
}

.chat-card-receiver-image {
  position: absolute;
  left: 32px;
  top: 23px;
  width: 32px;
  height: 32px;
}

.chat-card-header-admin {
  position: relative;
  display: flex;
  background: #42464d !important;
}

.chat-card-userinfo {
  position: absolute;
  left: 78px;
  top: 13px;
  font-size: 14px;
}

.chat-card-from-to {
  margin-bottom: 0;
}

.chat-card-body-admin {
  background: #323438;
  width: 95%;
  height: 91px;
  margin-left: 14px;
  margin-top: 69px;
  padding: 14px;
  overflow: auto;
}

.chatcard-footer {
  display: flex;
  padding-left: 10%;
  padding-top: 15px;
}

.chat-card-button-title {
  margin-left: 15px;
  cursor: pointer;
}

.chat-card-button {
  position: relative;
  width: 140px;
  height: 44px;
  margin-right: 30px;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #6b6b6b;
  }
}

.chat-panel-body {
  overflow: auto;
}

.participant-info {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  padding: 3px;
  cursor: pointer;
  &:hover {
    background: #c4c4c4;
  }
}

.refresh-chat {
  position: fixed;
  top: 20px;
  right: 440px;
  width: 45px;
  height: 45px;
  padding: 14px;
  background: #212529;
  cursor: pointer;
}

.chat-filters-panel {
  right: 70px;
  position: fixed;
  width: 400px;
  height: 305px;
  background: #37393f;
  top: 65px;
  cursor: pointer;
  z-index: 3;
}

.filter-header {
  padding-top: 26px;
  padding-left: 24px;
}

.chat-view-panel {
  position: fixed;
  top: 223px;
  left: 30%;
  width: 603px;
  height: 454px;
  background: #202225;
  z-index: 3;
}

.chat-view-header {
  display: flex;
  width: 100%;
  height: 68px;
  background: #37393f;
}

.chat-view-title {
  margin-top: 23px;
  margin-left: 14px;
  font-size: 18px;
  font-weight: 500;
}

.chat-view-close {
  margin-left: 381px;
  margin-top: 23px;
  cursor: pointer;
}

.chat-info-title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;
}

.conv-users-info {
  display: flex;
  width: 603px;
  height: 68px;
  border-bottom: 1px solid #303239;
}

.conv-user-one {
  width: 50%;
  height: 100%;
  border-right: 1px solid #303239;
}

.conv-user-one {
  width: 50%;
  height: 100%;
}

.conv-body {
  width: 100%;
  height: 65%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.chat-message-history {
  width: 100%;
  height: 65%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.message-sent {
  width: fit-content;
  padding: 15px;
  max-width: 293px;
  background: #40444b;
  border-radius: 2px 2px 2px 0px;
}

.message-received {
  width: fit-content;
  padding: 15px;
  float: right;
  max-width: 293px;
  background: #40444b;
  border-radius: 2px 2px 2px 0px;
}

.message-card-admin {
  background: #202225;
  border: none;
  margin-top: 0 !important;
}

.message-card-body {
  padding: 0 !important;
}

.message-card-title {
  padding-top: 15px;
}

.chat-history {
  display: flex;
  width: 1216px;
  height: 690px;
  background: #202225;
  position: fixed;
  left: 15%;
  top: 105px;
  z-index: 2;
}

.chat-message-queue {
  width: 368px;
  height: 100%;
  background: #37393f;
}

.chat-message-history {
  width: 603px;
  height: 100%;
}

.chat-partner-profile {
  width: 245px;
  height: 100%;
  background: #191b1e;
}

.chat-history-close {
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.fetch-loading-spinner {
  margin-left: 20px;
}

.queue-option {
  cursor: pointer;
  position: relative;
  display: flex;
  padding: 10px 14px;
  margin: 5px;
  &:hover {
    background: #42464d;
  }
}

.queue-info {
  margin-left: 14px;
}

.queue-option-name {
  font-size: 14px;
  margin-bottom: 0;
}

.queue-option-message {
  font-size: 12px;
  margin-top: -25px;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.queue-date-part {
  font-size: 10px;
  position: absolute;
  right: 10px;
}

.chat-queue-body {
  height: 610px;
  overflow: auto;
}

.chat-message-loading-spinner {
  margin-left: 10px;
}

.chat-user-info {
  display: flex;
  padding: 10px;
}

.chat-card-badge {
  position: absolute;
  top: 15px;
  right: 40px;
}

.chats-current-page {
  width: 36px;
  height: 36px;
  padding-left: 13px;
  padding-top: 6px;
  background: #202225;
}

.chat-card-footer-wrap {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.personal-image-badge {
  position: fixed;
  margin-top: 25px;
  margin-left: 20px;
}

.personal-non-image {
  padding: 330px;
  font-size: 30px;
  font-weight: 500;
}

.refresh-reports {
  position: fixed;
  top: 20px;
  right: 570px;
  width: 45px;
  height: 45px;
  padding: 14px;
  background: #212529;
  cursor: pointer;
}

.report-table-card {
  width: 100%;
  height: 100%;
  border-radius: 0 !important;
}

.report-option {
  position: absolute;
  right: 360px;
  top: -31px;
}
// toggle button
.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 200px;
  height: 56px;
  bottom: -42px;
  box-sizing: border-box;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button-report {
  position: relative;
  top: 50%;
  width: 282px;
  height: 45px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button-report.b2 {
  border-radius: 2px;
}

.checkbox-report {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #202225;
  transition: 0.3s ease all;
  z-index: 1;
}

#button-10 .knobs:before,
#button-10 .knobs:after,
#button-10 .knobs span {
  position: absolute;
  top: 4px;
  width: 135px;
  height: 38px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  border-radius: 2px;
  transition: 0.3s ease all;
}

#button-10 .knobs:before {
  content: '';
  left: 4px;
  background-color: #b54343;
}

#button-10 .knobs:after {
  content: 'Bugs';
  right: 4px;
  color: #ffffff;
}

#button-10 .knobs span {
  display: inline-block;
  left: 4px;
  color: #ffffff;
  z-index: 1;
}

#button-10 .checkbox-report:checked + .knobs span {
  color: #ffffff;
}

#button-10 .checkbox-report:checked + .knobs:before {
  left: 142px;
  background-color: #b54343;
}

#button-10 .checkbox-report:checked + .knobs:after {
  color: #ffffff;
}

#button-10 .checkbox-report:checked ~ .layer {
  background-color: #202225;
}

.select-sortby-report {
  position: fixed;
  right: 25px;
  top: 20px;
  z-index: 3;
}

.reporttable-sortby {
  width: 240px !important;
  height: 45px !important;
  border: none !important;
  background: #202225;
  font-size: 14px !important;
  background-image: url(../../assets/Images/admin/Reason.svg);
  background-repeat: no-repeat;
  background-position: 9px 9px;
  background-size: 32px 12px;
  &:hover {
    outline: none !important;
    border: none !important;
  }
  &:focus {
    outline: none !important;
    border: 1px solid #6b6b6b;
  }
}

.reporttable-sortby .css-1yc4zyy-DropdownHandleComponent {
  margin-top: 11px !important;
}

.reporttable-sortby .css-1aarvou-DropdownHandleComponent {
  margin-bottom: 1px;
}

.reporttable-sortby .react-dropdown-select-content {
  padding-top: 15px;
  padding-left: 4px;
}

.report-reason {
  font-size: 14px;
  background: #202225;
  height: 35px;
  padding: 7px;
  border-left: 1px solid #b54343;
  overflow: auto;
}

.report-sender-info {
  display: flex;
  font-size: 14px;
}

.report-card-body {
  padding-top: 0.5rem !important;
}

.report-sender-name {
  margin-top: 3px !important;
  margin-left: 10px !important;
  margin-bottom: 0 !important;
}

.reporttable-pagination {
  position: absolute;
  right: 95px;
  display: flex;
  padding-top: 3px;
  margin: 0 10px;
}

.report-page {
  width: 36px;
  height: 36px;
  padding: 5px 12px;
  background: #202225;
}

.bug-panel {
  display: flex;
  flex-flow: row wrap;
  overflow: auto;
  height: 100%;
}

.report-bug-card {
  width: 570px;
  height: 311px;
  background: #42464d;
  margin: 10px;
}

.bug-card-header {
  display: flex;
  padding: 14px;
}

.bug-card-image {
  width: 40px;
  height: 40px;
}

.bug-card-username {
  margin-left: 10px;
  font-size: 14px;
}

.bug-card-category {
  width: 182px;
  height: 28px;
  font-size: 12px;
  margin-top: 14px;
  margin-left: 14px;
  background: #202225;
  padding-top: 6px;
  padding-left: 10px;
  border-left: 1px solid #b54343;
}

.bug-card-screens {
  display: flex;
  margin-top: 10px;
  padding-left: 14px;
  padding-top: 5px;
  font-size: 14px;
}

.bug-card-descrption {
  margin-top: 5px;
  margin-left: 14px;
  width: 542px;
  height: 91px;
  padding: 14px;
  font-size: 14px;
  background: #313439;
  overflow: auto;
}

.bug-card-action {
  display: flex;
  width: 109px;
  height: 40px;
  margin-top: 15px;
  margin-left: 446px;
  font-size: 14px;
  background: #4f545c;
  padding-top: 10px;
  padding-left: 15px;
}

.bug-card-date {
  margin-top: -35px;
  font-size: 12px;
  margin-left: 10px;
  margin-bottom: -33px;
}

#dropdown-report-admin::after {
  display: none;
}

#dropdown-report-admin {
  background: none;
  border: none;
  position: absolute;
  right: 15px;
}

.admin-report-menu {
  position: absolute;
  width: 120px;
  min-width: 120px;
  top: -7px !important;
  left: -55px !important;
  background: #4f545c;
  padding: 0;
}

.admin-report-option {
  color: white;
  font-size: 12px;
  font-weight: 500;
  height: 35px;
  padding-top: 7px;
  padding-left: 14px;
  &:hover,
  &:focus {
    color: white;
    background: #202225;
  }
}

.admin-reprt-divider {
  margin: 0;
  border-color: #303239;
}

.report-dropdown-action {
  height: 10px;
  top: -3px;
  position: relative;
}

.report-action-title {
  font-size: 14px;
  margin-left: 15px;
}

.report-users-info {
  position: absolute;
  width: 288px;
  height: 100%;
  right: 0;
  top: 0;
  background: #37393f;
  z-index: 3;
}

.report-users-info-title {
  display: flex;
  height: 68px;
  padding-left: 14px;
  padding-top: 25px;
  font-size: 18px;
  font-weight: 500;
  background: #4f545c;
}

.report-close {
  margin-left: 200px;
  cursor: pointer;
}

.report-userinfo-drawer-header {
  display: flex;
  width: 100%;
  height: 68px;
  color: white;
  padding: 14px;
}

.image-tab-ban-action {
  position: absolute;
  left: 200px;
}
// end toggle button
// ! Payments Card
