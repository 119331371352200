.desktop-messages-component {
  width: 100%;
  background-color: #f7f7f7;
  min-height: 100%;
}

.desktop-messages-container {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 3rem;
  display: flex;
  min-height: 100%;
}

.desktop-messages-sidebar {
  width: 25%;
}

.desktop-messages-inbox {
  width: 75%;
  padding-left: 3.5rem;
  min-height: 100%;
}

// ! Messages Sidebar

.messages-sidebar-tab {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  background-color: #f7f7f7;
  border-radius: 5px;
  cursor: pointer;
}

.messages-sidebar-tab-active {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: rgb(231, 231, 231) 0px -2px 0px 0px inset;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-tab-sent:hover .sidebar-tab-sent-title {
  color: #da0e2b !important;
  text-decoration: none !important;
}
.sidebar-tab-matches:hover .sidebar-tab-matches-title {
  color: #da0e2b !important;
  text-decoration: none !important;
}
.sidebar-tab-archived:hover .sidebar-tab-archived-title {
  color: #da0e2b !important;
  text-decoration: none !important;
}

.messages-sidebar-tab-active:hover .messages-sidebar-tab-title-active {
  color: #da0e2b !important;
}

.messages-sidebar-tab-title {
  margin: 0;
  color: #464646;
  font-size: 1.2rem;
  text-decoration: underline;
  font-weight: 500;
}

.messages-sidebar-tab-title-active {
  margin: 0;
  color: #464646;
  font-size: 1.2rem;
  font-weight: 700;
}

.messages-sidebar-inbox-counter-badge {
  background-color: rgb(153, 153, 153) !important;
}

.messages-sidebar-inbox-counter-badge-active {
  background-color: #da0e2b !important;
}
