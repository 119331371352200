.form-wrapper-contactpage {
  padding: 1rem;
  width: 100%;
  max-width: 400px;
}

.contact-form-titlewrap {
  padding-top: 3rem;
}

.contact-form-title {
  text-align: center;
  font-size: 2.5rem;
}

.contact-form-subtitle {
  text-align: center;
}

.selectinvalid {
  border: 1px solid red !important;
}

.contact-form-section {
  padding-bottom: 2rem;
  background-color: #fff;
}

.contact-form-container {
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.contact-form-card {
  max-width: 600px !important;
}

.cf-title {
  text-align: center;
  font-weight: 700 !important;
  color: #090a20;
  font-size: 3rem !important;
  padding-top: 1.5rem;
}

.cf-contact-span {
  border-bottom: 4px solid #c44b4b;
  padding-bottom: 0.5rem;
}

.contact-form-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.contact-form-submit-btn {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
}

@media (min-width: 767px) {
  .contact-form-section {
    padding-top: 2rem;
    background-color: #021e30;
  }
}
