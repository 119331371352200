.dashboard-component {
  width: 100%;
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.dashboard-sidebar {
  width: 84px;
  height: 100%;
  background-color: #202225;
}

.dashboard-main-area {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f9f9f9;
}
