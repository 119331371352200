.no-photos-warning {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  background: transparent;
}

.no-photos-innerwrap {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-photos-warning-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(215, 215, 215, 0.7);
  backdrop-filter: blur(6px);
}

.no-photos-warning-content {
  width: 95%;
  margin: auto;
  z-index: 2;
  max-width: 500px;
}

.no-photos-content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 3px;
}

.no-photos-content-warning {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
}

.no-photos-warning-icon {
  text-align: center;
  font-size: 30px;
  color: #c44b4b;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.no-photos-warning-title {
  font-size: 20px;
  text-align: center;
  color: #000;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.no-photos-warning-subtitle {
  font-size: 14px;
  color: #021e30;
  font-weight: 500;
  text-align: center;
}

.no-photos-warning-footer {
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}

.no-photos-footer-button {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border-radius: 3px;
  background-color: #c44b4b;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.no-photos-close-text {
  text-decoration: underline;
  font-size: 16px;
  color: #021e30;
  margin-right: 5px;
  margin-bottom: 3px;
  font-weight: 500;
}

.no-photos-title {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.no-photos-list {
  margin-left: 0;
  padding-left: 1rem;
}

.no-photos-list-item {
  font-size: 14px;
  font-weight: 500;
  color: #021e30;
  margin-bottom: 1.5rem;
  list-style: none;
  margin-left: 0;
}

.no-photos-list-item::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #c44b4b; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.no-photos-subtitle {
  margin-top: 3rem;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
