.desktop-user-profile-component {
  width: 100%;
  min-height: 100%;
  background-color: rgb(241, 241, 241);
}

.desktop-user-profile-container {
  max-width: 1300px;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 3rem;
  display: flex;
}

.desktop-user-profile-sidebar {
  width: 25%;
  height: 100%;
}

.desktop-user-profile-content {
  width: 75%;
  padding-left: 3.5rem;
}

// ! Desktop User Profile Siderbar

.user-profile-sidebar-tab {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  background-color: #f7f7f7;
  border-radius: 5px;
  cursor: pointer;
}

.user-profile-sidebar-tab-active {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: rgb(231, 231, 231) 0px -2px 0px 0px inset;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-tab-profileInformation:hover .sidebar-tab-profileInformation-title {
  color: #da0e2b !important;
  text-decoration: none !important;
}
.sidebar-tab-photos:hover .sidebar-tab-photos-title {
  color: #da0e2b !important;
  text-decoration: none !important;
}
.sidebar-tab-descriptions:hover .sidebar-tab-descriptions-title {
  color: #da0e2b !important;
  text-decoration: none !important;
}

.sidebar-tab-verifications:hover .sidebar-tab-verifications-title {
  color: #da0e2b !important;
  text-decoration: none !important;
}

.sidebar-tab-settings:hover .sidebar-tab-settings-title {
  color: #da0e2b !important;
  text-decoration: none !important;
}

.user-profile-sidebar-tab-active:hover .user-profile-sidebar-tab-title-active {
  color: #da0e2b !important;
}

.user-profile-sidebar-tab-title {
  margin: 0;
  color: #464646;
  font-size: 1.2rem;
  text-decoration: underline;
  font-weight: 500;
}

.user-profile-sidebar-tab-title-active {
  margin: 0;
  color: #464646;
  font-size: 1.2rem;
  font-weight: 700;
}

.desktop-user-profile-sidebar-link {
  text-decoration: none !important;
}
