.desktop-v2-profile-user-profile-box {
  width: 32%;
  border: 1px solid #eeeeee;
  background-color: #fcfcfc;
  border-radius: 5px;
  cursor: pointer;
}

.desktop-v2-profile-social-item-connect {
  width: 100%;
  max-width: 165px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: #c44b4b;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  padding-left: 1.5rem;
  font-size: 14px;
  text-decoration: underline;
  margin-bottom: 0.75rem;
  cursor: pointer;
}

.desktop-v2-section-title-wrapper {
  display: flex;
  align-items: center;
}

.desktop-v2-section-edit-button {
  margin-bottom: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.desktop-v2-user-info-form {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.desktop-v2-user-info-form-location {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.desktop-v2-user-info-form-input {
  width: 90%;
  background-color: #fcfcfc;
  border-radius: 3px;
  border: 1px solid #f4f4f4;
  margin-right: 5px;
  resize: none;
  padding: 1rem;
  color: #021e30;
  font-weight: 500;
  height: 50px;
}

.desktop-v2-user-info-save-button {
  height: 50px;
  width: 10%;
  border-radius: 5px;
  background-color: #c44b4b;
  color: #fff;
  border: 0 !important;
  font-weight: 500;
}

.desktop-location-dropdown-wrapper {
  position: absolute;
  z-index: 2;
  height: 155px;
  top: 60px;
  width: 100%;
  overflow-y: scroll;
}

.desktop-v2-about-row-user-action-wrapper {
  display: flex;
  align-items: center;
}

.no-margin {
  margin: 0 !important;
}

.desktop-v2-user-info-edit-button {
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  cursor: pointer;
}

.desktop-v2-user-info-select {
  width: 120px;
  background-color: #fcfcfc;
  border-radius: 3px;
  border: 1px solid #f4f4f4;
  font-size: 16px;
  color: #021e30;
}

// BENEFITS SECTION

.desktop-v2-user-profile-benefits-section {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.desktop-edit-profile-v2-benefit-option-active {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #021e30;
  color: #eee;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  cursor: pointer;
  margin-right: 7px;
  margin-bottom: 7px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.desktop-edit-profile-v2-benefit-option {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #eeeeee;
  color: #021e30;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  cursor: pointer;
  margin-right: 7px;
  margin-bottom: 7px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.desktop-v2-edit-profile-benefits-minus-icon {
  margin-left: 0.5rem;
  margin-top: 2px;
  color: #f4f4f4;
  font-size: 14px;
}

.desktop-v2-edit-profile-benefits-add-icon {
  margin-left: 0.5rem;
  margin-top: 2px;
  color: #021e30;
  font-size: 14px;
}
