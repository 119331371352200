.home-page-footer-nav-wrapper {
  padding-top: 4rem;
}

.home-page-footer-nav {
  display: flex;
  justify-content: space-between;
}

.home-page-footer-link {
  color: #000;
  font-weight: 500;
  text-decoration: none;
  font-size: 0.875rem;
  margin-right: 1rem;
}

.home-page-footer-social-icon {
  margin-left: 1.5rem;
}

.home-page-sub-footer-wrapper {
  border-top: 2px solid #7f7f7f;
  margin-top: 1.5rem;
  padding-top: 1rem;
}

.home-page-sub-footer-trademark {
  font-size: 0.75rem;
  font-weight: 400;
  color: #000;
  line-height: 1.4;
  font-family: Modern Era, sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

.home-page-sub-footer-links-wrapper {
}

.home-page-sub-footer-link {
  color: #000;
  font-weight: 400;
  font-size: 0.75rem;
  margin-right: 1rem;
}

.home-page-desktop-footer-nav-wrapper {
  display: none;
}

.home-page-desktop-sub-footer-social-wrapper {
  display: none;
}

.home-page-sub-footer {
  padding: 1.5rem;
}

@media (min-width: 767px) {
  .home-page-sub-footer-wrapper {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding-bottom: 4rem;
  }

  .home-page-sub-footer-innerwrap {
    display: flex;
    align-items: center;
  }

  .home-page-desktop-sub-footer-social-wrapper {
    display: flex;
  }

  .home-page-sub-footer-links-wrapper {
    padding-left: 3rem;
  }

  .home-page-sub-footer-link {
    font-size: 1rem;
    font-weight: 400;
  }

  .home-page-sub-footer-trademark {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 3px;
  }
}
