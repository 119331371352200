.desktop-messages-view-component {
  width: 100%;
  min-height: 100%;
}

.messages-view-header {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.messages-view-title {
  margin: 0;
  font-size: 1.6rem;
  color: #464646;
}

.messages-view-actions-wrap {
  display: flex;
}

.messages-view-action {
  padding-left: 1.5rem;
  display: flex;
}

.inbox-unread-checkbox-form {
  cursor: pointer !important;
}

.messages-view-action-title {
  color: #464646;
  font-size: 1rem;
  margin: 0;
  font-weight: 500;
}

.messages-view-cta {
  padding: 1.5rem;
  background-color: #090a20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.messages-view-cta-innerwrap {
  display: flex;
  align-items: center;
}

.messages-view-cta-icon {
  color: #fff;
  font-size: 2rem;
  margin-right: 25px;
}

.messages-view-cta-text {
  margin: 0;
  color: #fff;
}

.messages-view-cta-link {
  color: #c696e6;
  text-decoration: none;
  font-weight: 700;
  margin-left: 2px;
}

.messages-view-cta-button-icon {
  color: #fff !important;
}

// ! Messages Container

.messages-view-messages-container {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.messages-container-loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15rem;
  height: 500px;
}

.messages-container-innerwrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.messages-bottom-indicator {
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
