.plan-select-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100% !important;
}

.plan-card {
  flex-basis: 33% !important;
  border-color: #da0e2b !important;
}

.plan-card-inactive {
  flex-basis: 33% !important;
  border: 0 !important;
}

.plan-card-header {
  padding: 0 !important;
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #da0e2b !important;
}

.plan-card-header-inactive {
  padding: 0 !important;
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  border: 0 !important;
}

.plan-card-title {
  font-size: 0.7rem !important;
  margin: 0 !important;
  text-align: center;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.plan-card-title-inactive {
  font-size: 0.7rem !important;
  margin: 0 !important;
  text-align: center;
  color: #a1a1a1 !important;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.plan-card-body {
  padding: 0 !important;
  padding-top: 0.2rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdf7f8 !important;
}

.plan-card-body-inactive {
  padding: 0 !important;
  padding-top: 0.2rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff !important;
}

.plan-card-months {
  font-size: 1.8rem;
  color: #444444;
  margin: 0;
}

.plan-card-months-inactive {
  font-size: 1.8rem;
  color: #a1a1a1;
  margin: 0;
}

.plan-card-subtitle {
  color: #444444;
  font-weight: 700;
  margin-bottom: 0;
}

.plan-card-subtitle-inactive {
  color: #444444;
  font-weight: 500;
  margin-bottom: 0;
}

.plan-card-monthly-price {
  color: #8e8b8c;
  margin-bottom: 0;
  font-weight: 500;
}

.plan-card-discount {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 800;
  color: #da0e2b;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.plan-card-footer {
  border: 0 !important;
  background-color: #fdf7f8 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-card-footer-inactive {
  border: 0 !important;
  background-color: #fff !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-card-month-total-price {
  color: #444444;
  font-weight: 900;
  font-size: 1rem;
  text-align: center;
}

.plan-card-month-total-price-inactive {
  color: #8f8f8f;
  font-weight: 900;
  font-size: 1rem;
  text-align: center;
}
