.desktop-v2-about-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.desktop-v2-section-title {
  font-size: 18px;
  font-weight: 700;
  color: #021e30;
  margin-bottom: 1rem;
}

.desktop-v2-about-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.desktop-v2-about-row-section {
  display: flex;
  align-items: center;
}

.desktop-v2-about-row-icon {
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
}

.desktop-v2-about-row-icon-wrapper {
  width: 30px;
}

.desktop-v2-about-row-title {
  font-size: 16px;
  font-weight: 500;
  color: #021e30;
  margin: 0;
}

.desktop-v2-about-row-content {
  font-size: 16px;
  font-weight: 400;
  color: #021e30;
}

.desktop-v2-section-paragraph {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #021e30;
}
