.match-queue-container {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 1rem;
}

.match-queue-innerwrap {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}

.match-queue-innerwrap-loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.match-queue-placeholder {
  background-color: #f2f2f2;
  border-radius: 50%;
  margin-right: 7px;
}

.match-queue-image-subtitle {
  margin: 0;
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.match-queue-link {
  color: #000;
}

.match-queue-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.6rem;
  position: relative;
  width: 100px;
  height: 133px;
}

.match-image {
  width: 100px;
  height: 130px;
  border-radius: 5px;
  object-fit: cover;
}

.reached-arrow {
  padding-bottom: 25px;
}

.match-queue-unread-message-icon-wrapper {
  position: absolute;
  bottom: -15px;
  right: 30px;
  z-index: 2;
}

.match-indicator-v2 {
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: -3px;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  background-color: #d95454;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 4px;
}
