.profile-component-container {
  width: 100%;
  background-color: rgb(241, 241, 241);
  position: fixed;
  top: 0;
  z-index: 400;
}

.profile-component-card {
  height: 100% !important;
  width: 100%;
  background-color: #fff !important;
  z-index: 400;
  padding-bottom: 7rem !important;
}

.profile-component-card-header {
  background-color: #fff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.profile-card-header-username-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-card-header-image {
  height: 50px;
  width: 50px;
  border-radius: 60px;
  object-fit: cover;
}

.profile-card-header-title {
  margin: 0;
  text-align: center;
  font-size: 1rem;
  margin-top: 2px;
  color: #878787;
}

.profile-card-close-btn {
  color: #000 !important;
  font-size: 1rem !important;
}

.profile-back-icon {
  font-size: 1.7rem;
  color: #c7c7c7;
}

.profile-card-safety-icon {
  color: #488af2;
  font-size: 1.7rem;
}

.profile-component-card-body {
  height: 100% !important;
  padding: 0rem !important;
  overflow-y: scroll !important;
  padding-bottom: 7rem !important;
}

.profile-card-images-wrapper {
  width: 95%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: auto;
  margin-top: 0.5rem;
}

.profile-card-main-image-wrapper {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 0.5rem;
  position: relative;
}

.profile-card-user-info-section {
  display: flex;
  align-items: center;
}

.profile-card-user-info-text {
  color: #fff;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
}

.profile-card-main-image-user-info-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.profile-card-main-image {
  width: 100%;
  height: 450px;
  border-radius: 15px;
  object-fit: cover;
}

.profile-card-image {
}

.profile-card-main-image-username {
  font-weight: 800;
  font-size: 2rem;
  color: #fff;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.profile-card-online-wrapper {
  display: flex;
  align-items: center;
}

.profile-card-online-icon {
  color: #20c997;
  font-size: 0.8rem;
  margin-right: 5px;
}

.profile-card-online-text {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.profile-card-verified-icon {
  color: #007bff;
  font-size: 1.5rem;
  --fa-primary-color: #fff;
  --fa-secondary-color: #007bff;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  margin-left: 5px;
}

.liked-stamp-image-profile {
  position: absolute;
  top: 30px;
  left: 15px;
  z-index: 2;
  width: 30%;
  max-width: 250px;
  transform: rotate(-20deg);
}
