.user-info-v2 {
  width: 100%;
  padding-top: 1.5rem;
}

.user-info-v2-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.user-info-v2-item {
  display: flex;
  align-items: center;
  padding-left: 0.35rem;
  padding-right: 0.35rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  background-color: #eeeeee;
  border-radius: 3px;
  font-size: 14px;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  color: #021e30;
}

.user-info-v2-item-active {
  display: flex;
  align-items: center;
  padding-left: 0.35rem;
  padding-right: 0.35rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  background-color: #021e30;
  border-radius: 3px;
  font-size: 14px;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  color: #fff;
}

.user-info-v2-icon {
  margin-right: 5px;
  margin-top: 3px;
  color: #6b6b6b;
}

.user-info-v2-icon-active {
  margin-right: 5px;
  margin-top: 3px;
  color: #fff;
}

.user-info-v2-item-text {
}

.about-v2-content {
  margin: 0;
  font-size: 16px;
  color: #021e30;
}

.benefits-content-wrapper-v2 {
  padding-top: 1.25rem;
}

.benefits-content-wrapper-nopadding {
  padding-top: 0;
}
