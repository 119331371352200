.filters-v2-component {
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.filters-v2-header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 !important;
  height: 8% !important;
  width: 100%;
  background-color: #eeeeee;
  z-index: 50;
}

.filters-v2-content {
  height: 92% !important;
  overflow-y: scroll !important;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 50;
}

.filters-v2-header-title {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}

.filters-v2-header-close-button {
  background-color: transparent;
  border: 0;
  font-size: 18px;
  color: #000;
}

.filters-v2-section {
  width: 100%;
}

.filters-v2-section-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filters-v2-section-row-icon {
  color: #c44b4b;
  font-size: 16px;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters-v2-section-row-title {
  color: #000;
  font-size: 16px;
  width: 50%;
  padding-left: 0.5rem;
  white-space: nowrap;
}

.filters-v2-section-row-content {
  color: #021e30;
  font-size: 14px;
  width: 40%;
  overflow-x: scroll;
  text-align: right;
  padding-right: 1rem;
}

.filters-v2-section-row-content-plus {
  color: #021e30;
  font-size: 14px;
  width: 45%;
  overflow-x: scroll;
  text-align: right;
  padding-right: 1rem;
}

.filters-v2-section-row-content-counter {
  color: #021e30;
  font-size: 14px;
  width: 40%;
  overflow-x: scroll;
  text-align: right;
  padding-right: 1rem;
  display: flex;
  justify-content: flex-end;
}

.filters-v2-section-row-action {
  width: 5%;
  color: #b5b5b5;
}

.filters-v2-select-text {
  color: #c44b4b;
  font-size: 16px;
}

.filters-sb-plus-icon {
  width: 18px;
}
