.report-content-wrap {
  width: 100%;
  max-width: 700px;
  padding-top: 8rem;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

.report-title {
  color: #DA0E2B;
}

.report-description {
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 18px;
  color: #495057;
}

.submit-report {
  width: 100%;
  height: 50px;
  background: #DA0E2B !important;
  border: none;
  border-radius: 7px;
  font-size: 22px;
}

.submit-report-disabled {
  opacity: 50%;
  width: 100%;
  height: 50px;
  background: #DA0E2B !important;
  border: none;
  border-radius: 7px;
  font-size: 22px;
}

.redirect-to-messages {
  position: absolute;
  padding-top: 20px;
  padding-left: 15px;
}

.close {
  font-size: 25px;
}