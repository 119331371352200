.mobile-nav {
  height: 8%;
  width: 100%;
  background-color: #021e30;
  display: flex;
  z-index: 3;
}

.mobile-nav-section {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.mobile-nav-icon {
  height: 26px;
  width: 26px;
  object-fit: cover;
}

.mobile-nav-icon-active {
  color: #c44b4b;
  font-size: 1.5rem;
  position: relative;
}

.mobile-nav-icon-wrapper {
  position: relative;
}
