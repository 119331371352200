.filter-slider-component {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: transparent !important;
}

.filter-slider-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  filter: blur(5);
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1rem;
}

.filter-close-bar {
  width: 20%;
  height: 10px;
  background-color: #2c2b2b;
  border-radius: 5px;
}

.filter-slider-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  z-index: 6;
  padding: 1rem;
}

.filter-slider-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
}

.filter-slider-reset-button {
  color: #c44b4b;
  text-decoration: underline;
  font-size: 16px;
  padding-bottom: 10px;
}

.filter-slider-title-wrap {
  display: flex;
  align-items: center;
}

.filter-slider-title {
  font-size: 18px;
  font-weight: 700;
  color: #021e30;
  margin: 0;
}

.filter-slider-wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.filter-save-button-wrapper {
  padding-top: 1rem;
}

.filter-save-button {
  width: 100%;
  font-weight: 700;
  background-color: #c44b4b;
  border-color: #c44b4b;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 16px !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.filter-sort-icon {
  margin-right: 6px;
  color: #c44b4b;
  font-size: 18px;
}

// ! Filter Forms

.filter-slider-form {
  overflow-y: scroll;
}

.filter-slider-form-group {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0;
  border-bottom: 1px solid #eeeeee;
}

.filter-slider-form-check {
}

.filter-slider-form-check .form-check-input {
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  height: 24px;
  width: 24px;
}

.filter-slider-form-check .form-check-label {
  margin-left: 20px;
  margin-top: 4px;
  color: #021e30;
  font-size: 16px;
}

// Location Styles

.filter-slider-wrapper-location {
  overflow-y: scroll;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.filter-slider-form-location {
}

.filter-slider-form-group-location {
  padding: 0 !important;
}

.location-title {
  font-size: 14px;
  color: #021e30;
  margin-top: 1rem;
}

.location-v2-input-wrapper {
}

.location-v2-input {
  width: 100%;
  background-color: #fcfcfc !important;
  color: #021e30 !important;
  font-size: 16px !important;
  border-radius: 2px;
  border: 1px solid #c44b4b;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  height: 50px !important;
}

.location-v2-input:active {
  width: 100%;
  background-color: #fcfcfc !important;
  color: #021e30 !important;
  font-size: 14px !important;
  border-radius: 2px;
  border: 1px solid #c44b4b;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  height: 50px !important;
}

.location-v2-dropdown-wrapper {
  border-top: 1px solid #eeeeee;
  height: 235px;
  margin-top: 15px;
  overflow-y: scroll;
  padding-top: 0.5rem;
}

.location-suggestion-item-active {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  background-color: #c44b4b;
  color: #fff;
  font-size: 14px;
}

.location-suggestion-item {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  color: #021e30;
  font-size: 14px;
}

.location-suggestion-icon {
  margin-right: 7px;
  margin-top: 2px;
}

.location-map-wrapper {
  height: 235px;
  width: 100%;
  border: 1px solid #eeeeee;
  margin-top: 15px;
  border-radius: 5px;
}
