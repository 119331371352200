.desktop-v2-sidebar {
  width: 25%;
  height: 100%;
  padding: 1rem;
  position: relative;
}

.desktop-v2-sidebar-logo-container {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 1rem;
}

.desktop-v2-sidebar-logo {
  width: 188px;
  object-fit: contain;
}

.desktop-v2-sidebar-nav {
  padding: 1rem;
}

.desktop-v2-sidebar-nav-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.desktop-v2-sidebar-nav-title {
  font-size: 16px;
  font-weight: 500;
  color: #b5b5b5;
}

.desktop-v2-sidebar-nav-title-active {
  font-size: 16px;
  font-weight: 500;
  color: #021e30;
}

.desktop-v2-sidebar-nav-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
  margin-right: 1.5rem;
}

.desktop-sidebar-spotlight {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.desktop-sidebar-spotlight-title {
  font-size: 16px;
  color: #021e30;
  font-weight: 700;
  margin-bottom: 2rem;
}
