.landing-page-container-v2 {
  width: 100%;
}

.landing-page-header-v2 {
  width: 100%;
  background-color: #021e30;
  display: flex;
  justify-content: center;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.landing-page-header-logo-v2 {
  width: 50%;
}

.landing-page-hero-v2 {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #021e30;
}

.landing-page-hero-title-v2 {
  font-family: Modern Era, sans-serif;
  color: #fff;
}
