.filter-slider-v2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 55;
}

.filter-slider-v2-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(215, 215, 215, 0.7);
  backdrop-filter: blur(5px);
}

.filter-slider-v2-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: transparent;
}

.filter-slider-v2-close-bar {
  background: transparent;
  margin: auto;
  width: 25%;
  height: 4px;
  border-radius: 3px;
  background-color: #2c2b2b;
  margin-bottom: 1rem;
}

.filter-slider-v2-pop {
  background-color: #fff;
  padding: 1.25rem;
  padding-top: 1.5rem;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
}

.filter-slider-v2-header {
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-slider-v2-header-section {
  display: flex;
  align-items: center;
}

.sort-v2-heart-icon {
  color: #c44b4b;
  font-size: 18px;
  margin-right: 7px;
}

.filter-slider-v2-title {
  font-size: 18px;
  font-weight: 700;
  color: #021e30;
}

.filter-slider-v2-reset-text {
  color: #c44b4b;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
}

.filter-slider-v2-form-wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-height: 500px;
  overflow-y: scroll;
}

.filter-slider-v2-form-group {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eeeeee;
}

.form-group-no-border {
  border: 0;
}

.filter-slider-v2-form-label {
  display: block;
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: 500;
  color: #021e30;
}

.filter-slider-v2-form-label-checked {
  display: block;
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: 700;
  color: #021e30;
}

.filter-slider-v2-form-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.filter-slider-v2-form-label-checked input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.filter-slider-v2-input-radio {
}

.filter-slider-v2-form-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  border-radius: 50%;
}

.filter-slider-v2-form-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.filter-slider-v2-form-label input:checked ~ .filter-slider-v2-form-checkmark {
  background-color: #fff;
  border: 1px solid #c44b4b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-slider-v2-form-label
  input:checked
  ~ .filter-slider-v2-form-checkmark:after {
  display: block;
}

.filter-slider-v2-form-label .filter-slider-v2-form-checkmark:after {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #c44b4b;
}

.filter-slider-v2-form-label-checked
  input:checked
  ~ .filter-slider-v2-form-checkmark {
  background-color: #fff;
  border: 1px solid #c44b4b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-slider-v2-form-label-checked
  input:checked
  ~ .filter-slider-v2-form-checkmark:after {
  display: block;
}

.filter-slider-v2-form-label-checked .filter-slider-v2-form-checkmark:after {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #c44b4b;
}

.filter-slider-v2-footer {
  width: 100%;
  padding-top: 1rem;
}

.filter-slider-v2-apply-btn {
  width: 100%;
  background-color: #c44b4b;
  border-radius: 3px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.filter-v2-icon {
  height: 18px;
  width: 18px;
  object-fit: cover;
  margin-right: 6px;
}

.filter-slider-v2-slider-wrapper {
  padding-bottom: 3rem;
  padding-top: 1rem;
}

.filter-slider-v2-slider-title {
  font-size: 14px;
  text-align: center;
  color: #021e30;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

// ! Slider V2 Styles

.filter-slider-v2-slider-wrapper .MuiSlider-root .MuiSlider-rail {
  height: 4px;
  border-radius: 3px;
  background-color: #eeeeee;
  opacity: 1;
}

.filter-slider-v2-slider-wrapper .MuiSlider-root .MuiSlider-track {
  height: 4px;
  border-radius: 3px;
  background-color: #c44b4b;
  margin-right: 1.5rem;
}

.filter-slider-v2-slider-wrapper .MuiSlider-root .MuiSlider-thumb {
  width: 52px;
  height: 37px;
  background-color: #c44b4b;
  border-radius: 3px;
  margin-top: -18px;
}

.filter-slider-v2-slider-wrapper
  .MuiSlider-root
  .MuiSlider-thumb
  .MuiSlider-valueLabel {
  position: absolute;
  transform: none;
  top: 0;
  font-size: 16px;
  font-weight: 500;
}

.filter-slider-v2-slider-wrapper
  .MuiSlider-root
  .MuiSlider-thumb
  .MuiSlider-valueLabel
  span {
  transform: none;
  background-color: transparent;
  width: 52px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
