.about-hero-section {
  padding-top: 8rem;
  padding-bottom: 4rem;
  background-color: #6d6f8c;
}

.about-hero-title {
  text-align: center;
  color: #efefef;
  font-weight: 600;
}

.about-hero-subtitle {
  text-align: center;
  font-weight: 300;
  color: #efefef;
}

.about-description-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #090a20;
}

.about-description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100% !important;
}

.about-description-card {
  border: 0 !important;
  height: 100% !important;
  background-color: transparent !important;
  padding: 1rem !important;
}
.about-description-image-wrap {
  display: none;
}

.about-description-content {
  text-align: center;
  color: #efefef;
  font-size: 1.2rem;
  font-weight: 400;
}

.about-description-title {
  text-align: center;
  font-size: 1.7rem !important;
  color: #efefef;
  font-weight: 600 !important;
}
.about-stats-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.as-card-left {
  border: 0 !important;

  flex-basis: 100%;
  padding-top: 2rem;
}

.as-card-right {
  border: 0 !important;
  flex-basis: 100%;
  padding-top: 2rem;
}

.about-stats-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.as-card {
  border: 0 !important;
  flex-basis: 100%;
  padding-top: 2rem;
}

.as-card-title {
  text-align: center;
  font-size: 3.5rem !important;
  color: #da0e2b;
  font-weight: 700 !important;
}

.as-card-subtitle {
  text-align: center;

  padding-top: 0 !important;
}

.our-mission-section {
  padding-bottom: 4rem;
}

.our-mission-card {
  padding-top: 4rem !important;
  border: 0 !important;
  border-top: 2px solid #dbdbdb !important;
}

.our-mission-card-title {
  text-align: center;
}

.our-mission-card-body {
  text-align: center;
  display: flex;
  justify-content: center;
}

.om-card-body-innerwrap {
  max-width: 700px;
}

@media (min-width: 767px) {
  .about-stats-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .as-card {
    border: 0 !important;
    flex-basis: 33%;
  }

  .as-card-left {
    border: 0 !important;
    border-right: 2px solid #dbdbdb !important;
    flex-basis: 33%;
  }

  .as-card-right {
    border: 0 !important;
    border-left: 2px solid #dbdbdb !important;
    flex-basis: 33%;
  }

  .about-description-container {
    display: flex;
    flex-direction: row;
  }

  .about-description-image-wrap {
    display: block;
    width: 100%;
  }

  .about-description-card-img {
    display: none;
  }
}
