.no-messages-card {
  border: 0 !important;
}

.no-messages-card-footer {
  border: 0 !important;
  background-color: #fff !important;
}

.no-messages-title {
  text-align: center;
  color: #424242;
  font-weight: 600;
  margin-bottom: 1rem;
}

.no-messages-subtitle {
  text-align: center;
  color: #999999;
  font-weight: 500;
}
