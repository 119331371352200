.user-profile-v2 {
  height: 92%;
  width: 100%;
}

.user-profile-v2-content {
  height: 92%;
  width: 100%;
  overflow-y: scroll;
  padding-left: 1rem;
  padding-right: 1rem;
}

.user-profile-user-preview-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.user-profile-user-info-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.user-profile-view-profile-section {
  width: 100%;
}

.user-profile-view-profile-button {
  width: 100%;
  background-color: #021e30;
  border-color: #021e30;
  font-size: 1.2rem !important;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-weight: 500;
}

// ! User Preview Section

.user-profile-user-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.user-profile-v2-image {
  width: 45%;
  max-height: 200px;
  border-radius: 5px;
}

.user-profile-user-preview-info {
}

.user-profile-username-age {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 0.15rem;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-profile-age {
  font-size: 1.4rem;
  font-weight: 400;
}

.user-profile-address {
  text-align: center;
}

// ! User Info Section

.user-profile-v2-info-box {
  width: 32%;
  background-color: #eeeeee;
}
