.profile-v2 {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
}

.profile-v2-header {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
}

.profile-v2-close-button {
  background-color: transparent !important;
  border: none !important;
}

.profile-v2-close-icon {
  font-size: 1.5rem;
  color: #fff;
}

.profile-v2-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  background-color: #fff;
  padding-bottom: 7rem;
}

.profile-v2-main-image-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.profile-v2-main-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profile-v2-main-image-user-info-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 1.5rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(255, 255, 255, 0) 50%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.profile-v2-info-innerwrap {
  padding-bottom: 1.5rem;
}

.profile-v2-info-top {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-v2-username {
  font-weight: 800;
  font-size: 1.5rem;
  color: #fff;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  text-align: center;
}

.profile-v2-age {
  font-size: 1.4rem;
  font-weight: 500;
  margin-left: 5px;
}

.profile-v2-info-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile-v2-user-info {
  color: #fff;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
}

// ! Actions Section

.profile-v2-actions-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  padding-bottom: 2rem;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  z-index: 6;
}

.profile-v2-actions-button {
  background-color: rgba(255, 255, 255, 0.85);
  background-color: #fff;
  border-color: #fff;
  border-radius: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 2px 6px 0px rgba(112, 125, 134, 0.65);
  height: 65px;
  width: 65px;
  transition: height 15ms, width 15ms;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.profile-v2-actions-button-liked {
  background-color: rgba(255, 255, 255, 0.85);
  background-color: #c44b4b;
  border-color: #c44b4b;
  border-radius: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 2px 6px 0px rgba(112, 125, 134, 0.65);
  height: 65px;
  width: 65px;
  transition: height 15ms, width 15ms;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.profile-v2-send-message-icon {
  color: #021e30;
  font-size: 32px;
}

.profile-v2-heart-icon {
  color: #c44b4b;
  font-size: 32px;
}

.profile-v2-heart-icon-liked {
  color: #fff;
  font-size: 32px;
}

// ! Profile Footer Section

.profile-v2-container-innerwrap {
  width: 100%;
  background-color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
}
