.homegrid-container {
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-wrap: wrap;
}

.homegrid-seemore-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.homegrid-seemore-link {
  width: 70%;
  max-width: 350px;
}

.homegrid-seemore-btn {
  width: 100% !important;
}

.homegrid-title {
  text-align: center;
  color: #090a20;
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 25px;
}

.homegrid-genderpick-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.homegrid-genderpick-btn-active {
  border-bottom: 3px solid #da0e2b !important;
  border-radius: 0 !important;
  color: #da0e2b !important;
  text-decoration: none !important;
}

.homegrid-genderpick-btn {
  border-radius: 0 !important;
  color: #474747 !important;
  text-decoration: none !important;
}

.hg-title-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.homegrid-subtitle {
  font-size: 1.4rem;
  text-align: center;
  font-weight: 500;
  color: #090a20;
}

.one-million-hg {
  border-bottom: 3px solid #da0e2b;
}

@media (min-width: 767px) {
  .homegrid-genderpick-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
