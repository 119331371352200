.desktop-profile-settings-footer {
  padding-top: 5rem;
  width: 100%;
}

.desktop-profile-settings-footer-innerwrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.desktop-profile-settings-footer-list {
  list-style: none;
  color: #464646;
}

.desktop-profile-settings-footer-link {
  color: #464646 !important;
}

.desktop-profile-settings-footer-link:hover {
  color: #c44b4b !important;
}
