.profile-v2-footer-section {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.profile-v2-footer-section-actions {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 7.5rem;
}

.profile-v2-footer-header {
  width: 100%;
  background-color: #d95454;
  border-color: #d95454 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  max-width: 300px;
  margin: auto;
  border-radius: 5px;
}

.profile-v2-footer-header-innerwrap {
  display: flex;
  align-items: center;
}

.profile-footer-gift-icon {
  margin-right: 7px;
  color: #fff;
  font-size: 1.2rem;
  margin-top: 3px;
}

.profile-v2-footer-title {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
}

.profile-v2-footer-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-v2-abuse-title {
  text-align: center;
  font-size: 16px;

  color: #021e30;
}

.profile-v2-footer-actions {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}

.profile-v2-footer-action-button {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  background-color: #cac2c2 !important;
  border-color: #cac2c2 !important;
  color: rgba(0, 0, 0, 0.5);
}
