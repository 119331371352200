.desktop-filter-slider {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.desktop-filter-slider-back-button {
  font-size: 14px;
  color: #021e30;
  font-weight: 400;
  cursor: pointer;
}

.desktop-filter-slider-back-text {
  margin-left: 10px;
}

.desktop-filter-slider-header {
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop-filter-slider-header-section {
  display: flex;
  align-items: center;
}

.desktop-v2-filters-slider-icon {
  color: #c44b4b;
  margin-right: 3px;
}

.desktop-v2-filters-slider-title {
  font-size: 18 px;
  font-weight: 700;
  color: #021e30;
}

.desktop-filter-slider-reset-button {
  text-decoration: underline;
  color: #c44b4b;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.desktop-filter-slider-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.desktop-filter-slider-apply-button {
  border-radius: 5px;
  height: 48px;
  width: 100%;
  text-align: center;
  color: #fff;
  background-color: #c44b4b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

// Location

.desktop-location-title {
  text-align: center;
  font-size: 14px;
  margin-top: 1.5rem;
}

.desktop-filter-slider-content {
  flex: 1;
  overflow-y: scroll;
}

.desktop-filter-slider-innerwrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
