.desktop-grid-component-sb {
  width: 100%;
  display: flex;
}

.desktop-search-filters-sidebar {
  width: 25%;
  padding-right: 2.5rem;
  padding-bottom: 5rem;
}

.desktop-search-results-container {
  width: 75%;
}

.desktop-search-results-loading-container {
  width: 100%;
  min-height: 500px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
