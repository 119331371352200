.login-v2-component {
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-v2-header {
}

.login-v2-back-button {
  color: #b5b5b5;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.login-v2-back-icon {
  margin-right: 1rem;
  font-size: 16px;
}

.login-v2-title-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.login-v2-pre-title {
  color: #021e30;
  font-size: 18px;
  font-weight: 700;
}

.login-v2-title {
  color: #c44b4b;
  font-size: 28px;
  font-weight: 700;
}

.login-v2-content {
  flex-grow: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.login-v2-input-subtitle {
  font-size: 12px;
  color: #b5b5b5;
}

.login-v2-subtitle {
  color: #021e30;
  font-size: 16px;
}

.login-v2-number-span {
  color: #c44b4b;
}

.login-v2-footer {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  width: 100%;
}

.login-v2-footer-button {
  width: 100%;
  border-radius: 3px;
  background-color: #c44b4b;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
  cursor: pointer;
}

// Signup screens

.login-v2-gender-button {
  width: 49%;
  border-radius: 5px;
  cursor: pointer;
}
.login-v2-gender-button-active {
  width: 49%;
  border-radius: 5px;
  border: 1px solid #c44b4b;
  cursor: pointer;
}

.login-v2-signup-icon {
  height: 64px;
  width: 64px;
}

.login-v2-gender-button-content {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  background-image: url('../../../assets/Images/maleSignupButton.png');
  background-size: cover;
  height: 125px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}

.login-v2-gender-button-content-female {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  background-image: url('../../../assets/Images/femaleSignupButton.png');
  background-size: cover;
  height: 125px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}

.login-v2-gender-button-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  color: #021e30;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  cursor: pointer;
}

.login-v2-gender-button-footer-active {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border: 1px solid #c44b4b;
  background-color: #c44b4b;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  cursor: pointer;
}

.login-v2-birthdate {
  width: 100%;
  background-color: #fcfcfc;
  border-color: #eeeeee;
  font-size: 16px;
  font-weight: 500;
  color: #021e30;
  height: 50px;
  padding-left: 0.5rem;
  border-radius: 5px;
}

.login-v2-address-input-wrapper {
  width: 100%;
  position: relative;
}

.login-v2-address-input {
  width: 100%;
  background-color: #fcfcfc;
  border-color: #eeeeee;
  font-size: 16px;
  font-weight: 500;
  color: #021e30;
  height: 50px;
  padding-left: 0.5rem;
}

// @media (min-width: 992px) {
//   .login-v2-component {
//     background-color: #021e30;
//     padding: 1rem;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//   }

//   .login-v2-header {
//     max-width: 600px;
//     background-color: #fff;
//     padding: 1rem;
//     margin: auto;
//     width: 100%;
//   }

//   .login-v2-content {
//     max-width: 600px;
//     background-color: #fff;
//     padding: 1rem;
//     margin: auto;
//     width: 100%;
//     flex: grow;
//   }
//   .login-v2-footer {
//     max-width: 600px;
//     background-color: #fff;
//     padding: 1rem;
//     margin: auto;
//     width: 100%;
//   }
// }

// .login-v2-error-container {
//   padding-top: 2rem;
// }
