.physical-details-form {
  padding-top: 2rem;
}

.physical-details-form-group {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 3px !important;
}

.physical-details-form-image {
  position: absolute;
  left: 5%;
  height: 18px;
  width: 18px;
  object-fit: cover;
}

.physical-details-select {
  height: 50px !important;
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  border-radius: 3px !important;
  padding-left: 13% !important;
  font-size: 16px !important;
  color: #b5b5b5;
  font-weight: 500 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.physical-details-select-active {
  height: 50px !important;
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  border-radius: 3px !important;
  padding-left: 13% !important;
  font-size: 16px !important;
  color: #000;
  font-weight: 500 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.physical-details-dropdown-icon {
  position: absolute;
  right: 5%;
  font-size: 16px;
  color: #000;
}
