.video-chat-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  z-index: 4;
}

.video-call-standby {
  position: relative;
  height: 100%;
  width: 100%;
}

.video-call-standby-innerwrap {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 7;
}

#video-panel video {
  position: fixed;
  height: 100%;
  z-index: 5;
}

.video-disconnect {
  position: absolute;
  bottom: 10px;
  margin-left: 35%;
  z-index: 5;
}

.video-call-request {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #20c997;
  font-size: 30px;
  font-weight: 500;
  color: white;
  text-align: center;
  padding-top: 30%;
  z-index: 4;
}

.video-call-buttons {
  margin: 55% auto;
}

.video-reject {
  margin-left: 90px;
}

.video-call-notification {
  width: 100%;
  height: 30%;
  background-color: #f3f3f7;
  position: absolute;
  top: 0;
  z-index: 3;
  padding-top: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid #2a2d43;
  border-top: 0;
}

.video-call-notification-bar-image {
  height: 75px;
  border-radius: 40px;
}

.video-caller-name {
  font-size: 20px;
  font-weight: 500;
}

.video-call-notification-buttons {
  margin-bottom: 30px;
}

.video-notification-reject {
  margin-left: 40px;
}

.video-call-standby-header {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  padding-top: 10%;
  z-index: 5 !important;
}

.video-call-standby-logo-image-wrapper {
  width: 15%;
}

.video-call-logo {
  object-fit: cover;
}

.video-call-standby-content {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15%;
  z-index: 5 !important;
}

.video-call-standby-user-image-wrapper {
  width: 35%;
  border-radius: 5px;
}

.video-call-standby-user-image {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.video-call-standby-text-wrapper {
  padding-top: 2rem;
}

.video-call-standby-username-text {
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.video-call-standby-subtitle-text {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.video-cancel {
  background-color: #c44b4b;
  border-color: #c44b4b;
  padding: 1rem;
  font-size: 18px;
}

.video-call-standby-image-background-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.video-call-standby-user-image-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(30px);
  object-fit: cover;
  z-index: 1 !important;
}

.video-call-standby-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}
