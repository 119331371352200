.search-grid-v2 {
  width: 100%;
  padding: 0.5rem;
  padding-top: 0;
}

.search-grid-v2-content-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.search-grid-v2-toggle-filters-button {
  background: transparent !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-grid-filters-icon {
  color: #000;
  font-size: 1.25rem;
}
