.new-password-section {
  padding-top: 7rem;
  padding-bottom: 15rem;
  background-color: #090a20;
}

.new-password-container {
  display: flex;
  justify-content: center;
}

.new-password-form-card {
  max-width: 600px !important;
}

.new-password-form-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.new-password-card-title {
  text-align: center;
  font-weight: 700 !important;
  color: #090a20;
  font-size: 3rem !important;
  padding-top: 1.5rem;
}

.new-password-title-span {
  border-bottom: 4px solid #da0e2b;
  padding-bottom: 0.5rem;
}

.new-password-form {
  padding: 1rem;
  width: 100%;
  max-width: 400px;
}

.new-password-form-label {
  font-weight: 400;
  color: #090a20;
}

.new-password-form-input {
  border-color: #090a20 !important;
  color: #090a20 !important;
}

.new-password-form-btn {
  width: 100% !important;
  background-color: #da0e2b !important;
  border-color: #da0e2b !important;
  margin-top: 1rem;
}
