.receipt-image-section {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.receipt-image-innerwrap {
  width: 50%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 3px;
}

.receipt-image-subtitle {
  text-align: center;
  color: #021030;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0;
}
