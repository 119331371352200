.settings-v2-component {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
}

.settings-v2-header {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 !important;
  height: 8% !important;
  position: relative;
  width: 100%;
}

.settings-v2-header-section-left {
  position: absolute;
  left: 0.5rem;
}

.settings-v2-header-section-middle {
}

.settings-v2-header-section-right {
  position: absolute;
  right: 0.5rem;
}

.settings-v2-header-return-button {
  background-color: transparent;
  border: 0;
  color: #000;
  font-size: 16px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.settings-v2-header-title {
  margin: 0;
  text-align: center;
  font-size: 18px;
}

.settings-v2-header-done-button {
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  font-size: 16px;
  color: #c44b4b;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

// ! Settings Content

.settings-v2-content {
  height: 92% !important;
  overflow-y: scroll !important;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
