.landing-page-container {
  width: 100%;
  background-color: #fff;
}

.landing-page-container-form {
  width: 100%;
  min-height: 100%;
  background-color: #021e30;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.landing-page-header {
  width: 100%;
  background-color: #021e30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  position: relative;
}

.landing-page-progress-bar {
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 0 !important;
  height: 10px !important;
  background-color: #021e30 !important;
}

.landing-page-progress-bar .progress-bar {
  background-color: #c44b4b !important;
}

.landing-page-logo {
  width: 35%;
  max-width: 150px;
  margin-top: 1rem;
}

.landing-page-step-card {
  margin-top: 1rem;
}

.landing-page-title {
  color: #fff;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.2;
  letter-spacing: -0.025;
  text-align: center;
  margin-top: 2.3rem;
}

.landing-page-subtitle {
  color: #fff;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0;
}

.landing-page-header-subtitle {
  color: #dfe3e8;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0;
}

.landing-page-step-header-title {
  color: #fff;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.2;
  letter-spacing: -0.025;
  text-align: center;
  margin-top: 2.3rem;
}

.landing-page-step-header-subtitle {
  color: #fff;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0;
}

// ! Landing Page Email Modal

.landing-page-email-modal-body {
  background-color: #021e30 !important;
}

.landing-page-modal-title {
  text-align: center;
  margin: 0;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.2;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #fff;
  border-bottom: 4px solid #c44b4b;
  padding-bottom: 0.5rem;
  width: 90%;
}

.landing-page-modal-subtitle {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 25px;
  color: #dbdbdb;
}

.landing-page-modal-free {
  color: #fff;
  font-weight: 600;
}

.landing-page-form {
  width: 100%;
  padding-top: 2rem;
}

.landing-page-form-modal {
  width: 100%;
}

.landing-page-form-group-modal {
  max-width: 400px;
  margin: auto;
}

.landing-page-submit-button-modal {
  width: 100%;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
  font-weight: 700 !important;
  margin-top: 7.5px;
  border-radius: 5px !important;
  border: 0 !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.landing-page-input {
  color: #212b35 !important;
  box-shadow: 0 0 0 1px #637381;
  border-radius: 5px !important;
  border: 0 !important;
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.landing-page-submit-button {
  width: 100%;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
  font-weight: 700 !important;
  margin-top: 7.5px;
  border-radius: 5px !important;
  border: 0 !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.landing-page-form-subtitle {
  text-align: center;
  margin-top: 2rem;
  line-height: 1.5;
  font-size: 0.9rem;
  color: #fff;
  margin-bottom: 0;
}

.landing-page-form-subtitle-popup {
  text-align: center;
  margin-top: 2rem;
  line-height: 1.5;
  font-size: 0.9rem;
  color: #dfe3e8;
  margin-bottom: 10px;
}

.landing-page-gif-wrapper {
  width: 100%;
  position: relative;
}

.landing-page-gif {
  object-fit: cover;
  height: 400px;
  width: 100%;
  z-index: 1;
}

.landing-page-gif-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}

.landing-page-gif-content-innerwrap {
  width: 70%;
  border: 2px solid #021e30;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  background-color: rgba(109, 111, 140, 0.5);
  border-radius: 5px;
  max-width: 400px;
}

.landing-page-gif-subtitle {
  text-align: center;
  color: #fff;
  margin: 0;
  margin-top: 0.2rem;
  font-size: 0.9rem;
  font-weight: 700;
}

.landing-page-gif-title {
  text-align: center;
  color: #fff;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 800;
}

.landing-page-down-icon {
  color: #fff;
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
}

.landing-page-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  background-color: #fff;
  width: 100%;
}

.landing-page-feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 2.5rem;
}

.landing-page-feature-icon {
  font-size: 2.4rem;
  color: #2a2d43;
  margin-bottom: 1rem;
}

.landing-page-feature-title {
  text-align: center;
  margin-bottom: 0.625rem;
  color: #212b35;
  line-height: 1.25;
  font-weight: 700;
  font-size: 1rem;
}

.landing-page-feature-description {
  text-align: center;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1rem;
  color: #637381;
  font-weight: 400;
}

.landing-page-seen-on {
  border-top: 1px solid #dfe3e8;
  border-bottom: 1px solid #dfe3e8;
  padding: 1.875rem;
  padding-bottom: 1rem;
  width: 100%;
}

.landing-page-seen-on-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.landing-page-seen-on-list-item {
  margin: 0 15px 30px 15px;
  width: 25%;
  flex: 1 0 25%;
}

.landing-page-seen-on-logo {
  width: 115px;
  height: 44px;
}

.landing-page-cta {
  padding: 1.5rem;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-page-cta-quote {
  margin-bottom: 1.5rem;
  line-height: 1.5;
  font-size: 1.25rem;
  color: #212b35;
  font-weight: 700;
  text-align: center;
}

.landing-page-quote-cite {
  color: #454f5b;
  margin-bottom: 1.4rem;
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.043rem;
  text-align: center;
  font-style: normal;
}

.landing-page-cta-button {
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
  font-weight: 700 !important;
  margin-top: 7.5px;
  border-radius: 5px !important;
  border: 0 !important;
  padding: 1rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  font-size: 1rem !important;
}

.landing-page-footer {
  padding-top: 4rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-page-footer-link {
  color: #5c6ac4;
  text-decoration: none;
}

// ! Step Forms

.landing-page-step-form {
  padding: 1.5rem;
  padding-top: 3rem;
  width: 100%;
  max-width: 600px;
}

.landing-page-step-form-title {
  text-align: center;
  color: #fff;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.landing-page-step-form-title-age {
  text-align: center;
  color: #fff;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.landing-select {
  width: 100%;
  border: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  position: relative;
  max-width: 600px;
  margin: auto;
  margin-bottom: 1rem;
  cursor: pointer;
}
.landing-select-active {
  width: 100%;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #021e30;
  font-weight: 600;
  font-size: 1.5rem;
  position: relative;
  max-width: 600px;
  margin: auto;
  margin-bottom: 1rem;
  cursor: pointer;
}

.landing-select:hover {
  background-color: #888;
}

.landing-select-check-icon {
  color: #021e30;
  position: absolute;
  left: 15px;
  font-size: 2rem;
}

.landing-page-form-label {
  color: #dfe3e8;
}

.landing-page-signup-submit-button {
  width: 100%;
  font-weight: 600 !important;
  background-color: #c44b4b !important;
  border-color: #c44b4b !important;
  margin-top: 1rem;
}

.landing-form-age-input {
  width: 200px !important;
  height: 90px !important;
  margin: auto;
  text-align: center;
  font-size: 1.5rem !important;
}

.landing-form-location-input {
  height: 60px !important;
}

.age-input-subtitle {
  text-align: center;
  color: #dfe3e8;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.landing-page-last-step-text {
  text-align: center;
  color: #dfe3e8;
  font-style: italic;
}

.landing-page-signup-button-icon {
  margin-left: 10px;
}

.landing-page-back-wrapper {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.landing-page-back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.landing-page-back-icon {
  color: #dfe3e8;
  font-size: 1.2rem;
  margin-right: 10px;
}

.landing-page-back-text {
  color: #dfe3e8;
  font-size: 1.2rem;
}

.banner-video-container-desktop {
  display: none;
}

.landing-page-alert {
  margin: auto;
  max-width: 600px;
}

@media (min-width: 1024px) {
  .landing-page-title {
    font-size: 3.75rem;
  }

  .landing-page-subtitle {
    font-size: 2.25rem;
  }
  .landing-page-form-group {
    display: flex;
    max-width: 600px;
    margin: auto;
    margin-top: 1.5rem;
  }

  .landing-page-submit-button {
    margin: 0;
    margin-left: 20px;
    width: 35% !important;
  }
  .landing-page-form-subtitle {
    max-width: 600px;
    margin: auto;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .landing-page-gif {
    display: none;
  }

  .banner-video-container-desktop {
    display: block;
  }

  .landing-page-features {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 1.5rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
    background-color: #fff;
    width: 100%;
    max-width: 1000px;
    margin: auto;
  }

  .landing-page-feature-description {
    width: 90%;
  }

  .landing-page-seen-on-wrapper {
    max-width: 600px;
    margin: auto;
  }

  .landing-page-seen-on-logo {
    width: 114px;
    height: 44px;
  }
  .landing-page-seen-on-list {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -30px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .landing-page-cta-quote {
    max-width: 600px;
    font-size: 1.8rem;
  }

  .landing-page-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 2rem;
    max-width: 1000px;
    margin: auto;
  }

  .landing-page-footer-link {
    margin-left: 10px;
    margin-right: 10px;
  }
}
