.search-user-card-v2 {
  flex-basis: 49%;
  width: 49%;
  margin-bottom: 0.25rem;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.search-user-card-v2-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 55%
  );
  z-index: 1;
}

.search-user-card-images-container {
  height: 100%;
  display: flex;
  position: relative;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.search-user-card-image-count-wrapper {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.25rem;
  font-size: 10px;
  color: #fff;
}

.image-count-camera-icon {
  margin-right: 4px;
  font-size: 11px;
}

.search-user-placeholder-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.search-user-card-v2-image {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.search-user-card-v2-image-multiple {
  height: 100% !important;
  width: 93%;
  object-fit: cover;
  margin-right: 0.25rem;
}

.search-user-card-v2-footer {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.search-user-card-v2-verified-wrapper {
  width: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-user-card-v2-verified-icon {
  color: #007bff;
}

.search-user-card-v2-user-info-wrapper-verified {
  width: 80%;
  padding-left: 5px;
}
.search-user-card-v2-user-info-wrapper-notverified {
  width: 92%;
  padding-left: 5px;
}

.search-user-card-v2-activity-wrapper {
  width: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-user-card-v2-user-info-content-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.search-user-card-v2-username {
  font-size: 0.9rem;
  font-weight: 600;
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}

.search-user-card-v2-age {
  font-size: 0.85rem;
  font-weight: 400;
  margin-left: 0.15rem;
  color: #fff;
}

.search-user-card-online-icon-offline {
  color: grey;
  font-size: 0.5rem;
}

.search-user-card-online-icon-online {
  color: #47ac18;
  font-size: 0.5rem;
}

.search-user-card-v2-address {
  font-size: 0.65rem;
  font-weight: 400;
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}

@media (min-width: 992px) {
  .search-user-card-v2 {
    flex-basis: 23%;
    width: 23%;
    margin-bottom: 0.25rem;
    background-color: #fff;
    border: 1px solid #f4f4f4;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 1rem;
  }
}
