.home-v2 {
  height: 100%;
  width: 100%;
  background-color: #021e30;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3rem;
  padding-bottom: 2.5rem;
}

.home-v2-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.home-v2-header-logo {
  width: 52%;
  margin: auto;
}

.home-v2-header-subtitle {
  text-align: center;
  margin-top: 0.75rem;
  font-size: 14px;
  color: #fff;
}

.home-v2-actions-container {
  padding-top: 1rem;
}

.home-v2-continue-button-phone {
  width: 100%;
  background-color: #c44b4b;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
  border-radius: 3px;
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.home-v2-continue-button-email {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #021e30;
  border-radius: 3px;
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.home-v2-continue-with-container {
  width: 100%;
  padding-top: 2rem;
}

.home-v2-continue-text {
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.home-v2-social-login-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.home-v2-social-login-btn {
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
  font-size: 18px;
  color: #fff;
}

.home-v2-continue-subtitle {
  font-size: 12px;
  text-align: center;
  color: #b5b5b5;
  margin-top: 1rem;
}

.home-v2-disclaimer-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

.home-v2-disclaimer-text {
  text-align: center;
  color: #fff;
  font-size: 12px;
  width: 250px;
  margin: auto;
}

.home-v2-disclaimer-red {
  color: #c44b4b;
  text-decoration: underline;
}
