.photo-view-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-view-image {
  height: 85%;
  width: 85%;
  object-fit: cover;
  border-radius: 3px;
  z-index: 3;
}

.photo-view-left-button {
  position: absolute;
  left: 2%;
  top: 44%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.33);
  height: 75px;
  width: 75px;

  color: #fff;
  font-size: 18px;
  z-index: 4;
}

.photo-view-right-button {
  position: absolute;
  right: 2%;
  top: 44%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.33);
  height: 75px;
  width: 75px;
  color: #fff;
  font-size: 18px;
  z-index: 4;
}

.photo-view-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 2;
}

.photo-view-header {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 7%;
}

.photo-view-close-btn {
  padding: 1rem;
  padding-right: 2rem;
  font-size: 22px;
  color: #fff;
}
