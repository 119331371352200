.edit-profile-v2-gallery-container {
  position: absolute;
  background: #ffffff;
  width: 100%;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 3;
}

.edit-profile-v2-gallery-header {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 0 !important;
  height: 8% !important;
  position: relative;
  width: 100%;
}

.edit-profile-v2-gallery-header-section-left {
  position: absolute;
  left: 0.25rem;
}

.edit-profile-v2-gallery-add-new {
  position: absolute;
  bottom: 20px;
  width: 90%;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  background-color: #c44b4b !important;
  border: none !important;
  z-index: 100;
  cursor: pointer;
}

.edit-profile-v2-photo-grid-header {
  width: 100%;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.edit-profile-v2-photo-grid-header-tab {
  width: 50%;
  text-align: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid grey;
}

.edit-profile-v2-photo-grid-header-tab-active {
  width: 50%;
  text-align: center;
  padding-bottom: 0.5rem;
  color: #c44b4b;
  border-bottom: 1px solid #c44b4b;
}

.edit-profile-v2-photo-grid-content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.75rem;
  width: 100%;
  overflow: auto;
}

.edit-profile-v2-photo-wrapper {
  width: 30%;
  margin-bottom: 0.35rem;
  margin-right: 0.35rem;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

.edit-profile-v2-photo {
  width: 100%;
  border-radius: 3px;
}

.edit-profile-v2-one-photo-view-body {
  width: 100%;
  height: 100%;
  max-width: 500px;
}

.edit-profile-v2-one-photo-content {
  width: 100%;
  height: 95%;
  object-fit: cover;
}

.edit-profile-v2-photo-actions {
  display: flex;
  position: relative;
  width: 100%;
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
  padding: 0 5px;
  max-width: 500px;
  cursor: pointer;
}

.edit-profile-v2-one-photo-view-more {
  position: absolute;
  right: 5px;
  font-size: 30px;
  bottom: 5px;
}

.edit-profile-v2-one-photo-view-detail {
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  padding-top: 5px;
}

.edit-profile-v2-one-photo-close-action {
  font-weight: 100 !important;
  cursor: pointer;
}

.edit-profile-v2-photo-more-actions {
  position: absolute;
  background: #ffffff;
  width: 200px;
  top: 40px;
  right: 5px;
  font-size: 16px;
}

.edit-profile-v2-more-action-icon {
  margin-right: 20px;
}

.edit-profile-v2-photo-more-actions {
  padding: 16px 20px;
}

.edit-profile-v2-photo-more-action {
  height: 40px;
  color: #021e30;
}

.edit-profile-v2-photo-remove-action {
  height: 32px;
  color: #c44b4b;
}

.edit-profile-v2-one-photo-view {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  z-index: 101;
}

.edit-profile-v2-private-requests {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  z-index: 101;
}

.edit-profile-v2-photo-prev {
  position: absolute;
  width: 50px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.4);
  top: 40%;
  left: 1rem;
  color: #ffffff;
  font-size: 30px;
  padding-top: 15px;
  padding-left: 10px;
}

.edit-profile-v2-photo-next {
  position: absolute;
  width: 50px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.4);
  top: 40%;
  right: 1rem;
  color: #ffffff;
  font-size: 30px;
  padding-top: 15px;
  padding-left: 20px;
}

.edit-photo-grid-uploading-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 200;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-upload-progress-card {
  height: 25%;
}

.edit-profile-v2-private-photo-option {
  display: flex;
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 5px;
}

.edit-profile-v2-private-photo-option-title {
  position: absolute;
  left: 30px;
  font-size: 18px;
}

.edit-profile-v2-private-photo-option-row-action {
  display: flex;
  position: absolute;
  right: 10px;
}

.edit-profile-v2-private-photo-right-icon {
  color: #b5b5b5;
  margin-top: 5px;
  margin-left: 15px;
}

.edit-profile-v2-private-photo-option-value {
  font-size: 18px;
  font-weight: 400;
  margin-top: 2px;
}

.icon-gallery {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: cover;
}

.icon-gallery-users {
  background-image: url(../../../assets/Images/EditPage/users.svg);
}

.icon-gallery-requests {
  background-image: url(../../../assets/Images/EditPage/user-plus.svg);
}

.edit-profile-v2-private-requests-body {
  width: 100%;
  height: 90%;
  padding: 10px 5px;
  overflow: auto;
}

.edit-profile-v2-private-request {
  display: flex;
  position: relative;
  width: 100%;
  height: 60px;
  margin-top: 10px;
  border-bottom: 1px solid #eeeeee;
}

.edit-profile-v2-request-image-wrapper {
  width: 48px;
  height: 48px;
}

.edit-profile-v2-request-image {
  width: 100%;
  height: 100%;
}

.edit-profile-v2-request-info {
  margin-left: 15px;
}

.edit-profile-v2-request-username {
  font-weight: 700;
  font-size: 18px;
  margin: 0 !important;
}

.edit-profile-v2-request-user-address {
  font-size: 14px;
  color: #b5b5b5;
}

.edit-profile-v2-request-action {
  margin-top: 10px;
  position: absolute;
  right: 0;
}

.edit-profile-v2-request-approve-icon {
  color: #43b581;
  font-size: 24px;
  margin-right: 15px;
}

.edit-profile-v2-request-decline-icon {
  color: #c44b4b;
  font-size: 24px;
}

.private-photo-text {
  font-size: 12px;
  width: 60%;
  text-align: center;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
