.welcome-offer-card {
  margin-top: 2rem;
}

.offer-content-wrapper {
  padding: 2rem;
  height: 80%;
  overflow-y: scroll;
}

.welcome-offer-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border: 4px solid #da0e2b !important;
}

.welcome-offer-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem;
}

.welcome-offer-card-header {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 4px solid #da0e2b !important;
  border-bottom: 0 !important;
}

.premium-gif-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.welcome-title-wrap-premium {
  border-bottom: 2px solid #dbdbdb;
  padding: 0 !important;
  margin: 0 !important;
}

.offer-item-icon-heart {
  font-size: 1.5rem;
  color: #55eac4;
  margin-right: 10px;
}

.offer-item-icon-superlike {
  font-size: 1.5rem;
  color: #32b5ff;
  margin-right: 10px;
}

.offer-item-icon-bolt {
  font-size: 1.5rem;
  color: #9a4ed9;
  margin-right: 10px;
}

.offer-item-icon-filter {
  font-size: 1.5rem;
  color: #ffad08;
  margin-right: 10px;
}

.offer-item-icon-message {
  font-size: 1.5rem;
  color: #f75590;
  margin-right: 10px;
}

.offer-item-icon-eye {
  font-size: 1.5rem;
  color: #2a2d43;
  margin-right: 10px;
}

.offer-item-title {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
}

.offer-item-icon-wrapper {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.5rem;
}

.offer-item-text-wrapper {
  width: 85%;
}
