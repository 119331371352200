.desktop-profile-tab-section-form-wrapper-support {
  width: 100%;
  padding-left: 2rem;
}

.desktop-faq-tab {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.desktop-faq-title {
  font-size: 1rem !important;
}

.desktop-profile-faq-wrapper {
  width: 80%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.desktop-profile-faq-item {
  border: 1px solid #888;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.desktop-profile-faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop-profile-faq-title {
  font-size: 1rem;
  margin: 0;
}

.desktop-profile-faq-content-wrapper {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #dbdbdb;
}

.desktop-profile-faq-content {
  font-size: 0.9rem;
}
