.modal-v2 .modal-dialog .modal-content {
  background: transparent !important;
  background-color: transparent !important;
}

.modal-v2 {
  background: transparent !important;
  background-color: transparent !important;
}

.modal-v2-body {
  background-color: #fff;
  padding: 0;
}

.modal-v2-footer {
  background: transparent !important;
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff !important;
}
.modal-action-button {
  width: 100%;
  font-weight: 700;
  background-color: #c44b4b;
  border-color: #c44b4b;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.modal-verify-button {
  width: 100%;
  font-weight: 700;
  background-color: #021e30;
  border-color: #021e30;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 16px;
  cursor: pointer;
}

.modal-close-button {
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: #021e30;
  cursor: pointer;
}

.boost-modal-header {
  position: relative;
  padding-top: 56px;
  padding-bottom: 60px;
  flex-direction: column;
  align-items: center;
  background-image: url('../../assets/Images/background-pattern.png');
}

.boost-modal-header-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180.13deg,
    #021e30 -88.33%,
    rgba(196, 75, 75, 0) 99%
  );
}

.verification-modal-header-wrap {
  width: 100%;
  position: relative;
}

.verification-modal-safety-badge {
  background-color: #021e30;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 40px;
  margin: auto;
  position: absolute;
  left: 42%;
  z-index: 2;
  bottom: -20px;
  border-radius: 3px;
  color: #fff;
  font-size: 20px;
}

.verification-modal-front-image {
  width: 83px;
  height: 95px;
  position: relative;
  object-fit: cover;
}

.boost-active-popup-modal {
  text-align: center;
}

.boost-package-select-wrapper {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.boost-package-select-form {
  display: flex;
  justify-content: space-evenly;
}

.boost-package-select-group {
  width: 30%;
  border: 1px solid #eeeeee;
  background-color: #fcfcfc;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}

.boost-package-select-group-active {
  width: 30%;
  border: 1px solid #eeeeee;
  background-color: #021e30;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}

.boost-package-icon {
  text-align: center;
  font-size: 30px;
  color: #c44b4b;
  margin-bottom: 8px;
}

.verify-modal-icon {
  text-align: center;
  font-size: 30px;
  margin-bottom: 8px;
  color: #021e30;
}

.boost-package-subtitle {
  text-align: center;
  color: #b5b5b5;
  font-size: 12px;
  margin-bottom: 10px;
}

.boost-package-subtitle-active {
  text-align: center;
  color: #fff;
  font-size: 12px;
  margin-bottom: 10px;
}

.boost-package-price {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  color: #021e30;
}

.boost-package-price-active {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  color: #fff;
}

.boost-package-check {
  display: none;
}

// ! Premium Features

.modal-premium-features-wrapper {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
}

.modal-premium-features-wrapper-center {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
}

.modal-premium-feature {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #eeeeee;
  background-color: #fcfcfc;
  padding: 1rem;
  border-radius: 3px;
}

.modal-premium-feature-subtitle {
  font-size: 12px;
  text-align: center;
  margin: 0;
  color: #b5b5b5;
  margin-bottom: 0.5rem;
}

.modal-premium-feature-title {
  font-size: 16px;
  color: #021e30;
  text-align: center;
  margin: 0;
  font-weight: 700;
}
