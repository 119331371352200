.edit-screen {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
}

.edit-screen-header {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 !important;
  height: 8% !important;
  position: relative;
  width: 100%;
}

.edit-screen-content {
  height: 92% !important;
  overflow-y: scroll !important;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
}

.edit-screen-header-section-left {
  position: absolute;
  left: 0.5rem;
}

.edit-screen-header-return-button {
  background-color: transparent;
  border: 0;
  color: #000;
  font-size: 16px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.edit-screen-header-title {
  margin: 0;
  text-align: center;
  font-size: 18px;
}

.edit-screen-header-done-button {
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  font-size: 16px;
  color: #c44b4b;
}

.edit-screen-header-section-right {
  position: absolute;
  right: 0.5rem;
}

.edit-screen-v2-section-row-title {
  color: #000;
  font-size: 16px;
  width: 40%;
  padding-left: 0.5rem;
}

.edit-screen-v2-section-row-title-full-width {
  color: #000;
  font-size: 16px;
  width: 90%;
  padding-left: 0.5rem;
}

.edit-screen-v2-section-row-content {
  color: #021e30;
  font-size: 14px;
  width: 50%;
  overflow-x: scroll;
  text-align: right;
  padding-right: 1rem;
}

.edit-screen-v2-section-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  display: flex;
  align-items: center;
}

.edit-screen-cta-button-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
}

.edit-screen-cta-button-upgrade {
  width: 100%;
  background-color: #c44b4b;
  border-color: #c44b4b;
  font-size: 16px;
}

.edit-screen-cta-button-upgrade:disabled {
  width: 100%;
  background-color: #c44b4b;
  border-color: #c44b4b;
  font-size: 16px;
}

.edit-screen-cta-button-upgrade:focus {
  width: 100%;
  background-color: #c44b4b;
  border-color: #c44b4b;
  font-size: 16px;
}

.edit-screen-cta-button-cancel {
  width: 100%;
  background-color: #021e30;
  border-color: #021e30;
}

.edit-screen-cta-button-cancel:disabled {
  width: 100%;
  background-color: #021e30;
  border-color: #021e30;
}

.edit-screen-cta-button-cancel:focus {
  width: 100%;
  background-color: #021e30;
  border-color: #021e30;
}

.edit-screen-premium-cta {
}

.edit-screen-update-button {
  width: 100%;
  background: transparent;
  border: 0;
  color: #021e30;
  margin-bottom: 1rem;
  font-size: 16px;
}

.edit-screen-update-button:disabled {
  width: 100%;
  background: transparent;
  border: 0;
  color: #021e30;
  margin-bottom: 1rem;
  font-size: 16px;
}
.edit-screen-update-button:focus {
  width: 100%;
  background: transparent;
  border: 0;
  color: #021e30;
  margin-bottom: 1rem;
  font-size: 16px;
}

.notifications-settings-component {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
  max-width: 500px !important;
  width: 100% !important;
  margin: auto;
}

.notifications-settings-header {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.notifications-settings-title {
  color: #6d6d71 !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.notifications-settings-body {
  padding: 0 !important;
}

.plus-settings-switch .Mui-checked {
  color: #eeeeee !important;
}

.edit-screen-input-wrapper {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.edit-screen-status-wrapper {
  color: #c44b4b;
  font-size: 14px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.edit-screen-status-wrapper-verified {
  color: #021e30;
  font-size: 14px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.number-verified-icon {
  color: #43b581;
  margin-right: 0.5rem;
}

.number-alert-icon {
  color: #c44b4b;
  margin-right: 0.5rem;
}

.edit-screen-email-form {
}

.edit-screen-input {
  background-color: #fcfcfc !important;
  border: 1px solid #eeeeee !important;
  border-radius: 2px !important;
  font-size: 16px !important;
  color: #021e30 !important;
}

.edit-screen-input-invalid {
  background-color: #fcfcfc !important;
  border: 1px solid #c44b4b !important;
  border-radius: 2px !important;
  font-size: 16px !important;
  color: #021e30 !important;
}

.edit-screen-form-label {
  font-size: 14px;
  font-weight: 700;
}

.edit-screen-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit-screen-send-again-button {
  background-color: transparent;
  border: 0;
  color: #c44b4b;
  text-decoration: underline;
  font-size: 16px;
  margin-top: 1.5rem;
}

.edit-screen-send-again-button:disabled {
  background-color: transparent;
  border: 0;
  color: #c44b4b;
  text-decoration: underline;
  font-size: 16px;
  margin-top: 1.5rem;
}

.edit-screen-send-again-button:focus {
  background-color: transparent;
  border: 0;
  color: #c44b4b;
  text-decoration: underline;
  font-size: 16px;
  margin-top: 1.5rem;
}

.new-code-sent-text {
  color: #000;
  font-size: 16px;
  margin-top: 1.5rem;
}

.deactivate-account-title {
  font-size: 20px;
  font-weight: 700;
  color: #021e30;
  margin-bottom: 1.5rem;
}

.deactivate-account-subtitle {
  font-size: 16px;
  color: #021e30;
}

.modal-v2-body-deactivate {
  padding: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.confirm-modal-title {
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin-top: 1rem;
}

.number-alert-icon-confirm {
  text-align: center;
  color: #c44b4b;
  margin-top: 1rem;
  font-size: 26px;
}

.edit-screen-bottom-contact-wrapper {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.edit-screen-bottom-contact-title {
  font-size: 14px;
  color: #021e30;
  text-align: center;
  font-weight: 700;
}

.edit-screen-bottom-contact-subtitle {
  text-align: center;
  font-size: 14px;
  color: #c44b4b;
  text-decoration: underline;
}
