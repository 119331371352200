.edit-profile-v2-headline-container {
  position: absolute;
  background: #ffffff;
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  z-index: 3;
}

.edit-profile-v2-headline-header {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 !important;
  height: 8% !important;
  position: relative;
  width: 100%;
}

.edit-profile-v2-headline-header-section-left {
  position: absolute;
  left: 0.5rem;
}

.edit-profile-v2-headline-title-one {
  font-size: 24px;
  font-weight: 500;
}

.edit-profile-v2-headline-title-two {
  font-size: 36px;
  font-weight: bold;
  color: #c44b4b;
}

.edit-profile-v2-headline-description {
  margin-top: 20px;
  font-size: 16px;
}

.edit-profile-v2-headline-title-text {
  margin-top: 25px;
}

.edit-profile-v2-headline-save {
  position: absolute;
  bottom: 20px;
  width: 90%;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  background-color: #c44b4b !important;
  border: none !important;
}

.edit-profile-v2-headline-input-field {
  margin-top: 50px;
  position: relative;
}

.edit-profile-v2-headline-len {
  position: absolute;
  right: 0;
  color: #B5B5B5;
}