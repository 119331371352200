.btnFacebook {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.3rem;
  width: 100%;
  margin-top: 15px;
  line-height: 2.4;
  font-size: 1.25rem;
  font-family: Roboto, sans-serif;
}

.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.7;
}

.btnFacebook-signin {
  background-color: white;
  color: #333131;
  border: 1px solid #f3f3f3;
  border-bottom: 2px solid #c8c8c8;
  border-radius: 0.3rem;
  width: 100%;
  margin-top: 15px;
  line-height: 2.4;
  font-size: 1.25rem;
  font-family: Roboto, sans-serif;
}

.facebook-logo {
  width: 35px;
  padding-bottom: 4px;
}