.advanced-searchfilters-component {
  position: absolute;
  top: 0;
  max-width: 500px;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.advanced-search-filters-card {
  width: 100% !important;
  height: 100%;
  border-radius: 0 !important;
  padding-bottom: 2rem;
  background-color: #f6f6f6 !important;
}

.advanced-search-filters-title {
  text-align: center;
  font-size: 1.3rem !important;
  margin: 0 !important;
}

.advanced-filters-close-icon {
  font-size: 2rem;
  color: #767676;
}

.advanced-search-header {
  position: relative;
  background-color: #fff !important;
}

.advanced-search-filters-body {
  overflow-y: scroll;
}

.advanced-filters-close-btn {
  background-color: transparent !important;
  border: 0 !important;
  position: absolute;
  left: 7px;
  bottom: 0;
}

.advanced-filters-switch {
  height: 100%;
}

.advanced-switch-input {
  width: 2rem !important;
  height: 2rem !important;
}

.advanced-switch-icon {
  color: #767676;
  margin-right: 1rem;
  font-size: 1.2rem;
  margin-top: 2px;
}

.advanced-switch-label {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}

.advanced-switch-label-text {
  font-size: 1.1rem;
  font-weight: 500;
}

.advanced-switch-wrapper {
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dbdbdb;
}

.advanced-switch-wrapper .react-toggle {
  margin-right: 0.5rem;
}

.advanced-switch-wrapper .react-toggle .react-toggle-track {
  background-color: #e9e9ea !important;
}

.advanced-switch-wrapper .react-toggle--checked .react-toggle-track {
  background-color: #c44b4b !important;
}

.advanced-search-filters-slider-wrapper {
  border: 1px solid #dbdbdb;
  background-color: #fff;
  padding: 1rem 1rem 0 1rem;
  border-radius: 15px;
}

.advanced-search-filters-slider-wrapper .MuiSlider-thumbColorPrimary {
  color: #c44b4b;
  width: 25px;
  height: 25px;
  margin-top: -12px;
}

.advanced-search-filters-slider-wrapper .MuiSlider-thumb {
  transition: box-shadow 150ms cubic-bezier(0, 0, 0, 1) 0ms !important;
}

.advanced-search-filters-slider-wrapper .MuiSlider-track {
  color: #c44b4b;
}

.advanced-search-filters-slider-wrapper .MuiSlider-root {
  color: #c44b4b;
}

.advanced-search-filters-slider-wrapper .MuiSlider-rail {
  color: #c7c7cb;
}

.filter-item-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.toggles-innerwrap {
  display: flex;
  flex-wrap: wrap;
}
