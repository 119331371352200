.search-filters-bar-component {
  width: 100%;
  height: 10%;
  background-color: #090a20;
  display: flex;
}

.search-filters-icon {
  font-size: 1.7rem;
  position: relative;
  color: #dbdbdb;
}

.search-filters-link {
  width: 50%;
}

.search-filters-btn-home {
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  border: 0 !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-filters-btn {
  width: 50%;
  height: 100%;
  background-color: transparent !important;
  border: 0 !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
