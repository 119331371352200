.grid-dropdown-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  z-index: 2;
}

.grid-dropdown {
  width: 35% !important;
  border-color: rgba(9, 10, 32, 0.66) !important;
  background: rgba(9, 10, 32, 0.66) !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.grid-dropdown-btn {
  width: 100% !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  color: #dbdbdb !important;
  border: 0 !important;
}

.grid-dropdown-menu {
  width: 100% !important;
}

.grid-dropdown-item {
  width: 100% !important;
}
