.admin-home-tab {
  height: 100%;
  width: 100%;
  background-color: #202225;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-home-page-card {
  max-width: 500px;
}

.admin-home-card-image {
  height: 150px;
  object-fit: cover;
}

.admin-home-top-navigation {
  height: 10%;
  width: 100%;
  border: 1px solid #dc3545;
}

.admin-home-tab-content {
  border: 1px solid #dc3545;
  flex-grow: 1;
  overflow-x: scroll;
}
