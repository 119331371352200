.browsegrid-container {
  width: 100%;
  padding-top: 8rem;
  padding-bottom: 4rem;
  display: flex;
  flex-wrap: wrap;
}

.browsegrid-location-form {
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.browse-grid-location-input-wrapper {
  width: 90% !important;
  max-width: 350px;
  position: relative;
}

.browse-grid-location-input {
  width: 100% !important;
}

.browsegrid-dropdown-card {
  position: absolute;
  z-index: 5;
  font-size: 1rem;
  top: 50px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
}

.bg-location-input-reset-btn-wrap {
  width: 90%;
  max-width: 350px;
  display: flex;
  justify-content: flex-end;
}

.bg-location-input-reset-btn {
  font-size: 0.9rem !important;
  color: #090a20 !important;
}

.browse-grid-location-input {
  border: 1px solid #090a20 !important;
}
