.actions-component {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 15px 15px;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: absolute;
  bottom: 10%;
  z-index: 3;
}

.actions-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  height: 65px;
  width: 65px;
}

.actions-button-wrapper-scrolled {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  height: 55px;
  width: 55px;
}

.actions-card-btn {
  background-color: rgba(255, 255, 255, 0.85);
  background-color: #fff;
  border-radius: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 2px 6px 0px rgba(112, 125, 134, 0.65);
  height: 65px;
  width: 65px;
  transition: height 15ms, width 15ms;
}

.actions-card-btn-scrolled {
  background-color: rgba(255, 255, 255, 0.85);
  background-color: #fff;
  border-radius: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 2px 6px 0px rgba(112, 125, 134, 0.65);
  height: 55px;
  width: 55px;
  transition: height 15ms, width 15ms;
}

.actions-card-btn-white {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 2px 6px 0 rgba(112, 125, 134, 0.14);
  height: 65px;
  width: 65px;
  transition: height 15ms, width 15ms;
}

.actions-card-btn:active {
  height: 70%;
  width: 70%;
}

.actions-card-btn:active .actions-card-icon-large {
  font-size: 1.7rem;
}

.actions-card-btn:active .actions-card-icon-small {
  font-size: 1rem;
}

.actions-card-icon-large {
  font-size: 32px;
  margin-top: 3px;
}

.actions-card-icon-small {
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  margin-top: 30%;
}

.favorite-btn-icon {
  color: #e05e5e !important;
}

.dislike-btn-icon {
  color: #b5b5b5;
}

.superlike-btn-icon {
  color: #32b5ff;
}

.undo-btn-icon {
  color: #ffad08;
}

.undo-btn-icon-disabled {
  color: #dfe4ea;
}

.boost-btn-icon {
  color: #9a4ed9;
}
