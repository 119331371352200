.verification-component {
  height: 100%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.verification-header {
  padding-top: 1rem;
  width: 100%;
  position: relative;
}

.verification-header-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 3;
  background-color: transparent;
  border: 0;
  color: #021e30;
}

.verification-pre-title {
  font-size: 17px;
  color: #021e30;
  font-weight: 700;
}

.verification-title {
  font-size: 28px;
  font-weight: 700;
  color: #c44b4b;
  margin-bottom: 1rem;
}

.verification-subtitle {
  font-size: 14px;
  color: #021e30;
}

.verification-content {
  width: 100%;
  padding-top: 1rem;
}

.verification-item {
  width: 100%;
  padding: 1rem;
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.verification-item-icon {
}

.verification-icon {
  color: #c44b4b;
}

.verification-icon-verified {
  color: #43b581;
}

.verification-item-content {
  padding-left: 1rem;
}

.verification-item-title {
  color: #021e30;
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  margin-bottom: 4px;
}

.verification-item-subtitle {
  font-size: 12px;
  margin: 0;
  color: #d95454;
}

.verification-item-subtitle-verified {
  font-size: 12px;
  margin: 0;
  color: #021e30;
}

.verification-item-action {
}

.verification-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.verification-footer-button {
  width: 100%;
  background-color: #c44b4b;
  border: #c44b4b;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 16px;
  font-weight: 700;
}

.verification-footer-button-top {
  width: 100%;
  color: #021e30;
  border: 0;
  background-color: transparent;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.verification-content-title {
  font-size: 14px;
  color: #021e30;
  font-weight: 700;
  margin-bottom: 1rem;
}

.verification-content-form {
  border: 1px solid #eeeeee;
  background-color: #fcfcfc;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 16px;
  color: #021e30;
}

.verification-content-form-prepend {
  border: 0;
  background-color: #fcfcfc;
}

.verification-content-form-text {
  border: 0;
  background-color: #fcfcfc;
  font-size: 16px;
  color: #c44b4b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verification-content-form-input {
  border: 0;
  background-color: #fcfcfc;
  font-size: 16px;
  color: #021e30;
}

.verification-content-form-input:disabled {
  border: 0;
  background-color: #fcfcfc;
  font-size: 16px;
  color: #021e30;
}

.verification-form-icon {
  margin-top: 3px;
}

.verification-code-input-v2 {
}

.verification-code-input-v2 div {
  display: flex;
  justify-content: space-between;
}

.verification-code-input-v2 div input {
  background-color: #fcfcfc !important;
  border: 1px solid #eeeeee !important;
  border-radius: 3px !important;
}

.phone-verification-v2-wrapper {
  width: 100%;
}

.phone-verification-v2-wrapper .react-tel-input {
}

.phone-verification-v2-wrapper .react-tel-input input {
  width: 100%;
  height: 50px;
  background-color: #fcfcfc !important;
  border: 1px solid #eeeeee !important;
  border-radius: 3px !important;
  color: #021e30 !important;
  font-size: 16px !important;
}

.phone-verification-v2-wrapper .react-tel-input .flag-dropdown {
  border: 0 !important;
  background-color: #fcfcfc !important;
  border: 1px solid #eeeeee !important;
  border-right: 0 !important;
}

.phone-verification-v2 {
}
