.submit-report-btn-disabled {
  cursor: not-allowed !important;
  background-color: #dbdbdb !important;
  border: #dbdbdb !important;
}

.help-page-content-wrap {
  width: 100%;
  max-width: 700px;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.help-page-title {
  text-align: center;
  margin-bottom: 2rem;
}

.help-page-subtitle {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.help-page-subtitletwo {
  font-size: 1.5rem;
  color: #888;
  text-align: center;
}

.safety-page-icon {
  text-align: center;
  font-size: 2rem;
  color: #da0e2b;
}
.safety-icon-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.email-highlight-faq {
  color: #da0e2b;
  margin-left: 5px;
}

.below-faq-wrapper {
  padding-top: 2rem;
  text-align: center;
}

.help-container {
  height: 90%;
  padding: 1rem;
  overflow-y: scroll;
}
