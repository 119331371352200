.search-component-v2 {
  width: 100%;
  height: 92%;
}

.search-component-v2-loading-wrapper {
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.search-component-v2-content-container {
  height: 92%;
  overflow-y: scroll;
}

.search-component-v2-content-container-hidden {
}
