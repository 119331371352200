.header-container {
  height: 8%;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.header-section-left {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.header-section-center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-section-right {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-logo {
  height: 75%;
  object-fit: cover;
}

.header-button {
  background-color: transparent !important;
  border: none !important;
}

.header-undo-icon-inactive {
  color: #888;
  font-size: 1.5rem;
}

.header-undo-icon-active {
  color: #000;
  font-size: 1.5rem;
}

.header-heart-icon {
  color: #dc3545;
  font-size: 1.5rem;
  position: relative;
}

.header-filter-icon {
  color: #000;
  font-size: 1.5rem;
}

.header-settings-icon {
  color: #000;
  font-size: 1.5rem;
}

.header-edit-icon {
  color: #000;
  font-size: 1.5rem;
}

.header-back-icon {
  color: #000;
  font-size: 1.5rem;
}
