.boost-countdown-component {
  position: absolute;
  top: 0;
  color: #dbdbdb;
  font-size: 0.7rem;
  font-weight: 700;
  z-index: 1;
}

.countdown-boost-icon-left {
  margin-right: 4px;
  color: #9a4ed9;
}

.countdown-boost-icon-right {
  margin-left: 4px;
  color: #9a4ed9;
}

.boost-wrapper-top {
  position: fixed;
  top: 0;
  width: 100%;
  height: 10px;
  background-color: #9a4ed9;
  z-index: 5;
}

.boost-progress-bar {
  height: 10px !important;
}

.boost-progress-bar .progress-bar {
  background-color: #9a4ed9 !important;
}

.boost-wrapper-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 12px;
  background-color: #9a4ed9;
  z-index: 5;
}

.boost-wrapper-left {
  position: fixed;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  z-index: 5;
  background-color: #9a4ed9;
}

.boost-wrapper-right {
  position: fixed;
  right: 0;
  top: 0;
  width: 5px;
  height: 100%;
  z-index: 5;
  background-color: #9a4ed9;
}

.boost-wrapper-text {
  position: fixed;
  top: 0;
  z-index: 6;
  width: 100%;
  text-align: center;
  margin: 0;
  font-size: 7px;
  color: #f9f9f9;
  font-weight: 900;
  text-transform: uppercase;
}

.boost-wrapper-text-bottom {
  position: fixed;
  bottom: 0;
  z-index: 6;
  width: 100%;
  text-align: center;
  margin: 0;
  font-size: 9px;
  color: #f9f9f9;
  font-weight: 900;
  text-transform: uppercase;
}
