.loading-page-component {
  width: 100%;
  height: 100%;
}

.loading-page-image-section {
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-page-progress-section {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-spinner-logo {
  width: 50%;
}

.loading-page-cliploader-wrapper {
  margin-bottom: 1rem;
}

.logo-spinner-subtitle {
  color: #fff;
  margin-top: 1rem;
}
