.edit-value-component {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
}

.edit-value-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(215, 215, 215, 0.7);
  backdrop-filter: blur(5px);
  z-index: 3;
}

.edit-profile-v2-input-value {
  width: 100%;
  height: 50%;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  z-index: 4;
  padding-top: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 0;
}

.edit-profile-v2-apply-button {
  position: absolute;
  bottom: 20px;
  width: 90%;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  background-color: #c44b4b !important;
  border: none !important;
}

.edit-profile-v2-input-header-right {
  position: absolute;
  right: 20px;
}

.edit-profile-v2-input-header {
  display: flex;
  position: relative;
  width: 100%;
}

.edit-profile-v2-header-reset-button {
  background-color: transparent;
  padding: 0;
  border: 0;
  text-decoration: underline;
  font-size: 16px;
  color: #c44b4b;
  &:disabled {
    background-color: transparent;
    color: black;
  }
}

.edit-profile-v2-input-header-title {
  position: absolute;
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
  left: 35px;
}

.edit-profile-v2-icon {
  position: absolute;
  left: 7px;
  color: #c44b4b;
  margin-top: 12px;
}

.edit-profile-v2-input-body input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
}

.edit-profile-v2-input-body input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.edit-profile-v2-input-body input[type="radio"]:checked:before {
  background: #c44b4b;
}

.edit-profile-v2-input-body {
  position: absolute;
  width: 100%;
  height: 65%;
  overflow-y: auto;
  top: 65px;
  left: 20px;
}

.edit-profile-v2-radio-label {
  position: absolute;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 500;
}

.edit-profile-v2-input-option {
  width: 100%;
  height: 60px;
  padding-top: 20px;
  border-bottom: 1px solid #eeeeee;
}

.edit-profile-v2-height {
  position: absolute;
  width: 75%;
  top: 100px;
}

.edit-profile-v2-height-label {
  font-size: 18px;
  font-weight: 500;
}

.edit-profile-v2-height .MuiSlider-thumbColorPrimary {
  color: #c44b4b;
  width: 22px;
  height: 22px;
  margin-top: -8px;
}

.edit-profile-v2-height .MuiSlider-thumb {
  transition: box-shadow 150ms cubic-bezier(0, 0, 0, 1) 0ms !important;
}

.edit-profile-v2-height .MuiSlider-track {
  color: #c44b4b;
  height: 5px;
}

.edit-profile-v2-height .MuiSlider-root {
  color: #c44b4b;
  height: 5px;
}

.edit-profile-v2-height .MuiSlider-rail {
  color: #c7c7cb;
  height: 5px;
}
