.searchfilters-component {
  width: 100%;
  max-width: 500px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
  position: absolute;
  top: 0;
}

.search-filters-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
}

.search-filters-card {
  width: 100% !important;
  height: 100%;
  border-radius: 20px 20px 0 0 !important;
  padding-bottom: 2rem;
}

.search-filters-body {
  height: 100%;
  overflow-y: scroll;
}

.search-filters-title {
  text-align: center;
  font-size: 1.3rem !important;
  margin: 0 !important;
}

.search-filters-label {
  color: #818181;
}

.advanced-search-filters-btn {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  background-color: #fff !important;
  border: 1px solid #dbdbdb !important;
  font-size: 1.1rem !important;
  color: #000 !important;
  border-radius: 15px !important;
}

.search-filters-btn-icon {
  color: #dbdbdb;
}

.search-filters-slider-wrapper {
  border: 1px solid #dbdbdb;
  padding: 1rem 1rem 0 1rem;
  border-radius: 15px;
}

.slider-innerwrap {
  padding-right: 0.5rem;
}

.search-filters-slider-wrapper .MuiSlider-thumbColorPrimary {
  color: #c44b4b;
  width: 25px;
  height: 25px;
  margin-top: -12px;
}

.search-filters-slider-wrapper .MuiSlider-thumb {
  transition: box-shadow 150ms cubic-bezier(0, 0, 0, 1) 0ms !important;
}

.search-filters-slider-wrapper .MuiSlider-track {
  color: #c44b4b;
}

.search-filters-slider-wrapper .MuiSlider-root {
  color: #c44b4b;
}

.search-filters-slider-wrapper .MuiSlider-rail {
  color: #c7c7cb;
}

.search-slider-title {
  font-size: 1.1rem;
  font-weight: 500;
}

.search-filters-location-input-wrapper {
  position: relative;
}

.search-filters-dropdown-card {
  position: absolute;
  z-index: 5;
  font-size: 1rem;
  top: 50px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
}

.search-filters-close-btn {
  background-color: transparent !important;
  border: 0 !important;
  position: absolute;
  left: 7px;
  bottom: 0;
}

.search-filters-header {
  position: relative;
  background-color: #fff !important;
}

.search-filters-close-icon {
  font-size: 2rem;
  color: #767676;
}

// ACtive Filter Section

.active-filters-card {
  border: 0 !important;
  margin-bottom: 1rem;
  border: 1px solid #888 !important;
}

.active-filters-card-body {
  padding: 0.5rem !important;
}

.active-filters-card-header {
  padding: 0.5rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active-filters-card-footer {
  padding: 0.5rem !important;
}

.active-filters-card-title {
  margin: 0 !important;
  color: #888;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.search-active-filter-btn {
  border-radius: 15px !important;
  font-size: 0.6rem !important;
  background-color: #fff !important;
  color: #888 !important;
  border-color: #888 !important;
  margin-right: 3px !important;
  margin-bottom: 5px !important;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-filters-remove-icon {
  color: #888;
  margin-right: 5px;
}

.no-active-filters-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.no-active-filter-text {
  color: #888;
  font-size: 0.8rem;
  text-align: center;
}

.active-filters-clear-all-btn {
  font-size: 0.7rem !important;
  background-color: #090a20 !important;
  border-color: #090a20 !important;
  color: #fff !important;
}
