.desktop-v2-container {
  width: 100%;
  max-width: 1400px;
  padding: 1rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh;
}

.desktop-v2-content {
  width: 75%;
  height: 100%;
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

.desktop-messages-v2-content {
  width: 75%;
  height: 100%;
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

.desktop-v2-swipe-content {
  height: 95vh;
  width: 75%;
  display: flex;
  flex-direction: column;
}

.desktop-v2-search-results {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.desktop-v2-content-container {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  flex: 1;
  height: 0;
  display: flex;
}

// Desktop Settings

.desktop-settings-v2 {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.desktop-v2-settings-tabs {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #f4f4f4;
}

.desktop-v2-settings-tab {
  font-size: 14px;
  font-weight: 500;
  color: #021e30;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  cursor: pointer;
}

.desktop-v2-settings-tab-active {
  font-size: 14px;
  font-weight: 700;
  color: #021e30;
  border-bottom: 1px solid #000;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  cursor: pointer;
}

.desktop-v2-settings-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  position: relative;
}

.desktop-v2-settings-row {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.desktop-v2-settings-row-innerwrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.desktop-v2-settings-icon {
  height: 18px;
  width: 18px;
}

.desktop-v2-settings-row-section {
  display: flex;
  align-items: center;
}

.desktop-v2-settings-title {
  font-size: 16px;
  font-weight: 500;
  color: #021e30;
  margin-left: 1rem;
}

.desktop-v2-settings-form {
}

.desktop-v2-settings-form-group {
  position: relative;
  padding: 0;
  margin: 0;
}

.desktop-v2-settings-form-control {
  width: 332px;
  height: 48px;
  background-color: #fcfcfc !important;
  border: 1px solid #eeeeee !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #021e30 !important;
}

.desktop-v2-settings-form-verified-icon {
  position: absolute;
  right: 1rem;
  top: 30%;
  height: 18px;
  width: 18px;
}

.desktop-v2-settings-verify-button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #c44b4b;
  border-radius: 3px;
  color: #fff;
  height: 40px;
  width: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.desktop-v2-settings-add-number-button {
  background-color: #c44b4b;
  border-radius: 3px;
  color: #fff;
  height: 48px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.desktop-v2-settings-edit-button {
  font-size: 12px;
  font-weight: 400;
  color: #021e30;
  cursor: pointer;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 16px;
}

.desktop-v2-settings-form-group .react-tel-input {
  width: 332px;
  height: 48px;
  background-color: #fcfcfc !important;
  border: 1px solid #eeeeee !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #021e30 !important;
}

.desktop-v2-settings-form-group .react-tel-input .flag-dropdown {
  display: none;
}

.desktop-v2-settings-form-group .react-tel-input .form-control {
  width: 332px;
  height: 48px;
  background-color: #fcfcfc !important;
  border: 1px solid #eeeeee !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #021e30 !important;
  padding-left: 0.75rem;
}

.desktop-v2-settings-content-header {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eeeeee;
  position: relative;
}

.desktop-v2-settings-content-header-title {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin: 0;
  text-align: center;
}

.desktop-v2-settings-back-button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #021e30;
  position: absolute;
  left: 1rem;
}

.desktop-v2-settings-edit-row {
  display: flex;
  width: 100%;
  padding: 1rem;
  padding-top: 2rem;
}

.desktop-v2-settings-form-edit {
  width: 100%;
}

.desktop-v2-settings-form-group-edit {
  position: relative;
  padding: 0;
  margin-right: 2rem;
  width: 50%;
}
.desktop-v2-settings-form-group-edit-phone {
  position: relative;
  padding: 0;

  width: 50%;
  margin: auto;
}

.desktop-v2-settings-form-group-verification {
  width: 100%;
  margin: auto;
  max-width: 400px;
}

.desktop-v2-settings-form-control-edit {
  width: 50%;
  height: 48px;
  background-color: #fcfcfc !important;
  border: 1px solid #eeeeee !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #021e30 !important;
}

.desktop-v2-settings-form-edit-innerwrap {
  width: 100%;
  display: flex;
}

.desktop-v2-settings-input-group {
  border: 1px solid #eeeeee !important;
  background-color: #fcfcfc !important;
  font-size: 16px !important;
  color: #021e30 !important;
  font-weight: 400 !important;
}

.desktop-v2-settings-input-group-prepend {
  border: 0 !important;
  background-color: #fcfcfc !important;
}

.desktop-v2-settings-input-group-text {
  border: 0;
  background-color: #fcfcfc;
  font-size: 16px;
  color: #c44b4b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop-v2-settings-form-label {
  font-size: 14px;
  color: #021e30;
  font-weight: 400;
}

.desktop-v2-settings-form-edit-submit {
  background-color: #c44b4b;
  font-size: 14px;
  font-weight: 600;
  border-color: #c44b4b;
}

.desktop-v2-settings-form-group-edit-phone .react-tel-input .form-control {
  width: 100%;
  height: 50px;
  background-color: #fcfcfc !important;
  border: 1px solid #eeeeee !important;
  border-radius: 3px !important;
  color: #021e30 !important;
  font-size: 16px !important;
}

.desktop-v2-settings-form-group-edit-phone .react-tel-input .flag-dropdown {
  border: 0 !important;
  background-color: #fcfcfc !important;
  border: 1px solid #eeeeee !important;
  border-right: 0 !important;
}

.desktop-v2-settings-row-title {
  font-size: 14px;
  font-weight: 500;
  color: #021e30;
}

.desktop-v2-settings-notification-button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.desktop-v2-settings-notification-icon {
  height: 18px;
  width: 18px;
}

.desktop-v2-settings-help-icon {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.desktop-v2-settings-help-content {
  flex: 1;
  overflow-y: scroll;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;
}

.desktop-v2-settings-active-text {
  font-size: 14px;
  font-weight: 700;
  color: #43b581;
}
.desktop-v2-settings-plan-text {
  font-size: 14px;
  font-weight: 400;
  color: #021e30;
  text-transform: capitalize;
}

.desktop-v2-settings-content-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}

.desktop-v2-settings-cancel-subscription-button {
  width: 150px;
  height: 45px;
  border-radius: 5px;
  background-color: #021e30;
  cursor: pointer;
  color: #fff;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}

.desktop-search-placeholder-card {
  flex-basis: 23%;
  width: 23%;
  height: 273px;
  margin-bottom: 0.25rem;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 1rem;
}

// Desktop Profile

.desktop-v2-profile-content {
  width: 100%;
  height: 100%;
  display: flex;
}

.desktop-v2-profile-main {
  width: 75%;
  border: 1px solid #eeeeee;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
}

.desktop-v2-profile-right {
  width: 25%;
  position: relative;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
}
