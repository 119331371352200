.desktop-premium-content-container {
  width: 100%;
  display: flex;
  max-width: 1300px;
  margin: auto;
  padding-top: 3rem;
}

.desktop-premium-left-side {
  width: 30%;
  padding: 1rem;
  border: 1px solid #021e30;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.desktop-premium-right-side {
  width: 70%;
  padding: 1rem;
  border: 1px solid #021e30;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.desktop-premium-right-innerwrap {
  height: 100%;

  padding: 2rem;
}

.desktop-premium-header-title {
  font-size: 1.25rem;
  font-weight: 700;
}

.desktop-premium-right-content {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.desktop-premium-feature {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px !important;
  width: 30%;
  flex-basis: 30%;
  padding: 1rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.desktop-premium-feature-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.desktop-premium-feature-subtitle {
  font-size: 1rem;
  margin: 0;
}

.desktop-premium-feature-icon {
  color: #d45757;
  font-size: 2rem;
  margin-bottom: 1rem;
}
