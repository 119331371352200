.messages-matches-component {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0rem;
  width: 100%;
  max-width: 1140px;
}

.mm-title-wrap {
  display: flex;
  padding-left: 1rem;
}

.mm-title {
  color: #000;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
}

.match-count-number {
  color: #fff;
  background-color: #c44b4b;
  height: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px !important;
  margin-left: 7px;
  margin-top: 2px;
  border-radius: 5rem !important;
}
