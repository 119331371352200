.user-info-card {
  border-radius: 15px !important;
  width: 95%;
  max-width: 1140px;
  margin: auto;
  margin-top: 1.5rem;
  border: 1px solid #021e30 !important;
}

.user-info-card-header {
  padding: 0 !important;
  padding: 0.5rem !important;
  background-color: #021e30 !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.user-info-card-header-title {
  margin: 0 !important;
  text-align: center;
  color: #fff;
  font-weight: 700 !important;
}

.user-info-header-icon {
  margin-right: 10px;
}

.user-info-card-body {
}

.user-info-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
}

.user-info-section-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
}

.user-info-section-title {
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
  color: #888;
  white-space: nowrap;
}

.user-info-section-text {
  margin: 0;
  text-transform: none;
  color: #000;
  font-weight: 400;
  margin-left: 10px;
  text-align: right;
}

.verified-badge-user-info {
}
