.notification-bar-component {
  width: 100%;
  height: 13%;
  background-color: #f3f3f7;
  position: absolute;
  top: 0;
  z-index: 3;
  padding-top: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid #2a2d43;
  border-top: 0;
}

.notification-bar-innerwrap {
  display: flex;
  width: 100%;
  position: relative;
}

.notification-bar-image-wrapper {
}

.notification-bar-image {
  object-fit: cover;
  border-radius: 15px;
}

.notification-bar-image-blur {
  object-fit: cover;
  border-radius: 15px;
  filter: blur(10px) !important;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
}

.notification-bar-content-wrapper {
  padding-left: 1rem;
  padding-right: 1.5rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-bar-icon-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.notification-bar-right-icon {
  font-size: 1.2rem;
  color: #2a2d43;
}

.notification-superlike-icon {
  margin-right: 3px;
  color: #32b5ff;
}

.notification-like-icon {
  margin-right: 3px;
  color: #55eac4;
}

.notification-bar-title {
  margin: 0;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  margin-bottom: 5px;
}

.notification-bar-subtitle {
  margin: 0;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-bar-swipe-tab {
  width: 15%;
  height: 5px;
  background-color: #2a2d43;
  border-radius: 100px;
  margin-bottom: 2px;
}
