.message-card {
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 0.5rem !important;
}

.message-card-compliment {
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 0.5rem !important;
}

.message-card-consecutive {
  padding: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  margin-top: 0.5rem !important;
}

.message-card-compliment-consecutive {
  padding: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  margin-top: 0.5rem !important;
}

.message-card-consecutive-timestamp {
  padding: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  margin-top: 0.5rem !important;
}

.message-card-compliment-consecutive-timestamp {
  padding: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  margin-top: 0.5rem !important;
}

.message-card-header {
  background-color: transparent !important;
  padding: 0 !important;
  padding-bottom: 0.5rem !important;
  border: 0 !important;
}

.message-card-title {
  text-align: center;
  margin: 0 !important;
  font-size: 12px !important;
  color: #b5b5b5;
  margin-bottom: 0.5rem !important;
}

.message-card-received {
  display: flex !important;
  flex-direction: row !important;
  padding: 0 !important;
  border: 0 !important;
}

.message-card-sent {
  display: flex !important;
  flex-direction: row-reverse !important;
  padding: 0 !important;
  border: 0 !important;
}

.message-card-spacer {
  padding-left: 50px;
}

.message-card-image {
  object-fit: cover;
  height: 50px;
  width: 50px;
}

.bubble-consecutive-received {
  background-color: #f6f6f6;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 0px 4px 4px 0px;
  max-width: 70% !important;
}

.bubble-consecutive-compliment-received {
  background-color: #f6f6f6;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 0px 4px 4px 0px;
  max-width: 70% !important;
}

.bubble-consecutive-sent {
  background-color: #021e30;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 4px 0px 0px 4px;
  max-width: 70% !important;
}

.bubble-consecutive-compliment-sent {
  background-color: #021e30;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 4px 0px 0px 4px;
  max-width: 70% !important;
}

.bubble-received {
  background-color: #f6f6f6;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 4px 4px 4px 0px;
  max-width: 70% !important;
}

.bubble-received-compliment {
  background-color: #f6f6f6;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 4px 4px 4px 0px;
  max-width: 70% !important;
}

.bubble-sent {
  background-color: #021e30;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 4px 4px 0px 4px;
  max-width: 70% !important;
}

.bubble-sent-compliment {
  background-color: #021e30;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 4px 4px 0px 4px;
  max-width: 70% !important;
}

.chat-text-receiver {
  color: #000;
  font-size: 12px;
}

.chat-text-sent {
  color: #fff;
  font-size: 12px;
}

.message-card-footer {
  padding: 0 !important;
  padding-top: 0.5rem !important;
  display: flex;
  flex-direction: row-reverse;
  background-color: transparent !important;
  border: 0 !important;
}

.card-footer-innerwrap {
  display: flex;
}

.message-read-indicator-wrapper {
  padding-right: 0.7rem;
  margin-right: 0.5rem;
  position: relative;
}

.message-read-indicator-wrapper-premium {
  padding-left: 0.5rem;
}

.message-read-receipt-button {
  background-color: #021e30;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.read-receipt-btn-icon {
  color: #fff;
  font-size: 10px;
  margin-top: 3px;
  margin-left: 2px;
}

.read-receipt-btn-icon-single {
  color: #fff;
  font-size: 10px;
  margin-top: 3px;
}

.read-receipt-plus {
  position: absolute;
  top: 0;
  right: 0;
  color: #021e30;
  font-size: 10px;
}

.message-sent-indicator-wrapper {
}

.message-sent-indicator {
  margin: 0;
  color: #b5b5b5;
  font-size: 12px;
}

//  Compliment

.compliment-message {
  background-color: #c44b4b;
  padding: 0.7rem 1rem 0.7rem 1rem;
  margin-left: 0.7rem;
  border-radius: 4px 4px 0px 4px;
  max-width: 70% !important;
}

.compliment-message-content {
  background-image: url("../../../../assets/Images/complimentMessageBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.compliment-message-diamond-count {
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
}

.compliment-message-diamond-icon {
  height: 16px;
  width: 18px;
  object-fit: cover;
}

.compliment-message-text {
  font-size: 12px;
  font-weight: 700;
  color: #021030;
}

.compliment-message-diamond-count-text {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-left: 5px;
}
