.swipe-nav {
  width: 100%;
  height: 10%;
  background-color: #021e30;
  display: flex;
  justify-content: center;
}

.swipe-nav-premium {
  width: 100%;
  height: 10%;
  background-color: #090a20;
  display: flex;
  justify-content: center;
  border: 3px solid #e9aa43;
}

.swipe-nav-logo {
  height: 50px;
  width: 50px;
}

.swipe-nav-left {
  width: 37.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipe-nav-logo-link {
  height: 100%;
  width: 25%;
}

.swipe-nav-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.swipe-nav-right {
  width: 37.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipe-nav-link {
  width: 50%;
  height: 100%;
}

.swipe-nav-btn {
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  border: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

.swipe-nav-btn:active .swipe-nav-icon {
  font-size: 1rem;
}

.swipe-nav-icon {
  font-size: 1.7rem;
  position: relative;
  color: #dbdbdb;
}

.nav-boost-countdown-wrapper {
  margin: 0;
  padding: 0;
}

.nav-boost-countdown-wrapper span {
  color: #fff;
  font-weight: 800;
  font-size: 0.7rem;
  margin: 0;
}

.swipe-nav-innerwrap {
  width: 100%;
  display: flex;
  max-width: 1140px;
}

// DESKTOP

.swipe-nav-innerwrap-desktop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1140px;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.swipe-nav-desktop-logo-wrapper {
  height: 22px;
  width: 150px;
}

.swipe-nav-desktop-sections-wrapper {
  display: flex;
  align-items: center;
}

.swipe-nav-desktop-logo {
  height: 100%;
  width: 100%;
}

.swipe-nav-desktop-section {
  padding-left: 2rem;
}

.swipe-nav-icon-desktop {
  font-size: 1.7rem;
  position: relative;
  color: #dbdbdb;
  margin-right: 15px;
}

.swipe-nav-premium-text {
  margin: 0;
  font-size: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #dbdbdb;
}
.swipe-nav-premium-indicator {
  position: absolute;
  top: 1px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 3px;
  padding-bottom: 2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.premium-indicator-icon {
  font-size: 0.4rem;
  color: #cd9213;
  margin-right: 3px;
}
