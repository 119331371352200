.match-card {
  width: 100%;
}

.match-card-component {
  flex-basis: 49%;
  margin-bottom: 0.75rem;
}

.matches-card-action-wrapper {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.matches-card-button {
  width: 100%;
  height: 50px;
  border: 1px #f4f4f4 solid;
  text-align: center;
  padding-top: 10px;
}

.match-card-chat-icon {
  color: #c44b4b;
  font-size: 28px;
}
.matches-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.matches-card-username {
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 1rem;
}

.matches-card-body {
  padding: 0 !important;
  width: 100%;
  height: 250px;
  position: relative;
}

.matches-card-info {
  position: absolute;
  bottom: 45px;
  width: 100%;
  padding: 1rem;
}

@media (min-width: 992px) {
  .match-card-component {
    flex-basis: 24%;
    margin-bottom: 0.75rem;
  }
}
