.id-verify-form {
  padding-top: 1rem;
}

.id-verify-input {
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  color: #021e30;
  font-size: 16px;
  height: 50px;
}

.verification-video-wrapper {
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.verification-video-wrapper video {
  width: 100%;
}

.verification-recording-icon-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
  font-size: 1.5rem;
  color: #d95454;
}

.verification-footer-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  border-top: 1px solid #eeeeee;
  height: 135px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.verification-content-scroll {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 135px;
}

.verification-video-instructions-wrapper {
  width: 100%;
  border: 1px solid #eeeeee;
  padding: 1rem;
  background-color: #fcfcfc;
  border-radius: 3px;
}

.verification-video-instructions {
}

.verification-video-instructions-item {
  font-size: 14px;
  color: #021e30;
}

.verification-video-script-text {
  text-align: center;
  font-style: italic;
  font-size: 14px;
  color: #021e30;
  font-weight: 500;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.submitting-overlay {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: (0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.verification-footer-cliploader div {
  margin-right: 5px !important;
}
